<template>
  <div class="shop-top-box">
    <div class="shop-top">
      <div style="display: flex;justify-content: space-between;padding: 0 300px;">
        <router-link :to="{path:'/'}">
          <img style="margin-top:20px;" width="340" height="36" src="../../assets/img/logo-header.svg" alt="食安链家">
        </router-link>
        <div style="width: 300px;display: flex;justify-content: space-around;align-items: center;">
          <div style="display: flex;cursor: pointer;" @click="goCart">
              <el-badge :value="num" class="item">
                <img style="margin-bottom: 40px;" src="../../assets/img/cart.png" alt="">
              </el-badge>
            <div style="height: 100px;"></div>
          </div>
          <span class="Gologin" style="margin-bottom: 25px;" @click="GoLogin" v-show="!loginShow">登录</span>
          <div v-show="loginShow" class="user-box">
            <!--            {{ userLogin.accountName }}-->
            <el-dropdown>
              <span style="display: flex;align-items: center" class="el-dropdown-link">
                <p style="width: 200px;">{{ userLogin.accountName }}</p><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><span @click="goUserCenter"><i class="el-icon-setting"></i>账户中心</span></el-dropdown-item>
                <el-dropdown-item><span @click="dialogVisible = true"><i class="el-icon-switch-button"></i>退出登录</span></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div style="height: 50px;"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false">
      <span>确定要退出吗？</span>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="okLoginOut">确 定</el-button>
        </span>
    </el-dialog>
    <div class="shop-search">
      <div class="ya315-container">
        <router-link :to="{path:'/shop'}" class="shop-title"><img src="../../assets/img/shop-logo.png" width="134" height="34" alt=""></router-link>
        <div class="shop-title-tips">服务企业<br/>数字化管理</div>
        <div class="search-group">
          <input v-model="searchVal" placeholder="请输入搜索内容" type="text">
          <router-link :to="{path:'/shop/list',query:{searchVal:searchVal}}" class="search-btn">搜索</router-link>
          <div class="hot-word">
            <router-link :to="{path:'/shop/list',query:{searchVal:'小程序'}}">小程序</router-link>
            <router-link :to="{path:'/shop/list',query:{searchVal:'SaaS平台'}}">SaaS平台</router-link>
            <router-link :to="{path:'/shop/list',query:{searchVal:'防伪码'}}">防伪码</router-link>
          </div>
        </div>
        <div class="shop-select">
          <el-button style="margin-left: 20px;" @click="backBtn('买方中心')" type="primary">买方中心</el-button>
          <el-button style="margin-left: 20px;" @click="backBtn('卖方中心')" type="primary">卖方中心</el-button>
        </div>
      </div>
    </div>
    <div class="ya315-container">
      <div class="hot-nav">
        <div>云市场分类</div>
        <router-link :to="{path:'/shop/list',query:{searchVal:'小程序'}}">小程序</router-link>
        <router-link :to="{path:'/shop/list',query:{searchVal:'API'}}">API专区</router-link>
        <router-link :to="{path:'/solutionNew',query:{type:'zhnp'}}">解决方案</router-link>
        <router-link :to="{path:'/shop/server'}">增值服务</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import router from "../../router";
  import { JSEncrypt } from 'jsencrypt'
  import {logout} from "../../utils/api";
  const PROLIST = require('../../../public/data/proList.json');
  export default {
    name: 'Header',
    data(){
      return {
        leftNavIndex:1,
        searchVal:'',
        list:[],
        loginShow:false,
        userLogin:{},
        dialogVisible:false,
        num:"",
      }
    },
    watch: {
      $route(to, form) {
        this.getCurrent()
      }
    },
    mounted() {
      if(this.$router.history.current.query.log == "已登出"){
        this.okLoginOut()
      }
      $('#head').hide();
      this.getCurrent()
      this.http.getAccount().then(res=>{
        if(res.code == "1"){
          this.loginShow = true
          this.userLogin = res.content
          this.http.cartList().then(res=>{
            if(res.code == "1"){
              this.num = res.data.length
            }
          })
        }else{
          this.loginShow = false
        }
      })
    },
    methods: {
       // 登录
      GoLogin(){
        //  Go Login
        this.$router.push({
          path:"/login"
        })
      },
      goCart(){
        this.$router.push({
          path:"/cart"
        })
      },
      //跳转账户中心
      goUserCenter(){
        // document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//存储数据之前删除cookie删除token
        // //将token存储cookie
        // let token = localStorage.getItem("userToken")
        // let date = new Date();
        // date.setDate(date.getDate() + 1);
        // document.cookie = 'token' + '=' + token + ';expires=' + date;
        location.href="http://192.168.2.125:9999"
      },
      //确定退出登录
      okLoginOut(){
        this.http.logout().then(res=>{
          if(res.code == "1"){
            document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//存储数据之前删除cookie删除token
            Cookies.remove('token', { path: 'http://192.168.2.125:9999' });
            this.dialogVisible = false
            this.$message({message:"退出登录成功",type:"success"})
            location.reload()
          }
        })
      },
      backBtn(str){
        if(str == "买方中心"){
          this.goUserCenter()
        }else if(str == "卖方中心"){
          location.href="http://192.168.2.125:9998/login"
        }else if(str == "服务商入驻"){
          this.$emit("serviceBtn")
        }
      },
      getCurrent(){
        let param = this.$route.query
        this.searchVal = param.searchVal
        if(param.proType){
          PROLIST.forEach(item=>{
            if(item.proType==param.proType){
              this.list.push(item)
            }
          })
        }else if(param.type){
          let t=Number(param.type)*10
          let t2=(Number(param.type)+1)*10
          PROLIST.forEach(item=>{
            let t3=Number(item.proType)
            if(t3>t && t3<t2){
              this.list.push(item)
            }
          })
        }else{
          this.list = PROLIST
        }
        this.getSearchList()
      },
      getSearchList(){
        let val = this.searchVal;
        let list = []
        if(val){
          this.list.forEach(item=>{
            if ((item.proName.indexOf(val) >= 0) || (item.proName==val)) {
              list.push(item)
            }
          })
          this.$emit('list',list)
        }else{
          this.$emit('list',this.list)
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    }
  }
</script>

<style scoped>
  .shop-top-box{height:242px;width:100%;z-index: 10;background:#fff;overflow: hidden;}
  .shop-top{height:54px;width:100%;}
  .shop-search{height:112px;margin-bottom:28px;}
  .shop-title{color:#3B3B3B;font-size: 47px;float:left;margin-top:18px;}
  .shop-title-tips{color:#858A90;font-size: 16px;float:left;margin-top:35px;margin-left:17px;}
  .search-group{width:512px;float:left;margin-left:150px;margin-top:31px;}
  .search-group>input{height:48px;width:417px;border:1px solid #1890FF;border-radius: 8px 0 0 8px;box-sizing: border-box;line-height: 48px;float:left;outline:0;padding-left:24px;}
  .search-btn{height:48px;width:95px;float:left;background:#1890FF;line-height: 48px;text-align: center;font-size: 22px;color:#fff;border-radius: 0 8px 8px 0;cursor: pointer;}
  .hot-word{float:left;margin-top:8px;margin-left:16px;color:#858A90;font-size: 16px;}
  .hot-word>a{float:left;margin-right:24px;cursor: pointer;}
  .hot-nav{height:48px;}
  .hot-nav>a,.hot-nav>div{float:left;height:48px;font-size: 16px;color:#52565B;margin-right:36px;line-height: 48px;cursor: pointer;}
  .hot-nav>div{width:260px;font-size: 18px;background:#1890FF;color:#fff;text-indent: 24px;margin-right:32px;}
  .ya315-login{width:150px;height:54px;float: right;line-height: 54px;text-align: center}
  .user-box{display: flex;}
  .user-box>.user-img{width: 50px;height: 27px;display: block;align-items: center;margin-top: 13px;}
  .user-box>span{color: #bbb;font-size: 12px;margin-left: 5px;}
  .Gologin{font-size: 15px;cursor: pointer;}
  .shop-select{width: 200px;height: 50px;float: right;margin: 30px 50px 0 0;display: flex;align-items: center;justify-content: space-around}
  .item{
    top: 20px !important;
  }
</style>
