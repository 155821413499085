<template>
  <div class="loginCenter">
    <div class="loginCenter-header">
      <div class="loginCenter-header-center">
        <div class="logo" @click="toBack">
          <img src="../../assets/img/logo1.png" alt="食安链家" />
          <!-- <div class="title">登录中心</div> -->
        </div>
        <div class="goBack" @click="toBack">返回官网</div>
      </div>
    </div>
    <div class="loginCenter-ascend">
      <div class="loginCenter-ascend-center">
        <div class="search-box">
          <div class="search">
            <div class="searchTab">
              <span :class="goodsShow ? 'cur' : ''" @click="goodsClick"
                >商品条码/放心码查询</span
              >
              <span :class="codeShow ? 'cur' : ''" @click="codeClick"
                >追溯码查询</span
              >
            </div>
            <div class="searchCon" v-if="goodsShow">
              <input
                type="text"
                placeholder="请输入商品条码/放心码"
                v-model="sn"
                style="width: 264px; border-right: 0px"
              />
              <div
                data-v-577b1418=""
                style="
                  width: 2px;
                  background: #fff;
                  height: 30px;
                  margin-left: -1px;
                "
              ></div>
              <input
                type="text"
                placeholder="生产批次（选填）"
                v-model="lot"
                style="border-left: 0px; width: 201px"
              />
            </div>
            <div class="searchCon" v-if="codeShow">
              <input
                type="text"
                placeholder="搜索追溯码"
                v-model="traceCode"
                style="width: 465px"
              />
            </div>
            <div class="searchCon" v-if="securityCodeShow">
              <input
                type="text"
                placeholder="搜索防伪序列号"
                v-model="securityCode"
                style="width: 465px"
              />
            </div>
            <div class="verify">
              <verify
                ref="verify"
                style="width: 318px; height: 43.22px"
                @success="(v) => (verify = v.verify)"
                @complete="isLoginError = false"
              />
              <div class="s-btn" @click="toSearch">查询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loginCenter-content">
      <div class="center">
        <div class="top-title">
          <img src="../../assets/img/infoHeader.png" alt="" />
        </div>
        <div class="content">
          <div class="content-header">
            食安云管家-餐超产业链SaaS系统
          </div>
          <div class="content-box">
            <div class="tab">
              <div class="tab-item active">
                智慧餐饮SaaS系统
              </div>
              <div class="tab-item">
                智慧零售SaaS系统
              </div>
            </div>
            <div class="list">
              <div
                class="list-item"
                v-for="(item, index) in cardList"
                :key="index"
              >
                <div class="list-item-title">{{ item.title }}</div>
                <p class="list-item-desc">{{ item.desc }}</p>
                <a
                  v-if="item.url"
                  :href="item.url ? item.url : '#'"
                  class="list-item-link"
                  target="_blank"
                  >进入</a
                >
                <div v-else class="list-item-link">进入</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verify from "./verify/index.vue"
import info from "@/assets/info.png"

export default {
  name: "loginCenter",
  components: { verify },
  data() {
    return {
      goodsShow: true,
      codeShow: false,
      securityCodeShow: false,
      sn: "",
      info,
      lot: "",
      traceCode: "",
      securityCode: "",
      isLoginError: true,
      verify: null,
      internetList: [],
      cardList: [
        {
          title: "鲜赢客（中餐）",
          desc:
            "适用于中餐场景，通过销售、预订、运营、成本管控等方面的智能化管理，有效提升顾客满意度。",
          url: "https://restaurant.yunan315.com/dish/login"
        },
        {
          title: "鲜易客（快餐）",
          desc:
            "针对连锁行业如：奶茶店、小吃店、等打造一站式餐饮营销、管理系统。"
        },
        {
          title: "收银POS",
          desc:
            "针对收银进行管理，系统会将门店所有运营的商品推送至门店APP中，每次收银机打开并登录APP后，会自动请求系统是否有商品信息变更。"
        },
        {
          title: "服务员手持POS",
          desc:
            "APP包含餐饮店面的所有菜系及其他商品，可快速协助消费者点餐，并传到前台和后厨，通知后厨准备菜肴，缩短时间，并可以在在线付款时，打印小票。"
        },
        {
          title: "在线商城小程序",
          desc:
            "在线小程序商城是汇集了品牌下所有门店的统一在线商城管理系统，用户首次进入的时候会指定品牌，完成品指定之后进入小程序品牌在线商城。"
        },
        {
          title: "厨显APP",
          desc:
            "后厨厨显APP主要时为后厨厨师人员准备的可视化且可操作的菜品制作和管理系统。"
        },
        {
          title: "餐饮品牌商管理平台",
          desc:
            "品牌商管理系统主要针对品牌商的入驻申请、商品管理、供应管理、仓库管理、门店管理、PC商城管理等内容进行功能设计。",
          url: "https://restaurant.yunan315.com/brand/login"
        },
        {
          title: "餐饮品牌商供应商城",
          desc:
            "打造一条独具特色的上游采购到下游销售的营销线路，搭配小B端品牌商城在线订购；同时结合物流配送管理体系。",
          url: "https://restaurant.yunan315.com/shop"
        },
        {
          title: "供应商集采数据管理平台",
          desc:
            "基于企业供应链上游采购和供应商关系管理环节深度集成，实现需求/计划、采购过程、供应商管理、智慧寻源、供应商绩效考核、合同管理。",
          url: "https://restaurant.yunan315.com/supplier"
        },
        {
          title: "餐饮供应商商城",
          desc:
            "构建面向行业上、中、下游客户，提供集交易、仓储、物流、金融等一站式服务的B2B电商平台。",
          url: "https://restaurant.yunan315.com/supplierShop"
        }
      ]
    }
  },
  methods: {
    goodsClick() {
      this.goodsShow = true
      this.codeShow = false
      this.securityCodeShow = false
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    codeClick() {
      this.goodsShow = false
      this.codeShow = true
      this.securityCodeShow = false
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    securityCodeClick() {
      this.goodsShow = false
      this.codeShow = false
      this.securityCodeShow = true
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    toSearch() {
      const num = /^[0-9]*$/
      let str = ""
      let message = ""
      let type = 1
      let traceCode = this.traceCode
      let sn = this.sn
      let lot = this.lot
      let securityCode = this.securityCode
      if (this.goodsShow) {
        str = this.sn
        type = 1
        message = "请输入商品条码/放心码"
      } else if (this.codeShow) {
        str = this.traceCode
        message = "请输入追溯码"
        type = 2
      } else if (this.securityCodeShow) {
        str = this.securityCode
        message = "请输入防伪序列号"
        type = 3
      }
      if (str === "") {
        this.$message({
          message: message,
          offset: "400"
        })
      } else if (!num.test(str)) {
        this.$message({
          message: "请输入数字",
          offset: "400"
        })
      } else {
        if (!this.verify) {
          this.$message({
            message: "请先完成验证",
            offset: "400"
          })
        } else {
          let routeData = this.$router.resolve({
            name: "searchDetail",
            query: { str: str, lot: lot, type: type }
          })
          window.open(routeData.href, "_blank")
        }
      }
    },
    toLink(url) {
      if (url) {
        window.open(url, "_blank")
      }
    },
    toBack() {
      this.$router.push("/")
    }
  }
}
</script>
<style lang="scss" scoped>
.loginCenter {
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-top: 72px;
  background-color: #f7f9ff;
  min-height: 100vh;
  &-header {
    width: 100%;
    min-width: 1200px;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 72px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    padding: 0 60px;
    z-index: 999;
    // display: flex;
    // justify-content: ;
    &-center {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        height: 72px;
        cursor: pointer;
        .title {
          display: inline-block;
          margin-left: 50px;
        }
      }
      .goBack {
        color: #fff;
        background-color: #0366f1;
        text-align: center;
        line-height: 42px;
        width: 127px;
        height: 42px;
        border-radius: 2px;
        cursor: pointer;
      }
      .goBack:hover {
        background-color: rgba(3, 102, 241, 0.7);
      }
    }
  }

  &-ascend {
    width: 100%;
    height: 323px;
    background-image: url("../../assets/img/bgi.png");
    background-size: cover;
    &-center {
      width: 100%;
      min-width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }
  }

  &-content {
    margin-top: 30px;
    .center {
      // min-width: 1200px;
      width: 1400px;
      margin: 0 auto;
      .top-title {
        img {
          height: 116px;
          width: auto;
        }
      }
      .content {
        &-header {
          font-weight: 600;
          font-size: 20px;
          margin-top: 20px;
          margin-left: 17px;
        }
        &-box {
          // display: flex;
          // justify-content: center;
          .tab {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            &-item {
              margin-left: 40px;
              font-size: 18px;
              cursor: pointer;
              width: 160px;
            }
            .active {
              color: #0366f1;
              font-weight: 600;
              text-align: center;
            }
            .active:after {
              content: "";
              display: inline-block;
              width: 80%;
              height: 4px;
              background-color: #0366f1;
            }
            &-item:hover {
              color: #0366f1;
              font-weight: 600;
            }
          }
          .list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: auto;
            margin: 0 auto;
            &-item {
              width: 320px;
              height: 220px;
              background-color: #fff;
              margin-bottom: 15px;
              margin-right: 30px;
              padding: 20px 15px 15px 15px;
              border-radius: 8px;
              box-sizing: border-box;
              box-shadow: 4px 2px 7px 0px rgba(3, 102, 241, 0.06);
              // box-shadow: 0px 4px 10px 0px rgba(3, 102, 241, 0.06);
              font-family: Source Han Sans, Source Han Sans;
              position: relative;
              &-title {
                font-weight: 550;
                font-size: 20px;
                color: #333333;
              }
              &-desc {
                font-weight: 400;
                font-size: 16px;
                color: #8d8d8d;
                line-height: 23px;
                margin-top: 10px;
              }
              &-link {
                position: absolute;
                bottom: 25px;
                left: 15px;
                display: inline-block;
                width: 80px;
                height: 30px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #3672ee;
                text-align: center;
                line-height: 28px;
                font-size: 16px;
                color: #3672ee;
              }
            }
            &-item:hover {
              box-shadow: 4px 2px 15px 0px rgba(3, 102, 241, 0.15);
            }
          }
        }
      }
      // img {
      //   width: 100%;
      //   height: auto;
      // }
    }
  }

  .search-box {
    position: absolute;
    right: 13%;
    top: 50%;
    width: 512px;
    z-index: 2;
    transform: translateY(-50%);
    .number {
      font-size: 22px;
      color: #595959;
      letter-spacing: 10px;
      margin-bottom: 22px;

      .bigFont {
        font-size: 70px;
        color: #2c2c2c;
        font-weight: 600;
        text-shadow: 3px 2px 1px rgba(255, 255, 255, 0.3);
      }
    }

    .search {
      right: 0;
      margin: 0 auto;
    }

    .searchTab {
      color: #3c3c3c;
      font-size: 16px;
      span {
        width: 232px;
        height: 48px;
        line-height: 42px;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;
        // background: #f4f7ff;
        color: rgba(41, 66, 117, 1);
        font-size: 16px;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 500;
        border: 1px solid #fff;
        border-bottom: 0px;
        &.cur {
          background: linear-gradient(
            360deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          font-size: 16px;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 700;
          color: rgba(54, 114, 238, 1);
        }
      }
    }

    .searchCon {
      width: 465px;
      height: 48px;
      // background: #ffffff;
      // border: 1px solid #5278d4;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        padding: 0;
        border: 1px solid #fff;
        background: none;
        height: 48px;
        font-size: 16px;
        color: #333;
        outline: none;
        text-indent: 24px;
        width: 264px;
        // border: 0px!important;
      }

      input::-webkit-input-placeholder {
        color: #aab5d1;
      }

      i {
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background: #5277f7;
      }
    }
    .verify {
      width: 465px;
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-left: ;
      .s-btn {
        font-family: 微软雅黑;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 130px;
        height: 44px;
        background-image: linear-gradient(90deg, #0d74ff, #4760ff);
        text-align: center;
        font-size: 18px;
        color: #fff;
        line-height: 44px;
      }
    }
  }
}
</style>
