<template>
  <div>
    <div class="banner">
        <div class="ya315-container">
          <div class="d-b-title">蛋鸡产业链</div>
          <div class="d-b-info">涵盖了饲料管理、养殖管理、品牌分销、在线大额交易、物流配送，提供软硬一体的信息化解决办法，满足了企业集团管控、业务管控核心诉求</div>
        </div>
    </div>
    <div :class="isFixed?'fd':''" class="nav">
      <div>
        <div @click="anchor(0)" :class="anchorNum==0?'cur':''">产业链介绍</div>
        <div @click="anchor(1)" :class="anchorNum==1?'cur':''">产业链架构</div>
        <div @click="anchor(2)" :class="anchorNum==2?'cur':''">关联系统</div>
        <div @click="anchor(3)" :class="anchorNum==3?'cur':''">案例展示</div>
      </div>
    </div>
    <div id="page1">
      <p>产业链介绍</p>
      <p>平台整合供应商、物流商、品牌商和养殖场资源， 以自有商品、B2B支付、终端交易和C端聚合支付为支撑、以品牌商和终端业务流通和面向下游销售管理为基础，
        通过资源整合，打造统一供应链平台，对终端客户提供商品供应和营销服务，并为食品农产品的安全追溯打下坚实基础</p>
    </div>
    <div id="page2">
      <p>产业链架构</p>
      <p><img style="display: block;margin: 0 auto;" width="1000px" height="660px" src="@/assets/img/cyljg.png" alt=""></p>
    </div>
    <div id="page3">
      <p>关联系统</p>
      <el-tabs v-model="activeName" tab-position="left" style="height: 390px;margin-top: 20px;">
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane name="上游供应采集" label="上游供应采集">
          <div style="display: flex;padding: 20px;">
            <div>
              <p class="text">以企业间的信息资源集成共享为基础，使供应链运作从采购开始，以最少的成本，达到最优的运作，最终满足客户的需求。通过商品库存、采购计划、供应管理、仓储物流、终端销售、市场消费的全产业链可追溯管理，实现信息资源共享、流通过程可视化。</p>
              <div style="width: 620px;"><img style="display: block;margin: 0 auto;" width="400px" height="280px" src="@/assets/img/sygyjc.png" alt=""></div>
            </div>
            <div style="width: 300px;margin-left: 20px;">
              <div style="width: 300px;height: 240px;padding-top: 10px">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/spncpicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 80px;font-size: 15px;line-height: 30px;">食品农产品大数据资源管理平台</div>
                </div>
                <div style="margin-left: 50px;color: #2690FD;"><a href="https://raise.yunan315.com/supplierShop/login" target="_blank">登录平台<i class="el-icon-right"></i></a></div>
              </div>
              <div style="width: 300px;height: 240px;">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/gyscjicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 80px;font-size: 15px;line-height: 30px;">供应商集采集供数据管理平台</div>
                </div>
                <div style="margin-left: 50px;color: #2690FD;"><a href="https://raise.yunan315.com/supplier/login" target="_blank">登录平台<i class="el-icon-right"></i></a></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="中游品牌分销">
          <div style="display: flex;padding: 20px;">
            <div>
              <p class="text">品牌商和门店为上下隶属关系，品牌商入驻后，门店需选择已入驻的品牌商进行进驻。平台为每个入驻并审核通过的品牌商开通在线PC分销商城，供终端门店在线采购交易。
              </p>
              <div><img style="display: block;margin: 0 auto;" width="620px" height="280px" src="@/assets/img/zyppfx.png" alt=""></div>
            </div>
            <div style="width: 300px;margin-left: 20px;">
              <div style="width: 300px;height: 240px;padding-top: 10px">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/gyscjicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 80px;font-size: 15px;line-height: 30px;">品牌商SaaS管理平台</div>
                </div>
                <div style="margin-left: 50px;color: #2690FD;"><a href="https://raise.yunan315.com/shop" target="_blank">登录平台<i class="el-icon-right"></i></a></div>
              </div>
              <div style="width: 300px;height: 240px;">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/gyscjicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 80px;font-size: 15px;line-height: 30px;">品牌商后台供应链管理平台</div>
                </div>
                <div style="margin-left: 50px;color: #2690FD;"><a href="https://raise.yunan315.com/brand/login" target="_blank">登录平台<i class="el-icon-right"></i></a></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
        <el-tab-pane label="下游养殖管理">
          <div style="display: flex;padding: 20px;">
            <div>
              <p class="text">实现养鸡场生产管理线上化、生产数据实时化、营销采购公开透明化、资金快速流转、物流过程监管，对降本增效、加强管理、产品流通发挥较强作用。
              </p>
              <div><img style="display: block;margin: 0 auto;" width="620px" height="280px" src="@/assets/img/xyyzgl.png" alt=""></div>
            </div>
            <div style="width: 300px;margin-left: 20px;">
              <div style="width: 300px;padding-top: 10px">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/gyscjicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 60px;font-size: 15px;line-height: 30px;">养殖场管理平台</div>
                </div>
                <div style="margin-left: 50px;color: #2690FD;"><a href="https://raise.yunan315.com/store/login" target="_blank">登录平台<i class="el-icon-right"></i></a></div>
              </div>
              <div style="width: 300px;margin-top: 50px;">
                <div style="display: flex;">
                  <div style="margin-top: 15px;"><img src="@/assets/img/qdicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 60px;font-size: 15px;line-height: 30px;">禽蛋小程序交易平台</div>
                </div>
                <el-popover trigger="hover" placement="top">
                  <div style="margin-top: 15px;"><img src="@/assets/img/psicon.png" alt=""></div>
                  <div slot="reference" class="name-wrapper">
                    <div style="margin-left: 50px;color: #2690FD;">进入小程序</div>
                  </div>
                </el-popover>
              </div>
              <div style="width: 300px;margin-top: 50px;">
                <div style="display: flex;">
                  <div><img src="@/assets/img/psicon.png" alt=""></div>
                  <div style="margin-left: 20px;width: 150px;height: 60px;font-size: 15px;line-height: 30px;">物流配送APP</div>
                </div>
                <el-popover trigger="hover" placement="top">
                  <div><img src="@/assets/img/psicon.png" alt=""></div>
                  <div slot="reference" class="name-wrapper">
                    <div style="margin-left: 50px;color: #2690FD;">进入APP</div>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane disabled></el-tab-pane>
      </el-tabs>
    </div>
    <div id="page4">
      <p style="margin-top: 40px;">案例展示</p>
      <div style="display: flex;margin-top: 30px;">
        <div style="width: 500px;height: 500px;">
          <div @mousemove="active = 0" :class="active==0?'active':''" style="width: 350px;height: 150px;margin-top: 30px;display: flex;
          align-items: center;justify-content: space-around;background: rgba(252, 253, 255, 1);">
            <img width="260px" height="50px" src="@/assets/img/altop.png" alt="">
          </div>
          <div @mousemove="active = 1" :class="active==1?'active':''" style="width: 350px;height: 150px;margin-top: 30px;display: flex;
          align-items: center;justify-content: space-around;background: rgba(252, 253, 255, 1);">
            <img width="260px" height="50px" src="@/assets/img/albot.png" alt="">
          </div>
        </div>
        <div style="width: 700px;height: 500px;">
          <div v-show="active == 0">
            <h4 style="margin-top: 25px;">河南同发实业有限公司</h4>
            <p class="text" style="margin-top: 20px;">涵盖饲料管理、养殖管理、对外销售管理，提供软硬一体的信息化解决方案，满足企业集团管控、业务管控核心诉求，全产业链质量追溯，帮助企业打造高端品牌，树立企业形象，全面的成本分析和生产分析，为企业决策提供坚实的数据支撑。
            </p>
            <el-button type="text" style="font-size: 18px;margin-top: 100px;">查看案例<i class="el-icon-right"></i></el-button>
          </div>
          <div v-show="active == 1">
            <h4 style="margin-top: 25px;">郑州蛋是有未来生物科技有限公司</h4>
            <p class="text" style="margin-top: 20px;">为养殖公司企业设计，支持养殖企业实现对下属各单位、各养殖基地、各农户的 数据集中管理和实时监控的平台，为实行农业养殖生产加工等的产、供、销一体化经营奠定了基础；有利于把单家独户经营的农民与公司集结为一体；增强了养殖利润分析、存栏报表、空栏报表、养殖利润分析、往 来款分析，提供科学的分析报表，给企业决策提供准确依据，利用信息化推行全面预算，减少经营风险；有 利于组成规模经营，全面应用科技，提高生产力水平。</p>
            <el-button type="text" style="font-size: 18px;margin-top: 100px;">查看案例<i class="el-icon-right"></i></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "djcyl",
    data(){
      return {
        isFixed:false,
        anchorNum:0,
        active:0,
        activeName:"上游供应采集"
      }
    },
    mounted() {
      $('#head,#foot').show();
      this.type = this.$route.query.type
      if (document.addEventListener) {//firefox
        document.addEventListener('DOMMouseScroll', this.scrollFuc, false);
      }
      //滚动滑轮触发scrollFunc方法 //ie 谷歌
      window.onmousewheel = document.onmousewheel = this.scrollFuc;
    },
    methods:{
      scrollFuc(e){
        e = e || window.event;
        if (e.wheelDelta) { //第一步：先判断浏览器IE，谷歌滑轮事件
          if (document.documentElement.scrollTop > 450) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        } else if (e.detail) { //Firefox滑轮事件
          if (document.body.scrollTop> 450) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        }
      },
      anchor(n){
        this.anchorNum = n;
        let top = 0
        if(n==0){
          top = $('#page1').offset().top-72-60
        }else if(n==1){
          top = $('#page2').offset().top-72-60
        }else if(n==2){
          top = $('#page3').offset().top-72-60
        }else{
          top = $('#page4').offset().top-72-60
        }
        $('html').scrollTop(top)
        this.isFixed = true
      }
    },
  }
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-djcy.png);background-size:cover;}
  .d-b-title{font-size: 48px;color:#fff;padding-top:90px;}
  .d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;}
  .nav{height:54px;border-bottom:1px solid #E8E9EB;width:100%;background:#fff;overflow: hidden;}
  .nav.fd{position:fixed;top:60px;z-index: 10;}
  .nav>div{display: flex;justify-content: center;margin: 0 auto;}
  .nav>div>div{width:90px;margin:0 146px;height:54px;border-bottom:2px solid transparent;line-height: 54px;font-size: 16px;color:#52565B;text-align: center;cursor: pointer;}
  .nav>div>div.cur{color:#1890FF;border-bottom-color: #1890FF;}
  #page1,#page2,#page3,#page4{
    width: 1200px;
    padding: 20px 0px;
    margin: 0 auto;
  }
  #page1>p:nth-of-type(1),#page2>p:nth-of-type(1),#page3>p:nth-of-type(1),#page4>p:nth-of-type(1){
    font-size: 30px;
    color: #52565B;
    text-align: center;
  }
  #page1>p:nth-of-type(2){
    font-size: 14px;
    color: #52565B;
    line-height: 25px;
    letter-spacing: 2px;
    margin-top: 20px;
  }
  .text{
    width: 588px;
    height: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #52565B;
  }
  .active{
    border: 1px solid rgba(38, 144, 253, 0.2000);
    border-left: 4px solid #2690FD;
  }
























</style>
