import axios from 'axios'
import { Message } from "element-ui";
import router from "../router/index"


let reg = RegExp('token' + '=([^;]+)');
let arr = []
if (document.cookie.match(reg)) {
  arr = document.cookie.match(reg)
}
const instance = axios.create({
  // baseURL: 'http://192.168.2.114:8001/api/buyer',
  baseURL: 'https://cloud.yunan315.com/salj/api/buyer',
  timeout: 10000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Buyer-User-Token-Admin": arr[1]
  }
})
//请求拦截器
instance.interceptors.request.use(
  config => {
    return config
  },
  err => {
    return Promise.reject(err)
  })
//响应拦截器
instance.interceptors.response.use(
  res => {
    // 可以在这里判断http状态码
    if (res.data.code == "1") {
      return res.data
    } else if (res.data.code == "404") {

    } else if (res.data.code == "10000") {
      return res.data
    } else if (res.data.code == "20001") {

    } else if (res.data.code == "20002") {

    } else if (res.data.code == "20003") {


    } else if (res.data.code == "20004") {


    } else if (res.data.code == "20005") {

    } else if (res.data.code == "20007") {
      document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//删除cookie删除token
    } else if (res.data.code == "20008") {
      document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//删除cookie删除token
      location.reload()
    } else if (res.data.code == "40001") {

    } else if (res.data.code == "70001") {

    } else if (res.data.code == "80001") {

    } else if (res.data.verify) {
      return res
    }
    if (res.data.info == "ERROR") {
      return res.data
    }
    if (res.data.info != "用户未登录") {
      Message({ message: res.data.info || res.data.msg, type: "error" })
    }
    return res.data
  },
  err => {
    return Promise.reject(err)
  })
export default instance
