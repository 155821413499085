import api from "./index"

const suyuanBaseUrl = 'https://suyuan.limafangxin.com/service'
//主账户登录
export function login(data) {
  return api({
    url: "/buyer/account/login",
    method: "POST",
    data
  })
}
//子账户登录
export function subLogin(data) {
  return api({
    url: "/buyer/account/sonLogin",
    method: "POST",
    data
  })
}
//获取图形验证码
export function getVerify(params) {
  return api({
    url: "/buyer/account/getVerify",
    method: "GET",
    params
  })
}
//图形验证码校验
export function checkVerify(data) {
  return api({
    url: "/buyer/account/checkVerify" + data,
    method: "POST",
  })
}
//获取用户信息
export function userInfo(params) {
  return api({
    url: "/buyer/account/getInfo",
    method: "GET",
    params
  })
}
//获取验证码
export function phoneCode(params) {
  return api({
    url: "/buyer/account/getPhoneCode" + params,
    method: "GET",
  })
}
//账户注册
export function register(data) {
  return api({
    url: "/buyer/account/register",
    method: "POST",
    data
  })
}
//获取账户信息
export function getAccount(params) {
  return api({
    url: "/buyer/account/getAccount",
    method: "GET",
    params
  })
}
//主账户忘记密码
export function userForGetEdit(data) {
  return api({
    url: "/buyer/account/mForgetPwd",
    method: "POST",
    data
  })
}
//子账户忘记密码
export function subForGetEdit(data) {
  return api({
    url: "/buyer/account/sForgetPwd",
    method: "POST",
    data
  })
}
//收货地址列表
export function addrList(params) {
  return api({
    url: "/buyer/addr/getList",
    method: "GET",
    params
  })
}
//省市区列表
export function provList(params) {
  return api({
    url: "/buyer/certification/getAddrList",
    method: "GET",
    params
  })
}
//修改默认收货地址
export function upDefault(data) {
  return api({
    url: "/buyer/addr/upIsDefault" + data,
    method: "POST"
  })
}
//商品分类
export function cateTree(params) {
  return api({
    url: "/buyer/goods/cate/getCateTree",
    method: "GET",
    params
  })
}
//根据id获取商品
export function getGoodsList(params) {
  return api({
    url: "/buyer/online/getGoodsList" + params,
    method: "GET",
  })
}
//根据id获取商品详情
export function getGoodsOne(params) {
  return api({
    url: "/buyer/online/getGoodsOne" + params,
    method: "GET"
  })
}
//加入购物车
export function addCart(data) {
  return api({
    url: "/buyer/cart",
    method: "POST",
    data
  })
}
//购物车列表
export function cartList(params) {
  return api({
    url: "/buyer/cart",
    method: "GET",
    params
  })
}
//购物车商品单删以及批量删除
export function delCart(data) {
  return api({
    url: "/buyer/cart/del" + data,
    method: "POST",
  })
}
//更新商品数量
export function updateNum(data) {
  return api({
    url: "/buyer/cart/updateNum" + data,
    method: "POST",
  })
}
//查询当前店铺
export function currentStore(params) {
  return api({
    url: "/buyer/online/getOnlineOne" + params,
    method: "GET",
  })
}
//购物车提交订单
export function cartSubmit(data) {
  return api({
    url: "/buyer/order/submit",
    method: "POST",
    data
  })
}
//根据订单编号获取订单信息
export function orderDetail(params) {
  return api({
    url: "/buyer/order/getDetail" + params,
    method: "GET"
  })
}
//订单支付
export function pay(data) {
  return api({
    url: "/buyer/order/pay",
    method: "POST",
    data
  })
}
//退出登录
export function logout(data) {
  return api({
    url: "/buyer/account/logout",
    method: "POST",
    data
  })
}

// 获取图形验证码(商品码查询)
export function getVerify1(data) {
  return api({
    url: suyuanBaseUrl + "/service-base/v1/base/web/getVerify",
    method: "get",
    data
  })
}
// 校验验证码(商品码查询)
export function checkHnVerify(data) {
  return api({
    url: suyuanBaseUrl + "/service-base/v1/base/web/checkHnVerify",
    method: "POST",
    data
  })
}

// 商品码查询
export function selWebCodeInfo(data) {
  return api({
    url: suyuanBaseUrl + "/service-assure/registerInfo/selWebCodeInfo",
    method: "POST",
    data
  })
}
// 商品查询
export function selectOne(data) {
  let ct = 'application/x-www-form-urlencoded;charset=UTF-8'
  return api({
    url: suyuanBaseUrl + "/service-assure/produce/selectByProduceNoOrBatchNo",
    method: "GET",
    headers: {
      'Content-Type': ct
    },
    params: data,
  })
}
// 追溯码查询
export function selectByTraceCodeAll(data) {
  let ct = 'application/x-www-form-urlencoded;charset=UTF-8'
  return api({
    url: suyuanBaseUrl + "/service-assure/produce/selectByProduceNoOrBatchNo",
    method: "GET",
    headers: {
      'Content-Type': ct
    },
    params: data,
  })
}
// 防伪序列号查询
export function getSecurityCode(data) {
  let ct = 'application/x-www-form-urlencoded;charset=UTF-8'
  return api({
    url: suyuanBaseUrl + "/service-assure/produce/selectByProduceNoOrBatchNo",
    method: "GET",
    headers: {
      'Content-Type': ct
    },
    params: data,
  })
}
export default {
  login,//主账户登录
  subLogin,//子账户登录
  selectOne,//商品查询
  selectByTraceCodeAll, // 追溯码查询
  getSecurityCode, // 防伪序列号查询
  getVerify,//获取图形验证码
  getVerify1,//获取图形验证码
  checkVerify,//验证码校验
  checkHnVerify,//验证码校验
  selWebCodeInfo, // 商品码查询
  userInfo,//获取用户信息
  phoneCode,//手机验证码
  register,//账户注册
  getAccount,//获取账户信息
  userForGetEdit,//主账户忘记密码
  subForGetEdit,//子账户忘记密码
  addrList,//收货地址列表
  provList,//省市区列表
  upDefault,//修改默认收货地址
  cateTree,//商品分类
  getGoodsList,//根据id获取商品
  getGoodsOne,//根据id获取商品详情
  addCart,//加入购物车
  cartList,//购物车商品列表
  delCart,
  updateNum,//更新商品数量
  currentStore,//查询当前店铺
  cartSubmit,//购物车提交订单
  orderDetail,//根据id获取订单信息
  pay,//订单支付
  logout,//退出登录
}
