<template>
  <div class="shop-foot">
    <div class="ya315-container">
      <div class="shop-foot-item">
        <div>关于我们</div>
        <div>云市场简介</div>
        <div>联系我们</div>
      </div>
      <div class="shop-foot-item">
        <div>服务保障</div>
        <div>用户协议</div>
        <div>订购协议</div>
        <div>服务保障体系</div>
      </div>
      <div class="shop-foot-item">
        <div>帮助中心</div>
        <div>商品类目</div>
        <div>商品使用指导</div>
      </div>
    </div>
    <div class="ya315-container shop-foot-link">
      <a class="link-item">友情链接</a>
      <a class="link-item">云安溯源</a>
      <a class="link-item">立马放心</a>
      <a class="link-item">河南省食品农产品安全追溯平台</a>
      <a class="link-item">中国物品编码中心河南中心</a>
    </div>
  </div>
</template>

<script>
export default {
    name:'Footer',
  mounted() {
    $('#foot').hide();
  }
}
</script>

<style scoped>
  .shop-foot{height:458px;background:#212330;}
  .shop-foot>div{display: flex;justify-content: normal;}
  .shop-foot-item{width:183px;margin-left:32px;padding-top:47px;font-size: 18px;color:#9B9B9B;height:282px;}
  .shop-foot-item>div{margin-bottom:20px;}
  .shop-foot-item>div:first-child{color:#fff;font-size: 20px;margin-bottom:39px;}
  .shop-foot-link{border-top:1px solid #3B3F58;}
  .link-item{font-size: 18px;color:#9B9B9B;margin-right:60px;margin-top:24px;cursor: pointer;}
  .link-item:first-child{font-size: 20px;color:#fff;margin-right:75px;margin-left:32px;margin-top:22px;}
</style>