<template>
  <div id="scrollPage">
    <div class="top-height"></div>
    <div class="banner" :class="type">
      <div class="mask">
        <div class="ya315-container">
          <template>
            <div class="h1">仲记餐饮产业链</div>
            <div class="h2">
              实现上下游业务流程的一站式统一化管理，提升业务运营能效，构建餐饮业高增长数字生态
            </div>
          </template>
        </div>
      </div>
    </div>
    <div :class="isFixed ? 'fd' : ''" class="nav">
      <div class="ya315-container">
        <div @click="anchor(0)" :class="anchorNum == 0 ? 'cur' : ''">
          方案简介
        </div>
        <div @click="anchor(1)" :class="anchorNum == 1 ? 'cur' : ''">
          解决相关痛点
        </div>
        <div @click="anchor(2)" :class="anchorNum == 2 ? 'cur' : ''">
          平台架构
        </div>
        <div @click="anchor(3)" :class="anchorNum == 3 ? 'cur' : ''">
          核心业务架构能力
        </div>
        <div @click="anchor(4)" :class="anchorNum == 4? 'cur' : ''">
          品牌应用场景
        </div>
      </div>
    </div>
    <div>
      <div id="page1" class="common-title">方案简介</div>
      <template >
        <div class="common-desc ya315-container">
          整合供应商、物流商、品牌商和终端生鲜门店资源，以自有商品、B2B支付、终端交易和C端聚合支付为支撑、以品牌商和生鲜门店业务管理为基础，通过资源整
合，打造统一供应平台，对终端客户提供商品供应和营销服务，并为食品农产品的安全追溯打下坚实基础。
        </div>
      </template>

      <div id="page2" class="common-title">解决相关痛点</div>
      <div class="tongdian">
      </div>

      <div id="page3" class="common-title">平台架构</div>
      <div class="jiagou">
      </div>
      <div id="page4" class="common-title">核心业务架构能力</div>
      <div class="center">
      </div>
      <div id="page5" class="common-title">品牌应用场景</div>
      <div class="brands1 brands">
      </div>
      <div class="brands2 brands">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      type: '',
      anchorNum: 0,
      page2NavIndex: 0,
      isFixed: false,
    }
  },
  watch: {
    $route(to, form) {
      this.type = this.$route.query.type
    },
  },
  mounted() {
    $('#head,#foot').show()
    this.type = this.$route.query.type
    if (document.addEventListener) {
      //firefox
      document.addEventListener('DOMMouseScroll', this.scrollFuc, false)
    }
    //滚动滑轮触发scrollFunc方法 //ie 谷歌
    window.onmousewheel = document.onmousewheel = this.scrollFuc
  },
  methods: {
    scrollFuc(e) {
      e = e || window.event
      if (e.wheelDelta) {
        //第一步：先判断浏览器IE，谷歌滑轮事件
        if (document.documentElement.scrollTop > 300) {
          //当滑轮向上滚动时
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      } else if (e.detail) {
        //Firefox滑轮事件
        if (document.body.scrollTop > 300) {
          //当滑轮向上滚动时
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      }
    },
    anchor(n) {
      this.anchorNum = n
      let top = 0
      if (n == 0) {
        top = $('#page1').offset().top - 72 - 60
      } else if (n == 1) {
        top = $('#page2').offset().top - 72 - 60
      } else if (n == 2) {
        top = $('#page3').offset().top - 72 - 60
      } else if (n == 3) {
        top = $('#page4').offset().top - 72 - 60
      } else {
        top = $('#page5').offset().top - 72 - 60
      }
      $('html').scrollTop(top)
    },
  },
}
</script>

<style scoped>
.top-height {
  height: 60px;
}
.banner {
  width: 100%;
  height: 300px;
}
.banner {
  background-image: url(../../assets/img/khal4.png);
  background-size: 100% 100%;
  position: relative;
}

.mask {
  width: 100%;
  height: 100%;
  /* background: rgba(3, 36, 70, 0.48); */
  position: absolute;
}
.mask > div {
  position: relative;
  height: 300px;
}
.banner .h1 {
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 82px;
}
.banner .h2 {
  color: #e3e3e3;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 175px;
  left: 50%;
  margin-left: -410px;
  width: 820px;
}
.nav {
  height: 54px;
  border-bottom: 1px solid #e8e9eb;
  width: 100%;
  background: #fff;
  overflow: hidden;
}
.nav.fd {
  position: fixed;
  top: 60px;
  z-index: 10;
}
.nav > div {
  display: flex;
  justify-content: center;
}
.nav > div > div {
  width: 154px;
  margin: 0 36px;
  height: 54px;
  border-bottom: 2px solid transparent;
  line-height: 54px;
  font-size: 16px;
  color: #52565b;
  text-align: center;
  cursor: pointer;
}
.nav > div > div.cur {
  color: #1890ff;
  border-bottom-color: #1890ff;
}
.tongdian{
  background-image: url(../../assets/img/khal3.png);
  width:1200px;
  height: 376px;
  background-size: 100% 100%;
  margin: 50px auto;
}
.jiagou{
  background-image: url(../../assets/img/khal5.png);
  width:1200px;
  height: 840px;
  background-size: 100% 100%;
  margin: 50px auto;
}
.center{
  background-image: url(../../assets/img/khal6.png);
  width:1176px;
  height: 837px;
  background-size: 100% 100%;
  margin: 50px auto;
}
.brands{
  width:1200px;
  height: 460px;
  background-size: 100% 100%;
  margin: 50px auto;
}
.brands1{
  background-image: url(../../assets/img/khal2.png);

}
.brands2{
  background-image: url(../../assets/img/khal1.png);

}
.common-title {
  color: #2690fd;
  font-size: 30px;
  margin-top: 72px;
  text-align: center;
  font-weight: 700;
}
.common-title-md {
  color: #121a25;
  font-size: 26px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 24px;
}
.common-desc {
  color: #52565b;
  font-size: 16px;
  margin-top: 39px;
  line-height: 36px;
  letter-spacing: 1px;
}
.common-desc-sm {
  margin-bottom: 12px;
  color: #707070;
  font-size: 16px;
  text-align: center;
}
.info-img {
  text-align: center;
  margin-top: 77px;
}
.function-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 48px;
}
.function-info > div {
  width: 390px;
  height: 173px;
  background: url(../../assets/img/bg-solution-function.png);
  margin-bottom: 30px;
  position: relative;
}
.function-info .name {
  position: absolute;
  top: 36px;
  left: 30px;
  font-size: 20px;
  color: #ffffff;
}
.function-info .desc {
  position: absolute;
  top: 89px;
  left: 30px;
  right: 40px;
  font-size: 12px;
  color: #deecfe;
}
.customer-case {
  height: 461px;
  display: flex;
  margin-top: 65px;
  margin-bottom: 400px;
}
.case-left {
  width: 247px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e9eb;
  justify-content: center;
  text-align: center;
}
.case-left > div {
  height: 115px;
  font-size: 16px;
  color: #121a25;
  text-align: center;
  line-height: 115px;
}
.case-right {
  width: 953px;
  background: url(../../assets/img/bg-case-img1.png);
}
.case-right-mask {
  background: url(../../assets/img/bg-case-mask.png);
  width: 724px;
  height: 461px;
  float: right;
  color: #fff;
  position: relative;
}
.case-right.xyncp {
  background: url(../../assets/img/xyncp-foot.png);
}
.case-right.szzf {
  background: url(../../assets/img/szzf-foot2.png);
}
.case-right.sxmd {
  background: url(../../assets/img/img-sxmd.png);
}
.case-right.cyls {
  background: url(../../assets/img/bg-case-img1.png);
}
.case-right.cyxls {
  background: url(../../assets/img/img-cyxls.png);
}
.case-right.hgyt {
  background: url(../../assets/solution/img-hgyt2.png);
}
.case-right.dfxc {
  background: url(../../assets/img/img-dfxc2.png);
}
.case-right.zfzs {
  background: url(../../assets/solution/zfzs-case.png);
}
.case-title {
  font-size: 34px;
  position: absolute;
  top: 84px;
  left: 179px;
}
.case-desc {
  font-size: 20px;
  position: absolute;
  top: 183px;
  left: 179px;
  width: 480px;
}
.case-btn {
  width: 123px;
  height: 39px;
  border: 1px solid #ffffff;
  text-align: center;
  line-height: 38px;
  font-size: 20px;
  position: absolute;
  left: 179px;
  bottom: 94px;
  color: #ffffff;
  cursor: pointer;
}
.flex-box {
  justify-content: space-between;
}
.tp1 > div {
  width: 240px;
  height: 200px;
  position: relative;
  margin-top: 52px;
}
.tp1 > div > img {
  position: absolute;
  top: 36px;
  left: 50%;
  margin-left: -24px;
}
.tp1 > div > .title {
  position: absolute;
  top: 100px;
  width: 100%;
  text-align: center;
  color: #121a25;
  font-size: 20px;
}
.tp1 > div > .desc {
  position: absolute;
  top: 144px;
  width: 180px;
  text-align: center;
  color: #707070;
  left: 50%;
  margin-left: -90px;
}
.tp2 > div {
  width: 574px;
  height: 247px;
  border: 1px solid #dde0e7;
  border-radius: 4px;
  position: relative;
  margin-top: 60px;
}
.tp2 > div > img {
  position: absolute;
  top: 31px;
  left: 31px;
}
.tp2 > div > .title {
  font-size: 28px;
  color: #121a25;
  top: 43px;
  left: 114px;
  position: absolute;
}
.tp2 > div > .desc {
  width: 512px;
  left: 30px;
  top: 127px;
  position: absolute;
  font-size: 20px;
  color: #707070;
}
.tp3 {
  margin-top: 60px;
}
.tp3 > div {
  width: 1002px;
  margin: 0 auto 50px;
  position: relative;
  height: 52px;
}
.tp3 > div > img {
  position: absolute;
  top: 8px;
  left: 0;
}
.tp3 > div > .title {
  font-size: 20px;
  color: #121a25;
  font-weight: bold;
  left: 72px;
  top: 13px;
  position: absolute;
}
.tp3 > div > .desc {
  width: 764px;
  right: 0;
  top: 0;
  color: #52565b;
  font-size: 16px;
  vertical-align: middle;
  position: absolute;
}
.tp4 {
  width: 1002px;
  margin: 76px auto 0;
  justify-content: space-between;
}
.tp4 > img {
  margin-top: 34px;
}
.tp4 > div > div {
  color: #52565b;
  font-size: 16px;
  margin-bottom: 29px;
  line-height: 21px;
  white-space: nowrap;
  position: relative;
  right: -62px;
}
.tp4 > div > div > img {
  margin-right: 10px;
}
.tp5 {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.tp5 > div {
  width: 390px;
  height: 286px;
  position: relative;
  border: 1px solid #dde0e7;
  border-radius: 4px;
}
.tp5 > div > img {
  position: absolute;
  top: 48px;
  left: 30px;
}
.tp5 > div > img.img1 {
  top: 44px;
  left: 36px;
}
.tp5 > div > .title {
  position: absolute;
  font-size: 26px;
  color: #121a25;
  top: 57px;
  left: 91px;
}
.tp5 > div > .title.title1 {
  color: #121a25;
  top: 50px;
  left: 102px;
}
.tp5 > div > .desc {
  color: #707070;
  font-size: 16px;
  width: 328px;
  height: 113px;
  top: 127px;
  left: 30px;
  position: absolute;
}
.pro-advantage {
  position: absolute;
  top: 127px;
  left: 36px;
  color: #52565b;
  font-size: 14px;
}
.pro-advantage > div {
  margin-bottom: 10px;
}
.tp6 {
  margin-top: 54px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tp6 > div {
  width: 586px;
  height: 201px;
  border: 1px solid #dde0e7;
  position: relative;
  margin-bottom: 30px;
}
.tp6 > div > .title {
  color: #121a25;
  font-size: 20px;
  top: 44px;
  left: 41px;
  position: absolute;
}
.tp6 > div > .desc {
  color: #52565b;
  font-size: 16px;
  position: absolute;
  top: 97px;
  left: 41px;
  width: 505px;
}

.page2-common {
  box-shadow: 0 5px 21px 0 rgb(0 0 0 / 5%);
}
.page2-common-nav {
  height: 54px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 40px;
}
.page2-common-nav > div {
  border-top: 4px solid #333333;
  text-align: center;
  line-height: 54px;
  background: #f7f7f7;
}
.page2-common-nav > div.cur {
  border-top-color: #197bdf;
  background: #ffffff;
  color: #197bdf;
}
.c5 > div {
  width: 20%;
}
.c6 > div {
  width: 200px;
}
.page2-common-content {
  padding: 40px 80px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #333;
}
.page2-common-content > div {
  width: 544px;
  padding-bottom: 36px;
}
.page2-common-content > img {
  position: relative;
  top: 26px;
}
.page2-common-content > div h2 {
  font-size: 20px;
  font-weight: 700;
  padding-left: 40px;
  margin-bottom: 15px;
}
.page2-common-content > div h3 {
  font-size: 16px;
  line-height: 24px;
  padding-left: 40px;
  margin-bottom: 25px;
  font-weight: 400;
}
.page2-common-content > div .con {
  background: url(../../assets/img/bingo.png) 3px 5px no-repeat;
  font-size: 16px;
  padding-left: 40px;
  color: #999;
  line-height: 24px;
  margin-bottom: 15px;
}
.page2-common-content > div .con > span {
  display: block;
  color: #333333;
}
.page2-common-content > div a {
  width: 176px;
  height: 44px;
  background: #197bdf;
  font-size: 18px;
  color: #fff;
  line-height: 44px;
  margin-left: 40px;
  text-align: center;
  margin-top: 30px;
  display: block;
  cursor: pointer;
}
</style>
