<template>
  <div class="cart">
    <div style="height: 60px;"></div>
    <div style="width: 80%;display: flex;align-items: center;margin: 0 auto;">
      <router-link :to="{path:'/shop'}">
        <img style="margin-top:9px;" width="340" height="36" src="../assets/img/logo-header.svg" alt="食安链家">
      </router-link>
      <div style="font-weight: bold;font-size: 20px;padding-top: 10px;margin-left: 50px;color: #1890ff;">购物车</div>
    </div>
    <div style="height: 20px;"></div>
    <div class="my_table">
    <el-table @selection-change="handleSelectionChange" :cell-style="tableRowClassName" :data="shopList" style="width: 80%;margin: 0 auto;" :header-cell-style="{color:'#606266'}">
      <el-table-column type="selection" align="center" :selectable="selectHandle"></el-table-column>
      <el-table-column label="商品信息">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <div style="width: 80px;height: 80px;background: black;">
              <img v-show="scope.row.isOnline == 1" width="80" height="80" :src="JSON.parse(scope.row.goodsImgs)[0]" alt="">
              <p v-show="scope.row.isOnline == 0" style="color: white;text-align: center;line-height: 80px;">商品已下架</p>
            </div>
            <div style="margin-left: 20px;">
              <p>{{ scope.row.goodsTitle }}</p>
              <p>{{ scope.row.specName }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单价">
        <template slot-scope="scope">
          <span>￥{{ scope.row.price/100 | price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="数量">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.quantity" @change="handleChange(scope.row)" size="mini" :min="1"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="周期单位">
        <template slot-scope="scope">
          <span v-show="scope.row.dateUnit == 1">天</span>
          <span v-show="scope.row.dateUnit == 2">月</span>
          <span v-show="scope.row.dateUnit == 3">年</span>
          <span v-show="scope.row.dateUnit == 4">长期</span>
        </template>
      </el-table-column>
      <el-table-column label="金额">
        <template slot-scope="scope">
          <span>￥{{ scope.row.price/100 * scope.row.quantity | price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm @confirm="domDel(scope.row)" title="确定要删除此商品吗？">
            <el-button slot="reference" type="text" style="color: red;">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div style="width: 100%;background: white;position: fixed;bottom: 0;right: 0;left: 0;z-index: 1000;box-shadow: 0 -6px 16px 0 rgba(0,0,0,0.08);">
      <div v-show="shopList.length>0" style="width: 70%;height: 100px;margin: 0 auto;display: flex;justify-content: space-between;line-height: 100px;">
        <div>
          <span><el-button type="text" @click="selectDel" style="color: red;">批量删除</el-button></span>
          <span style="margin-left: 50px;">已选商品</span><span style="font-weight: bold;font-size: 18px;color: red;padding: 10px;">{{ list.length }}</span><span>件</span>
        </div>
        <div style="display: flex;">
          <span style="margin-left: 50px;">合计（不含运费）：</span><span style="font-weight: bold;color: red;font-size: 20px;margin-right: 20px;">￥{{ sum==''?0:sum | price }}</span>
          <div style="width: 100px;height: 100px;" v-show="this.list.length == 0"><el-button title="请勾选要结算的商品" :disabled="true">结算</el-button></div>
          <div v-show="this.list.length > 0"><el-button style="background: rgb(230, 65, 66);color: white;" @click="goOrder">结算</el-button></div>
        </div>
      </div>
    </div>
    <div style="height: 100px;"></div>
  </div>
</template>

<script>
  export default {
    name: "cart",
    data(){
      return {
        shopList:[],
        sum:0,
        list:[],
        options:[
          {label:"1个月",value:"month1"},
          {label:"6个月",value:"month6"},
          {label:"1年",value:"year1"},
        ],
        options1:[
          {label:"单次",value:"singleTime"},
        ],
        title:"1111",
        timer:null,
        state:false,
      }
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    mounted() {
      this.userList()
    },
    methods:{
      userList(){
        this.http.cartList().then(res=>{
          if(res.code == "1"){
            this.shopList = res.data
            console.log(res.data)
          }
        })
      },
      handleChange(row){
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          let str = `?id=${row.id}&num=${row.quantity}`
          this.http.updateNum(str).then(res=>{
            if(res.code == "1"){}
          })
        }, 100);
      },
      handleSelectionChange(val){
        this.list = val
        this.sum = ""
        val.forEach((item,index)=>{
          this.sum = Number(this.sum + (item.price * item.quantity)/100)
        })
      },
      goOrder(){
        localStorage.setItem("order",JSON.stringify(this.list))
        this.$router.push({path:"/confirmOrder"})
      },
      tableRowClassName({ row, rowIndex }) {
        return "background: white";
      },
      selectDel(){
        if(this.list.length>0){
          let arr = []
          this.list.forEach((item,index)=>{
            arr.push(item.id)
          })
          this.http.delCart('?ids=' + arr.toString()).then(res=>{
              if(res.code == "1"){
                this.$message({message:"删除成功",type:"success"})
                this.userList()
              }
          })
        }else{
          this.$message({message:"请选择要删除的商品",type:"warning"})
        }
      },
      domDel(item){
        this.http.delCart('?ids=' + item.id).then(res=>{
            if(res.code == "1"){
              this.$message({message:"删除成功",type:"success"})
              this.userList()
            }
        })
      },
      //禁用某一行的多选框
      selectHandle(row){
        if(row.isOnline == 0){
          return false
        }else if(row.isOnline == 1){
          return true
        }
      },
    },
  }
</script>

<style scoped>
  .content{
    width: 80%;
    margin: 0 auto;
  }

  .my_table >>> .el-table__row>td{
    /* 去除表格线 */
    border: none;
  }
  .el-table .warning-row {
    background: linear-gradient(to right,#fba721, #fcce68, #faefb4) !important;
  }

  .el-table .success-row {
    background: linear-gradient(to right,#fba721, #fcce68, #faefb4) !important;
  }
</style>
