import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)
//以下是网页内容切换的页面
import Index from "../views/Index"
import ProductCenter from "../views/ProductCenter"
import DetailsOne from "../views/product_details/DetailsOne"
//登录页面
import Login from "../login"
//引入公司简介路由
import CompanyProfile from "../views/CompanyProfile"
//引入新闻动态路由
import News from "../views/News"
//引入公司公告路由
import companyTell from "../views/NewsDetails/companyTell"
//引入每个新闻接口路由one
import NewsOne from "../views/NewsDetails/NewsOne"
//引入每个新闻接口路由two
import NewsTwo from "../views/NewsDetails/NewsTwo"
//引入每个新闻接口路由three
import NewsThree from "../views/NewsDetails/NewsThree"
//引入每个新闻接口路由four
import NewsFour from "../views/NewsDetails/NewsFour"
//引入每个新闻接口路由five
import NewsFive from "../views/NewsDetails/NewsFive"
//引入每个新闻接口路由six
import NewsSix from "../views/NewsDetails/NewsSix"
//引入技术服务接口路由
import TechnologyService from "../views/TechnologyService.vue"
//引入解决方案接口路由
import Solution from "../views/Solution"
import cooperation from "../views/cooperation"
import cooperation2 from "../views/cooperation/index1"
//关于我们
import About from "../views/About/index"
import Case from "../views/About/case"
import CaseDetail from "../views/About/caseDetail"
// 登录中心
import loginCenter from "../views/loginCenter/index"
import searchDetail from "../views/loginCenter/searchDetail.vue"

//
import info from "../views/loginCenter/info.vue"

// 应用商城
import Shop from "../views/Shop/index"
import ShopList from "../views/Shop/list"
import ShopDetail from "../views/Shop/detail"
import SolutionNew from "../views/Solution/index"
import ShopServer from "../views/Shop/server"

// 客户案例
import CustomerCase from "../views/customerCase/index"

//购物车
import cart from "../components/cart"
import confirmOrder from "../views/Shop/confirmOrder" //确认订单
import hntIndex from "../views/hntPay/index" //订单支付页面
import success from "../views/Shop/success" //支付成功结果页
const routes = [
  //首页接口
  {
    path: "/",
    name: "index",
    component: Index
  },
  //关于我们
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/case",
    name: "case",
    component: Case
  },
  {
    path: "/caseDetail",
    name: "caseDetail",
    component: CaseDetail
  },
  //产品中心接口
  {
    path: "/product",
    name: "product",
    component: ProductCenter
  },
  //产品中心小轮播图中产品详情接口
  {
    path: "/product/one",
    name: "details_one",
    component: DetailsOne
  },
  // 公司简介(关于我们)路由接口
  {
    path: "/company",
    name: "company",
    component: CompanyProfile
  },
  //新闻动态News接口
  {
    path: "/news",
    name: "news",
    component: News
  },
  {
    path: "/news/tell",
    name: "news_tell",
    component: companyTell
  },
  //引入新闻接口中的每个详情接口one
  {
    path: "/news/one",
    name: "news_one",
    component: NewsOne
  },
  //引入新闻接口中的每个详情接口two
  {
    path: "/news/two",
    name: "news_two",
    component: NewsTwo
  },
  //引入新闻接口中的每个详情接口three
  {
    path: "/news/three",
    name: "news_three",
    component: NewsThree
  },
  //引入新闻接口中的每个详情接口three
  {
    path: "/news/four",
    name: "news_four",
    component: NewsFour
  },
  //引入新闻接口中的每个详情接口three
  {
    path: "/news/five",
    name: "news_five",
    component: NewsFive
  },
  //引入新闻接口中的每个详情接口three
  {
    path: "/news/six",
    name: "news_six",
    component: NewsSix
  },
  //引入技术服务组件
  {
    path: "/technology",
    name: "technology",
    component: TechnologyService
  },
  //引入解决方案组件
  {
    path: "/solution",
    name: "solution",
    component: Solution
  },
  // 客户案例
  {
    path: "/customerCase",
    name: "customerCase",
    component: CustomerCase
  },
  // 引入登录页面
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/cart",
    name: "购物车",
    component: cart
  },
  {
    path: "/confirmOrder",
    name: "确认订单",
    component: confirmOrder
  },
  {
    path: "/orderSubmit",
    name: "订单支付",
    component: hntIndex
  },
  {
    path: "/success",
    name: "结果页",
    component: success
  },
  //引入应用商店首页
  {
    path: "/shop",
    name: "shop",
    component: Shop
  },
  {
    path: "/shop/list",
    name: "shopList",
    component: ShopList
  },
  {
    path: "/shop/detail",
    name: "shopDetail",
    component: ShopDetail
  },
  {
    path: "/shop/server",
    name: "ShopServer",
    component: ShopServer
  },
  {
    path: "/solutionNew",
    name: "solutionNew",
    component: SolutionNew
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: cooperation
  },
  {
    path: "/cooperation/index1",
    name: "cooperation2",
    component: cooperation2
  },
  {
    path: "/loginCenter",
    name: "loginCenter",
    component: loginCenter
  },
  {
    path: "/loginCenter/searchDetail",
    name: "searchDetail",
    component: searchDetail
  },
  {
    path: "/loginCenter/info",
    name: "info",
    component: info
  }
]

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes
})

export default router
