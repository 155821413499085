<template>
  <div>
    <shopTop @list="getList"></shopTop>
    <div class="shop-list">
      <div class="ya315-container">
        <div class="list-nav-brand">
          <router-link :to="{path:'/shop/list'}">全部分类</router-link>
          <template v-if="pathName">
            >
            <span>{{pathName}}</span>
          </template>
        </div>
        <div class="shop-list-content">
          <div class="pro-list">
            <div class="pro-screen">
              <div>
                <div>产品分类：</div>
                <div style="font-size: 17px;" @click="allCate('0')" :class="searchForm.proType=='0'?'cur':''" >全部</div>
                <div v-for="(item,index) in cateList" :key="index" style="font-size: 17px;" :class="searchForm.proType==item.id?'cur':''" @click="search(item)">
                  {{ item.cateName }}
                </div>
              </div>
              <div style="height: 50px;" v-show="childList.length>0">
                <div style="margin-left: 140px;display: flex;width: 100%;">
<!--                  cateToo(item)-->
                  <p @click="cateToo(item)" v-for="item in childList" :class="cateId==item.id?'cur':''" style="width: 100px;text-align: center;line-height: 40px;color: #666;font-size: 16px;">
                    {{ item.cateName }}
                  </p>
                </div>
              </div>
<!--              <div>-->
<!--                <div>交付方式：</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==0?'cur':''" @click="search('turnOverType',0)">全部</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==1?'cur':''" @click="search('turnOverType',1)">镜像类</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==2?'cur':''" @click="search('turnOverType',2)">SAAS类</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==3?'cur':''" @click="search('turnOverType',3)">下载类</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==4?'cur':''" @click="search('turnOverType',4)">API类</div>-->
<!--                <div style="font-size: 17px;" :class="searchForm.turnOverType==5?'cur':''" @click="search('turnOverType',5)">人工服务类</div>-->
<!--              </div>-->
              <div>
                <div>价格：</div>
                <div style="font-size: 17px;" :class="searchForm.priceType==0?'cur':''" @click="search('priceType',0)">全部</div>
                <div style="font-size: 17px;" :class="searchForm.priceType==1?'cur':''" @click="search('priceType',1)">免费/试用</div>
                <div style="font-size: 17px;" :class="searchForm.priceType==2?'cur':''" @click="search('priceType',2)">1-98</div>
                <div style="font-size: 17px;" :class="searchForm.priceType==3?'cur':''" @click="search('priceType',3)">99-998</div>
                <div style="font-size: 17px;" :class="searchForm.priceType==4?'cur':''" @click="search('priceType',4)">9998以上</div>
              </div>
            </div>
            <div class="pro-sort">
              <div>
                <span>综合排序</span>
                <span @click="isPrice==1?isPrice=2:isPrice=1">价格 <i :class="isPrice==1?'cur':''"></i><i :class="isPrice==2?'cur':''"></i></span>
                <span @click="isTime==1?isTime=2:isTime=1">时间 <i :class="isTime==1?'cur':''"></i><i :class="isTime==2?'cur':''"></i></span>
              </div>
              <div>共找到 <span class="cur" style="margin-right:0;width:auto;">{{proList.length?proList.length:0}}</span> 件</div>
            </div>
            <div>
              <router-link :to="{path:'/shop/detail',query:{id:item.id,pathName:pathName}}" class="pro-item" v-for="(item,idx) in proPageList" :key="idx">
                  <img class="pro-item-img" :src="item.logo" alt="">
                <div class="pro-item-name">{{item.goodsTitle}}</div>
                <div class="pro-item-desc">{{item.introduce}}</div>
                <div class="pro-item-paytype">接入类型：{{item.accessTypeName}}</div>
                <div class="pro-item-price"><span>￥{{ item.price/100 | price }}</span>元/{{ item.dateNum>1?item.dateNum:'' }}
                  <span v-show="item.dateUnit==1">天</span>
                  <span v-show="item.dateUnit==2">月</span>
                  <span v-show="item.dateUnit==3">年</span>
                  <span v-show="item.dateUnit==4">长期</span>
                </div>
                <div class="pro-item-source">{{ item.storeName }}</div>
              </router-link>
            </div>
            <div class="page-content" v-show="proPageList.length>0">
              <div>
                <div class="page-btn" :class="page.currentPage==1?'cant':''" @click="toPrev">上一页</div>
                <div class="page-num" @click="toPage(item)" :class="item==page.currentPage?'cur':''" v-for="(item,index) in page.pageTotal">{{item}}</div>
                <div class="page-btn" :class="page.currentPage==page.pageTotal?'cant':''" @click="toNext">下一页</div>
              </div>
            </div>
          </div>
          <div class="pro-recommend">
            <div class="pro-recommend-title">同类热搜</div>
            <div class="pro-recommend-item">
              <div class="recommend-item-img"></div>
              <div class="recommend-item-name">扫码点餐小程序</div>
              <div class="recommend-item-desc">外卖，扫码点餐，无码点餐，预约排队 做自己专属的餐饮平台</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <shopFoot></shopFoot>-->
  </div>
</template>

<script>
  import shopTop from '@/components/Shop/Top.vue'
  import shopFoot from '@/components/Shop/Foot.vue'
  const PROLIST = require('../../../public/data/proList.json');
  export default {
    name: "list",
    data(){
      return {
        searchDefaultForm:{
          proType:0,
          turnOverType:0,
          priceType:0,
        },
        searchForm:{
          proType:"0",
        },
        pathName:null,
        proList:[],
        proPageList:[],
        page:{
          pageTotal:0,
          currentPage:1
        },
        isPrice:1,
        isTime:1,
        cateList:[],
        childList:[],
        cateId:"",
      }
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    components:{ shopTop, shopFoot },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, form) {
        this.init()
      }
    },
    methods:{
      init(){
        let param = this.$route.query
        this.pathName = param.pathName
        // this.resetSearchForm()
        this.http.cateTree().then(res=>{
            console.log(res.data)
          this.cateList = res.data
        })
        this.http.getGoodsList('').then(res=>{
          if(res.code == "1"){
            this.proList = res.data
            this.getPage()
          }
        })
      },
      getList(list){
        let arr = []
        this.http.getGoodsList('?sonCateId=' + this.$route.query.proType).then(res=>{
            if(res.code == "1"){
              this.proList = res.data
              this.getPage()
            }
        })
      },
      search(item){
        this.cateId = ""
        this.childList = item.children
        this.searchForm.proType = item.id
        this.http.getGoodsList('?parentCateId=' + item.id).then(res=>{
          if(res.code == "1"){
            console.log(res.data)
            this.proList = res.data
            this.getPage()
          }
        })
      },
      resetSearchForm(){
        this.searchForm = JSON.parse(JSON.stringify(this.searchDefaultForm))
        this.searchVal = ''
        this.proList = PROLIST
        this.getPage()
      },
      getPage(){
        let num = this.proList.length/10
        let cp = this.page.currentPage
        if(num > parseInt(num)){
          this.page.pageTotal=parseInt(num)+1
        }else{
          this.page.pageTotal=parseInt(num)
        }
        let startIndex = (cp - 1) * 10+1;
        let endIndex = cp * 10;
        endIndex = (endIndex > this.proList.length)? this.proList.length : endIndex;
        //遍历显示数据实现分页
        let list = []
        for(let i=startIndex-1;i<endIndex;i++){
            list.push(this.proList[i])
        }
        console.log(160,list)
        this.proPageList = list
      },
      toPage(page){
        console.log('当前页数',page)
        this.page.currentPage = page
        this.resetSearchForm()
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      },
      toPrev(){
        if(this.page.currentPage==1){
          return
        }else{
          this.page.currentPage-=1
          this.resetSearchForm()
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
      },
      toNext(){
        if(this.page.currentPage==this.page.pageTotal){
          return
        }else{
          this.page.currentPage+=1
          this.resetSearchForm()
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
      },
      cateToo(item){
        this.cateId = item.id
        this.http.getGoodsList('?sonCateId=' + item.id).then(res=>{
          if(res.code == "1"){
            this.proList = res.data
            this.getPage()
          }
        })
      },
      allCate(str){
        this.cateId = ""
        this.searchForm.proType = str
        this.http.getGoodsList('').then(res=>{
          if(res.code == "1"){
            this.proList = res.data
            this.getPage()
          }
        })
      },
    }
  }
</script>

<style scoped>
  .shop-list{background:#F5F7FD;box-shadow:inset 0px 15px 10px -15px rgba(38, 144, 253,.1);}
  .list-nav-brand{padding-top:36px;margin-left:8px;color:#52565B;font-size: 18px;margin-bottom:30px;}
  .list-nav-brand>span{margin:0 16px;}
  .shop-list-content{display: flex;justify-content: space-between;}
  .pro-list{width:944px;padding-bottom: 120px;}
  .pro-screen>div{height:54px;border-bottom:1px solid #E8E9EB;padding-left:24px;display: flex;background:#ffffff;}
  .pro-screen>div:last-child{border-bottom-color: transparent;}
  .pro-screen>div>div{margin-right:54px;line-height: 54px;color:#858A90;font-size: 18px;cursor:pointer;}
  .pro-screen>div>div.cur{color:#1890FF !important;}
  .pro-screen>div>div:first-child{width:162px;margin-right:0;}
  .pro-sort{height:54px;margin-top:36px;display: flex;justify-content: space-between;line-height: 54px;font-size: 16px;color:#858A90;padding-left:24px;background:#ffffff;}
  .pro-sort>div:last-child{margin-right:44px;}
  .pro-sort>div>span{margin-right:40px;display: inline-block;width:64px;position:relative;cursor: pointer;}
  .pro-sort>div>span.cur{color:#1890FF;}
  .pro-sort>div>span>i{display: block;position:absolute;}
  .pro-sort>div>span>i:first-child{border:4px solid transparent;border-bottom-color: #CDCDCD;right:20px;top:18px;}
  .pro-sort>div>span>i:last-child{border:4px solid transparent;border-top-color: #CDCDCD;right:20px;bottom:16px;}
  .pro-sort>div>span>i:first-child.cur{border-bottom-color:#2C2C2C;}
  .pro-sort>div>span>i:last-child.cur{border-top-color:#2C2C2C;}
  .pro-item{margin-top:30px;background:#ffffff;height:269px;width:944px;position:relative;display: block;border-color:transparent;}
  .pro-item>div{position:absolute;}
  .pro-item-img{width:100px;height:50px;top:24px;left:30px;margin-top: 30px;margin-left: 20px;}
  .pro-item-name{top:31px;left:142px;color:#121A25;font-size: 20px;}
  .pro-item-desc{top:71px;left:142px;color:#858A90;font-size: 16px;width:480px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
  .pro-item-paytype{font-size: 20px;color:#858A90;left:142px;top:129px;}
  .pro-item-source{height:80px;width:calc(100% - 142px);left:142px;bottom:0;line-height: 80px;border-top:1px solid #E8E9EB;color:#1890FF;font-size: 20px;}
  .pro-item-price{font-size: 20px;color:#52565B;top:79px;right:84px;}
  .pro-item-price>span{color:#FE5E54;font-size: 20px;}
  .pro-recommend{width:230px;height:301px;background:#ffffff;}
  .pro-recommend-title{height:70px;line-height: 70px;color:#858A90;font-size: 18px;padding-left:35px;border-bottom:1px solid #E8E9EB;}
  .pro-recommend-item{height:252px;text-align: center;position:relative;}
  .pro-recommend-item>div{position:absolute;}
  .recommend-item-img{width:80px;height:80px;background:#ECECEC;top:32px;left:50%;margin-left:-40px;}
  .recommend-item-name{color:#121A25;font-size: 18px;text-align: center;top:136px;width:100%;}
  .recommend-item-desc{color:#858A90;font-size: 14px;bottom:33px;left:18px;right:18px;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;}
  .page-content{height:28px;margin-top:20px;}
  .page-content>div{float:right;display: flex;justify-content: space-between;}
  .page-content .page-num{cursor:pointer;margin-left:10px;width:28px;height:28px;border:1px solid #E8E9EB;border-radius: 4px;text-align: center;line-height: 28px;font-size: 12px;}
  .page-content .page-num.cur{color:#2690FD;}
  .page-content .page-btn{cursor:pointer;color:#2690FD;margin-left:10px;width:78px;height:28px;border:1px solid #E8E9EB;border-radius: 4px;text-align: center;line-height: 28px;font-size: 12px;}
  .page-content .page-btn.cant{color:#E8E9EB;cursor: not-allowed;}
  .cur{color:#1890FF !important;}
</style>
