<template>
  <div>
    <div class="cl cl--light cl--float cl--jigsaw" style="min-width: 220px">
      <div
        class="cl_panel"
        :style="{
          bottom: '44px',
          'padding-bottom': '15px',
          display: hover ? 'block' : 'none',
        }"
        @mouseover="handelMouseover"
        @mouseleave="handelMouseleave"
      >
        <div class="cl_panel-placeholder">
          <div
            class="cl_bgimg"
            style="border-radius: 2px; box-sizing: border-box"
          >
            <img
              class="cl_bg-img"
              style="border-radius: 2px; box-sizing: border-box"
              src=""
            />
            <img class="cl_jigsaw" src="" />
            <div class="cl_inference cl_inference--0" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--1" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--2" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--3" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--4" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--5" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--6" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
            <div class="cl_inference cl_inference--7" draggable="true">
              <img class="cl_inference__img" draggable="false" />
              <span class="cl_inference__border"></span>
            </div>
          </div>
          <div
            class="cl_loadbox"
            v-if="loading"
            style="border-radius: 2px; box-sizing: border-box"
          >
            <div class="cl_loadbox__inner">
              <div class="cl_loadicon"></div>
              <span class="cl_loadtext">加载中...</span>
            </div>
          </div>
          <div class="cl_top">
            <div class="cl_refresh" @click="refresh"></div>
          </div>
        </div>
      </div>
      <div
        class="cl_control"
        :style="{
          height: '44px',
          'border-radius': '2px',
          borderColor: error ? '#FF4D4F' : success ? '#52ccba' : '',
          backgroundColor: error ? '#FF4D4F' : success ? '#d2f4ef' : '',
        }"
      >
        <div
          class="cl_slide_indicator"
          style="height: 44px; border-radius: 2px; box-sizing: border-box"
        ></div>
        <div
          :style="{
            width: '44px',
            'border-radius': '2px',
            backgroundColor: error
              ? '#FF4D4F'
              : success
              ? '#52ccba'
              : hover
              ? '#1991fa'
              : '#ffffff',
          }"
          @mouseup.stop="handleMouseUp"
          @mouseleave="handelMouseleave"
          @mousedown="handleMouseDown"
          @mouseover="handelMouseover"
          class="cl_slider"
        >
          <!-- 分支二兼容旧接口 -->
          <div v-if="success" class="cl_slider__icon slider_success"></div>
          <div v-else-if="error" class="cl_slider__icon slider_error"></div>
          <div
            v-else
            :class="hover ? 'slider_white' : 'slider_black'"
            class="cl_slider__icon"
          ></div>
        </div>
        <div
          class="cl_tips"
          :style="{
            'line-height': '44px',
          }"
        >
          <span class="cl_tips__icon"></span>
          <span class="cl_tips__text cl-fallback__tip">{{ tip }}</span>
          <div class="cl_tips__answer hide">
            <span class="cl_tips__point"></span>
            <img class="cl_tips__img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api.js'

export default {
  name: 'Index',
  data() {
    return {
      searchHeight: '',
      loading: false,
      down: false, // false松开，true按下
      vSlideLeftpx: 0, // 滑块距离左侧的距离
      error: false,
      success: false,
      token: '',
      startTime: 0,
      endTime: 0,
      hover: false,
      tip: '向右拖动滑块填充拼图',
    }
  },
  mounted() {
    this.getSearchHeight()
    this.getVerify()
    const that = this
    document.onmousemove = this.handelMouseMove
    document.onmouseup = this.handleMouseUp
    document
      .querySelector('.cl_slider')
      .addEventListener('touchstart', function (e) {
        that.hover = true
        that.handleMouseDown(e)
      })
    document
      .querySelector('.cl_slider')
      .addEventListener('touchmove', function (e) {
        that.handelMouseMove(e)
        e.preventDefault() // 若阻止默认事件，则在长按元素上滑动时，页面是不滚动的，
      })

    document
      .querySelector('.cl_slider')
      .addEventListener('touchend', function (e) {
        // 若手指离开屏幕时，时间小于我们设置的长按时间，则为点击事件，清除定时器，结束长按逻辑
        that.handleMouseUp(e)
        that.hover = false
      })
  },
  methods: {
    getSearchHeight() {
      if (this.$refs.searchBar) {
        this.$nextTick(() => {
          let obj = this.$refs.searchBar.$el
          this.searchHeight = `calc(100vh - ${
            275 + parseInt(window.getComputedStyle(obj).height)
          }px)`
        })
      }
    },
    refresh() {
      this.error = false
      this.success = false
      this.setLeft(0)
      this.getVerify()
    },
    getVerify() {
      this.tip = '向右拖动滑块填充拼图'
      this.vSlideLeftpx = 0
      this.loading = true

      api.getVerify1({}).then((response) => {
        // 填充画布
        const img = document.querySelector('.cl_bg-img')
        console.log('response-------------', response)
        const data = response.data
        img.src = data.bg

        document.querySelector('.cl_jigsaw').src = data.slider
        document.querySelector('.cl_jigsaw').style.top = data.y + 'px'
        this.token = data.token
        this.loading = false
      })
    },
    /* 鼠标移动到滑块上方 */
    handelMouseover() {
      if (!this.success) {
        this.hover = true
      }
    },
    /* 鼠标在滑块上点击 */
    handleMouseDown() {
      if (!(this.success || this.loading || this.error)) {
        this.down = true
        this.tip = ''
        // 记录滑块位置
        this.vSlideLeftpx = document
          .querySelector('.cl_slider')
          .getBoundingClientRect().left
      }
    },
    /* 鼠标离开滑块 */
    handelMouseleave() {
      if (this.down) {
      } else {
        this.hover = false
      }
    },
    /* 鼠标在滑块上松开 */
    handleMouseUp() {
      this.down = false
      this.endTime = new Date().getTime()
      if (this.startTime === 0) {
        return
      }
      if (this.vSlideLeftpx === 0) {
        return
      }
      const x =
        document.querySelector('.cl_jigsaw').getBoundingClientRect().left -
        this.vSlideLeftpx
      api.checkHnVerify({ x: x, token: this.token }).then((res) => {
        let response = {
          data: res.data.verify,
        }
        if (response.data !== '-1') {
          this.success = true
          this.hover = false
          this.$emit('success', { verify: response.data })
        } else {
          this.error = true
          this.tip = '校验不通过！'
          this.$emit('fail')
          setTimeout(() => {
            this.error = false
            this.refresh()
          }, 1200)
        }
        this.$emit('complete')
      })
    },
    /* 鼠标移动 */
    handelMouseMove(e) {
      if (!this.down) {
        this.startTime = 0
        return
      }
      if (this.startTime === 0) {
        this.startTime = new Date().getTime()
      }
      let x
      if (!e.touches) {
        // 兼容移动端
        x = e.clientX
      } else {
        // 兼容PC端
        x = e.touches[0].pageX
      }
      const width = document.querySelector('.cl_tips').offsetWidth
      const sliderWidth = document.querySelector('.cl_slider').offsetWidth

      if (x - this.vSlideLeftpx <= 0) {
        this.setLeft(0)
      } else if (x - this.vSlideLeftpx >= width - sliderWidth) {
        this.setLeft(width - sliderWidth)
      } else {
        this.setLeft(x - this.vSlideLeftpx)
      }
    },
    setLeft(left) {
      document.querySelector('.cl_slider').style.left = left + 'px'
      document.querySelector('.cl_jigsaw').style.left = left + 'px'
    },
  },
}
</script>

<style src="./css/index.css" scoped></style>
