<template>
  <div class="loginCenter">
    <div class="loginCenter-header">
      <div class="loginCenter-header-center">
        <div class="logo" @click="toBack">
          <img src="../../assets/img/logo1.png" alt="食安链家" />
          <div class="title">登录中心</div>
        </div>
        <div class="goBack" @click="toBack">返回官网</div>
      </div>
    </div>
    <div class="loginCenter-ascend">
      <div class="loginCenter-ascend-center">
        <div class="search-box">
          <div class="search">
            <div class="searchTab">
              <span :class="goodsShow ? 'cur' : ''" @click="goodsClick"
                >商品条码/放心码查询</span
              >
              <span :class="codeShow ? 'cur' : ''" @click="codeClick"
                >追溯码查询</span
              >
            </div>
            <div class="searchCon" v-if="goodsShow">
              <input
                type="text"
                placeholder="请输入商品条码/放心码"
                v-model="sn"
                style="width: 264px; border-right: 0px"
              />
              <div
                data-v-577b1418=""
                style="
                  width: 2px;
                  background: #fff;
                  height: 30px;
                  margin-left: -1px;
                "
              ></div>
              <input
                type="text"
                placeholder="生产批次（选填）"
                v-model="lot"
                style="border-left: 0px; width: 201px"
              />
            </div>
            <div class="searchCon" v-if="codeShow">
              <input
                type="text"
                placeholder="搜索追溯码"
                v-model="traceCode"
                style="width: 465px"
              />
            </div>
            <div class="searchCon" v-if="securityCodeShow">
              <input
                type="text"
                placeholder="搜索防伪序列号"
                v-model="securityCode"
                style="width: 465px"
              />
            </div>
            <div class="verify">
              <verify
                ref="verify"
                style="width: 318px; height: 43.22px"
                @success="(v) => (verify = v.verify)"
                @complete="isLoginError = false"
              />
              <div class="s-btn" @click="toSearch">查询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loginCenter-content">
      <div class="center">
        <div class="left">
          <img
            src="../../assets/img/loginCenter_1.png"
            alt="食安链家登陆中心"
          />
        </div>
        <div class="right">
          <div
            class="internetList"
            v-for="(item, index) in internetList"
            :key="index"
          >
            <div class="internetList-title">
              <img :src="item.titleUrl" :alt="item.title" :title="item.title" />
            </div>
            <div
              class="internetList-child"
              v-for="(child, index1) in item.children"
              :key="index1"
            >
              <div class="internetList-child-tille">{{ child.title }}</div>
              <div class="internetList-child-itemList">
                <div
                  class="items"
                  :class="grandson.url || grandson.popover ? 'pointer' : ''"
                  @click="toLink(grandson.url)"
                  v-for="(grandson, index2) in child.children"
                  :key="index2"
                  :style="'background-color:' + item.color"
                >
                  <el-popover
                    placement="top"
                    v-if="grandson.popover"
                    width="185"
                    trigger="hover"
                  >
                    <img
                      :src="grandson.popover.url"
                      alt=""
                      style="width: 160px; height: 160px"
                    />
                    <div
                      slot="reference"
                      style="display: inline-block; width: 100%; height: 100%"
                    >
                      {{ grandson.title }}
                    </div>
                  </el-popover>
                  <div v-else style="display: inline-block">
                    {{ grandson.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verify from "./verify/index.vue"
import img1 from "@/assets/img/title1.png"
import img2 from "@/assets/img/title2.png"
import img3 from "@/assets/img/title3.png"
import lmfx from "@/assets/img/lmfx.jpg"
import jc from "@/assets/img/jc.jpg"
import hnt from "@/assets/img/hnt.jpg"
import qindan from "@/assets/img/qindan.jpg"

export default {
  name: "loginCenter",
  components: { verify },
  data() {
    return {
      goodsShow: true,
      codeShow: false,
      securityCodeShow: false,
      sn: "",
      lot: "",
      traceCode: "",
      securityCode: "",
      isLoginError: true,
      verify: null,
      internetList: [
        {
          titleUrl: img1,
          color: "rgba(54, 114, 238, 1)",
          title: "食安链家",
          children: [
            {
              title: "食安农互通-禽蛋养殖系统",
              children: [
                {
                  title: "养殖管理系统",
                  url: "https://raise.yunan315.com/store/login"
                },
                {
                  title: "禽蛋交易平台",
                  url: "",
                  popover: {
                    url: qindan
                  }
                }
              ]
            },
            {
              title: "食安商互通-云商赢经销商系统",
              children: [
                {
                  title: "商家管理后台",
                  url: "https://salj.yunan315.com/dealer/login"
                },
                {
                  title: "业务移动端",
                  url: ""
                },
                {
                  title: "订货小程序",
                  url: ""
                }
              ]
            },
            {
              title: "食安云管家-餐饮生鲜",
              children: [
                {
                  title: "智慧餐饮系统",
                  // url: 'https://restaurant.yunan315.com/dish/login',
                  url: "/loginCenter/info"
                },
                {
                  title: "智慧新零售系统",
                  url: ""
                }
              ]
            }
          ]
        },
        {
          titleUrl: img2,
          color: "rgba(67, 173, 133, 1)",
          title: "立码放心 安全·安心·安康",
          children: [
            {
              title: null,
              children: [
                {
                  title: "政府追溯平台",
                  url: "https://henantrace.org.cn/#/home"
                },
                {
                  title: "3D可视化",
                  url: ""
                },
                {
                  title: "政府监管平台",
                  url: "https://henantrace.org.cn/retrospectSupervise/login"
                },
                {
                  title: "政府稽查移动端",
                  url: "",
                  popover: {
                    url: jc
                  }
                },
                {
                  title: "企业管理后台",
                  url: "https://suyuan.limafangxin.com/#/login"
                },
                {
                  title: "企业移动端",
                  url: ""
                },
                {
                  title: "公共查询移动端",
                  url: "",
                  popover: {
                    url: lmfx
                  }
                }
              ]
            }
          ]
        },
        {
          titleUrl: img3,
          color: "rgba(25, 81, 196, 1)",
          title: "云链金融",
          children: [
            {
              title: null,
              children: [
                {
                  title: "平台管理端",
                  url: "https://hntpay.cn/platform/login"
                },
                {
                  title: "商家管理后台",
                  url: "https://hntpay.cn/merchant/login"
                },
                {
                  title: "商家移动端",
                  url: "",
                  popover: {
                    url: hnt
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    goodsClick() {
      this.goodsShow = true
      this.codeShow = false
      this.securityCodeShow = false
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    codeClick() {
      this.goodsShow = false
      this.codeShow = true
      this.securityCodeShow = false
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    securityCodeClick() {
      this.goodsShow = false
      this.codeShow = false
      this.securityCodeShow = true
      this.traceCode = ""
      this.sn = ""
      this.lot = ""
      this.securityCode = ""
    },
    toSearch() {
      const num = /^[0-9]*$/
      let str = ""
      let message = ""
      let type = 1
      let traceCode = this.traceCode
      let sn = this.sn
      let lot = this.lot
      let securityCode = this.securityCode
      if (this.goodsShow) {
        str = this.sn
        type = 1
        message = "请输入商品条码/放心码"
      } else if (this.codeShow) {
        str = this.traceCode
        message = "请输入追溯码"
        type = 2
      } else if (this.securityCodeShow) {
        str = this.securityCode
        message = "请输入防伪序列号"
        type = 3
      }
      if (str === "") {
        this.$message({
          message: message,
          offset: "400"
        })
      } else if (!num.test(str)) {
        this.$message({
          message: "请输入数字",
          offset: "400"
        })
      } else {
        if (!this.verify) {
          this.$message({
            message: "请先完成验证",
            offset: "400"
          })
        } else {
          let routeData = this.$router.resolve({
            name: "searchDetail",
            query: { str: str, lot: lot, type: type }
          })
          window.open(routeData.href, "_blank")
        }
      }
    },
    toLink(url) {
      if (url) {
        window.open(url, "_blank")
      }
    },
    toBack() {
      this.$router.push("/")
    }
  }
}
</script>
<style lang="scss" scoped>
.loginCenter {
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-top: 72px;
  &-header {
    width: 100%;
    min-width: 1200px;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 72px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
    padding: 0 60px;
    z-index: 999;
    // display: flex;
    // justify-content: ;
    &-center {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        height: 72px;
        cursor: pointer;
        .title {
          display: inline-block;
          margin-left: 50px;
        }
      }
      .goBack {
        color: #fff;
        background-color: #0366f1;
        text-align: center;
        line-height: 42px;
        width: 127px;
        height: 42px;
        border-radius: 2px;
        cursor: pointer;
      }
      .goBack:hover {
        background-color: rgba(3, 102, 241, 0.7);
      }
    }
  }

  &-ascend {
    width: 100%;
    height: 323px;
    background-image: url("../../assets/img/bgi.png");
    background-size: cover;
    &-center {
      width: 100%;
      min-width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
    }
  }

  &-content {
    margin-top: 30px;
    .center {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .left {
      img {
        width: 400px;
        height: auto;
      }
    }
    .right {
      width: 650px;
      .internetList {
        margin-bottom: 20px;
        &-title {
          img {
            max-height: 80px;
            max-width: 130px;
          }
          margin-left: 30px;
          margin-bottom: 10px;
        }
        &-child {
          &-tille {
            font-size: 20px;
            font-weight: 550;
            color: #0a1d37;
            margin-bottom: 15px;
            margin-left: 30px;
          }
          &-itemList {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            .items {
              width: 186px;
              height: 52px;
              text-align: center;
              color: #fff;
              line-height: 52px;
              margin-bottom: 15px;
              margin-left: 30px;
              border-radius: 4px;
            }
            .pointer {
              cursor: pointer;
            }
            .items:hover {
            }
          }
        }
      }
    }
  }

  .search-box {
    position: absolute;
    right: 13%;
    top: 50%;
    width: 512px;
    z-index: 2;
    transform: translateY(-50%);
    .number {
      font-size: 22px;
      color: #595959;
      letter-spacing: 10px;
      margin-bottom: 22px;

      .bigFont {
        font-size: 70px;
        color: #2c2c2c;
        font-weight: 600;
        text-shadow: 3px 2px 1px rgba(255, 255, 255, 0.3);
      }
    }

    .search {
      right: 0;
      margin: 0 auto;
    }

    .searchTab {
      color: #3c3c3c;
      font-size: 16px;
      span {
        width: 232px;
        height: 48px;
        line-height: 42px;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;
        // background: #f4f7ff;
        color: rgba(41, 66, 117, 1);
        font-size: 16px;
        font-family: Source Han Sans, Source Han Sans;
        font-weight: 500;
        border: 1px solid #fff;
        border-bottom: 0px;
        &.cur {
          background: linear-gradient(
            360deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          font-size: 16px;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 700;
          color: rgba(54, 114, 238, 1);
        }
      }
    }

    .searchCon {
      width: 465px;
      height: 48px;
      // background: #ffffff;
      // border: 1px solid #5278d4;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        padding: 0;
        border: 1px solid #fff;
        background: none;
        height: 48px;
        font-size: 16px;
        color: #333;
        outline: none;
        text-indent: 24px;
        width: 264px;
        // border: 0px!important;
      }

      input::-webkit-input-placeholder {
        color: #aab5d1;
      }

      i {
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background: #5277f7;
      }
    }
    .verify {
      width: 465px;
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-left: ;
      .s-btn {
        font-family: 微软雅黑;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 130px;
        height: 44px;
        background-image: linear-gradient(90deg, #0d74ff, #4760ff);
        text-align: center;
        font-size: 18px;
        color: #fff;
        line-height: 44px;
      }
    }
  }
}
</style>
