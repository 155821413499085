<template>
  <div>
    <shopTop @serviceBtn="servicebtn"></shopTop>
    <service-provider @backservice="backService" v-show="serviceShow"></service-provider>
    <div v-show="!serviceShow">
      <div class="shop-page1">
        <div class="shop-page1-bg">
          <div class="ya315-container">
            <div class="page1-left" @mouseleave="navIndex=-1">
              <div :class="navIndex==idx?'cur':''" class="page1-left-item" v-for="(item,idx) in navList" :key="idx" @mouseenter="navIndex = idx">
                <div class="nav-label">{{item.cateName}}</div>
                <div class="nav-fast">
                  <div v-if="cIdx<3" v-for="(cItem,cIdx) in item.children" :key="cIdx">{{cItem.cateName}}</div>
                </div>
                <div class="arrow-right"> > </div>
              </div>
              <div v-if="navIndex!=-1" class="left-menu">
                <div class="menu-list">
<!--                  -->
                  <div v-for="(item,idx) in navList[navIndex].children" :key="idx">
                    <div @click="toList(item)" style="cursor: pointer;" class="menu-list-title">{{item.cateName}}</div>
<!--                    <div class="menu-list-child">-->
<!--                      <div @click="toDetail(cItem)" v-for="(cItem,cIdx) in item.childList" :key="cIdx">{{cItem.proName}}</div>-->
<!--                    </div>-->
                  </div>
                </div>
                <div class="menu-fast">
                  <div>
                    <img src="../../assets/img/img-shop-nav-smdc.png" width="48" height="48" alt="扫码点餐">
                    <div>扫码点餐</div>
                    <div>食客分流，减少排队，提高点餐效率</div>
                  </div>
                  <div>
                    <img src="../../assets/img/img-shop-nav-mdsy.png" width="48" height="48" alt="门店收银">
                    <div>门店收银</div>
                    <div>收银一体机，满足全场景收银需求</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page1-center">
              <b-carousel class="page1-swiper"
                          :interval="3000"
                          indicators
                          img-width="678"
                          img-height="282"
              >
                <!-- Slides with product slot -->
                <!-- Note the classes .d-block and .product-fluid to prevent browser default image alignment -->
                <b-carousel-slide img-alt="云安溯源科技有限公司">
                  <template v-slot:img>
                    <div class="slide-item">
                      <h1>帮助企业转型数字化管理</h1>
                      <img src="../../assets/img/shop-banner1.png" width="233" height="210" alt="">
                      <div>
                        <div>生产企业</div>
                        <div>餐饮连锁</div>
                        <div>生鲜超市</div>
                      </div>
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <div class="page1-center-bottom">
                <div>
                  <div class="title">商品条码</div>
                  <div class="info">办理条码，提供防伪服务</div>
                  <img src="../../assets/img/shop-banner1-1-1.png" width="48" height="48" alt="">
                </div>
                <div>
                  <div class="title">智慧新餐饮系统</div>
                  <div class="info">数字化方式，多维度管理</div>
                  <img src="../../assets/img/shop-banner1-2-1.png" width="48" height="48" alt="">
                </div>
              </div>
            </div>
            <div class="page1-right">
              <div v-show="show">
                <div style="display: flex;align-items: center;justify-content: space-around;margin-top: 20px;">
                  <div style="width: 40px;height: 40px;border-radius: 50%;">
                    <img src="../../assets/loginIcon.png" alt="">
                  </div>
                  <div>
                    <div style="font-size: 12px;width: 150px;text-align: left;">北京九方愉悦商贸有限公司</div>
                    <div style="font-size: 14px;width: 140px;text-align: left;color: #1890FF;cursor: pointer;">进入管理后台</div>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-around;line-height: 40px;margin-top: 30px;">
                  <div>
                    <p>待付款</p>
                    <p style="color: #2690FD;font-size: 18px;">1</p>
                  </div>
                  <div>
                    <p>使用中</p>
                    <p style="color: #2690FD;font-size: 18px;">2</p>
                  </div>
                  <div>
                    <p>即将到期</p>
                    <p style="color: #2690FD;font-size: 18px;">0</p>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-around;line-height: 80px;">
                  <div><el-button size="mini" round>订单</el-button></div>
                  <div><el-button size="mini" round>合同</el-button></div>
                  <div><el-button size="mini" round>工单</el-button></div>
                </div>
              </div>
              <div v-show="!show">
                <div style="display: flex;align-items: center;justify-content: space-around;margin-top: 20px;">
                  <!--                  <img src="../../assets/img/page1-img1.png" alt="">-->
                  <div style="width: 40px;height: 40px;border-radius: 50%;">
                    <img src="../../assets/loginIcon.png" alt="">
                  </div>
                  <div>
                    <div style="font-size: 12px;width: 150px;text-align: left;color: #1890FF;cursor: pointer;" @click="GoLogin"">登录</div>
                  </div>
                </div>
                <div style="font-size: 14px;display: flex;justify-content: space-around;line-height: 40px;margin-top: 30px;color: #929292;">
                  <p>登录后可查看更多服务</p>
                </div>
                <div style="display: flex;justify-content: space-around;line-height: 80px;">
                  <div @click="GoLogin"><el-button size="mini" round>订单</el-button></div>
                  <div @click="GoLogin"><el-button size="mini" round>合同</el-button></div>
                  <div @click="GoLogin"><el-button size="mini" round>工单</el-button></div>
                </div>
                </div>
  <!--              <div class="page1-right-title">欢迎来到食安链家云市场</div>-->
  <!--              <img src="../../assets/img/img-shop-page1-right.png" width="132" height="96" alt="">-->
                <div class="page1-news">
                  <div class="title">动态</div>
                  <div class="news-item">办理条码，提供免费防伪服务</div>
                  <div class="news-item">河南商丘民权农批商贸城</div>
                  <div class="news-item">仲记餐饮管理有限公司成功上线</div>
                  <div class="news-item">关于农产品食品安全追溯问题</div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="shop-page2">
        <div class="ya315-container">
          <div class="shop-nav-common">
            <div class="left">
              <div>热门服务</div>
              <div @click="nav1=0" :class="nav1==0?'cur':''">小程序</div>
              <div @click="nav1=1" :class="nav1==1?'cur':''">防伪追溯</div>
              <div @click="nav1=2" :class="nav1==2?'cur':''">企业SaaS</div>
            </div>
            <router-link :to="{path:'/shop/list'}" class="right">更多</router-link>
          </div>
          <div class="shop-pro-list">
            <router-link v-if="nav1==0" :to="{path:'/shop/detail',query:{id:item.id}}" class="shop-pro-item" v-for="item in list1">
              <div class="pro-name">{{item.proName}}</div>
              <div class="pro-desc">{{item.proDesc}}</div>
              <div class="pro-advantage">
                <div v-for="adv in item.proAdvantage"><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:7px;">{{adv}}</div>
              </div>
              <div v-if="item.proLabelType==1" class="pro-label">试用7天</div>
              <div v-if="item.proLabelType==2" class="pro-label green">安全易用</div>
              <div class="pro-price">
                <span>￥</span>
                <span>{{item.proPrice}}</span>/年
              </div>
            </router-link>
            <router-link v-if="nav1==1" :to="{path:'/shop/detail',query:{id:item.id}}" class="shop-pro-item" v-for="item in list11">
              <div class="pro-name">{{item.proName}}</div>
              <div class="pro-desc">{{item.proDesc}}</div>
              <div class="pro-advantage">
                <div v-for="adv in item.proAdvantage"><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:7px;">{{adv}}</div>
              </div>
              <div v-if="item.proLabelType==1" class="pro-label">试用7天</div>
              <div v-if="item.proLabelType==2" class="pro-label green">安全易用</div>
              <div class="pro-price">
                <span>￥</span>
                <span>{{item.proPrice}}</span>/年
              </div>
            </router-link>
            <router-link v-if="nav1==2" :to="{path:'/shop/detail',query:{id:item.id}}" class="shop-pro-item" v-for="item in list12">
              <div class="pro-name">{{item.proName}}</div>
              <div class="pro-desc">{{item.proDesc}}</div>
              <div class="pro-advantage">
                <div v-for="adv in item.proAdvantage"><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:7px;">{{adv}}</div>
              </div>
              <div v-if="item.proLabelType==1" class="pro-label">试用7天</div>
              <div v-if="item.proLabelType==2" class="pro-label green">安全易用</div>
              <div class="pro-price">
                <span>￥</span>
                <span>{{item.proPrice}}</span>/年
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="shop-page3">
        <div class="ya315-container">
          <div class="shop-nav-common">
            <div class="left">
              <div>防伪追溯</div>
              <div @click="nav2=0" :class="nav2==0?'cur':''">企业服务</div>
              <div @click="nav2=1" :class="nav2==1?'cur':''">政府服务</div>
            </div>
            <router-link :to="{path:'/shop/list',query:{type:2,pathName:'防伪追溯'}}" class="right">更多</router-link>
          </div>
          <div v-if="nav2==0" class="shop-page3-content">
            <div>
              <div class="name">企业服务</div>
              <div class="desc">条码不仅是商品的“身份证”，还是实现商业现代化、商品数字化、智慧供应链的基础。</div>
              <div class="price"><span>￥<b style="font-size: 30px;">8000</b></span>/年</div>
              <router-link :to="{path:'/shop/detail',query:{id:100013}}" class="btn">查看详情</router-link>
            </div>
            <div>
              <a >
                <img src="../../assets/img/icon-shop-page3-1.png" alt="">
                <div class="name">商品条码</div>
                <div class="desc">十三位数字标准码和八位数字缩短码</div>
              </a>
              <a style="border-right:0;">
                <img src="../../assets/img/icon-shop-page3-2.png" alt="">
                <div class="name">商品二维码</div>
                <div class="desc">基于国家标准《商品二维码》（GB/T 33993-2017）生成，更加精准和可靠</div>
              </a>
              <a style="border-bottom:0;">
                <img src="../../assets/img/icon-shop-page3-3.png" alt="">
                <div class="name">条码查询</div>
                <div class="desc">通过输入批次号或条码，查询商品流 通情况</div>
              </a>
              <a style="border-bottom:0;border-right:0;">
                <img src="../../assets/img/icon-shop-page3-4.png" alt="">
                <div class="name">入驻平台</div>
                <div class="desc">签约入驻“立码放心”平台，完整的追溯流程</div>
              </a>
            </div>
          </div>
          <div v-if="nav2==1" class="shop-page3-content type2">
            <div>
              <div class="name">政府服务</div>
              <div class="desc">建立产品追溯标准体系、信息安全体系、运营管理体系、绩效评估体系、组织保障体系</div>
              <div class="price"><span>￥<b style="font-size: 30px;">200万</b>起</span></div>
              <router-link :to="{path:'/shop/detail',query:{id:100014}}" class="btn">查看详情</router-link>
            </div>
            <div>
              <a >
                <img src="../../assets/img/icon-shop-page3-1.png" alt="">
                <div class="name">食品追溯平台</div>
                <div class="desc">建立食品农产品追溯监管平台，可从、原料、采购、制作、加工、包装、订单流向、销售，全数码身份监控</div>
              </a>
              <a style="border-right:0;">
                <img src="../../assets/img/icon-shop-page3-2.png" alt="">
                <div class="name">公共查询系统</div>
                <div class="desc">消费者可以通过微信小程序、网站查询产品追溯信息，追溯信息 内容包括食品信息、企业信息、检验等信息。</div>
              </a>
              <a style="border-bottom:0;">
                <img src="../../assets/img/icon-shop-page3-3.png" alt="">
                <div class="name">监管子系统</div>
                <div class="desc">通过对数据源分析，形成可视化监管系统，供政府查看食品农产品流通及价格信息。</div>
              </a>
            </div>
          </div>
          <img src="../../assets/img/img-shop-page3.png" width="1200" height="88" style="margin-top:83px;" alt="">
        </div>
      </div>
      <div class="shop-page4">
        <div class="ya315-container">
          <div class="shop-nav-common">
            <div class="left">
              <div>SaaS系统</div>
            </div>
            <router-link :to="{path:'/shop/list',query:{type:5,pathName:'SAAS综合平台'}}" class="right">更多</router-link>
          </div>
          <div class="shop-pro-list">
            <router-link :to="{path:'/shop/detail',query:{id:item.id}}" class="shop-pro-item" v-for="item in list3">
              <div class="pro-name">{{item.proName}}</div>
              <div class="pro-desc">{{item.proDesc}}</div>
              <div class="pro-advantage">
                <div v-for="adv in item.proAdvantage"><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:7px;">{{adv}}</div>
              </div>
              <div v-if="item.proLabelType==1" class="pro-label">试用7天</div>
              <div v-if="item.proLabelType==2" class="pro-label green">安全易用</div>
              <div class="pro-price">
                <span>￥</span>
                <span>{{item.proPrice}}</span>/年
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="shop-page5">
        <div class="ya315-container">
          <div class="shop-nav-common">
            <div class="left">
              <div>硬件专区</div>
              <div @click="nav3=0" :class="nav3==0?'cur':''">收银设备</div>
              <div @click="nav3=1" :class="nav3==1?'cur':''">打印设备</div>
              <div @click="nav3=2" :class="nav3==2?'cur':''">收银配件</div>
              <div @click="nav3=3" :class="nav3==3?'cur':''">物料耗材</div>
            </div>
            <router-link :to="{path:'/shop/list',query:{type:4,pathName:'硬件设备'}}" class="right">更多</router-link>
          </div>
          <div class="shop-pro-list">
            <router-link :to="{path:'/shop/detail',query:{id:item.id}}" class="shop-pro-item type2" v-for="(item,index) in list4">
              <img v-if="index==0" src="../../assets/img/img-pro-scsyj.png" width="173" height="208" alt="手持收银机">
              <img v-if="index==1" src="../../assets/product/24-1-1.png" width="173" height="208" alt="手持收银机">
              <img v-if="index==2" src="../../assets/product/T2.png" width="173" height="208" alt="手持收银机">
              <img v-if="index==3" src="../../assets/product/25-1-1.png" width="173" height="208" alt="手持收银机">
              <div class="pro-name">{{item.proName}}</div>
              <div class="pro-name2">{{item.proName2}}</div>
              <div class="pro-desc">{{item.proDesc}}</div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="shop-page6">
        <div class="ya315-container">
          <div class="shop-nav-common">
            <div class="left">
              <div>客户案例</div>
            </div>
          </div>
          <div class="customer-case">
            <div class="case-left">
              <div @click="caseType=1"><img src="../../assets/img/img-logo-zj.png" width="120" height="75" alt="仲记餐饮"></div>
              <div @click="caseType=2"><img src="../../assets/img/img-logo-by.png" width="198" height="48" alt="河南省标准化研究院"></div>
              <div @click="caseType=3"><img src="../../assets/img/img-logo-tf.png" width="181" height="37" alt="郑州同发牧业有限公司"></div>
              <div @click="caseType=4">民权县新农市场置业有限公司</div>
            </div>
            <div class="case-right" :class="'type'+caseType">
              <div class="case-right-mask">
                <div v-if="caseType==1" class="case-title">餐超供应链平台</div>
                <div v-if="caseType==2" class="case-title">尉氏县众邦农贸市场有限公司</div>
                <div v-if="caseType==3" class="case-title">蛋鸡溯源供应链系统</div>
                <div v-if="caseType==4" class="case-title">民权县追溯管理系统</div>
                <div v-if="caseType==1" class="case-desc">插上互联网的翅膀，搭建属于自己餐厅的“线上线下一体”的生态系统</div>
                <div v-if="caseType==2" class="case-desc">根据追溯平台、监管子系统、公共查询系统，打造 县域农产品追溯体系和城市应急体系</div>
                <div v-if="caseType==3" class="case-desc">蛋鸡综合服务平台和生态圈，打造供应链平台，打造蛋鸡绿色供应链运作模式</div>
                <div v-if="caseType==4" class="case-desc">根据追溯平台、监管子系统、公共查询系统，打造 县域农产品追溯体系和城市应急体系</div>
                <div class="case-btn">阅读案例</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <shopFoot></shopFoot>-->
  </div>
</template>

<script>
  import shopTop from '@/components/Shop/Top.vue'
  import shopFoot from '@/components/Shop/Foot.vue'
  import ServiceProvider from "../../components/Shop/ServiceProvider";
  const NAVLIST = require('../../../public/data/proTypeList.json');
  export default {
    name: "index",
    components:{ shopTop, shopFoot,ServiceProvider },
    data(){
      return {
        show:false,
        userInfo:{},
        serviceShow:false,
        navIndex:-1,
        navList:[],
        list1:[
          {id:"100008",proName:'扫码点餐',proDesc:'食客分流，减少排队，提高点餐效率',proAdvantage:['一桌一码、多人点餐','智能推荐菜品，提升客单价','分时段菜单自动呈现'],proLabelType:1,proPrice:890},
          {id: "100010",proName:'外卖小程序',proDesc:'一站多端，多营销方式率',proAdvantage:['优惠券点餐更优惠','会员制享受更多功能','广告位营销展示'],proLabelType:2,proPrice:890},
          {id: "100012",proName:'配送APP',proDesc:'便捷商品配送与运费结算',proAdvantage:['实时掌握配送情况','便于运费结算','直观管理和维护配送人员'],proLabelType:1,proPrice:890},
          {id: "100002",proName:'在线商城小程序',proDesc:'食客分流，减少排队，提高点餐效率',proAdvantage:['海量商品管理及用户数据','自定义优惠促销活动','完整的会员体系'],proLabelType:1,proPrice:890},
        ],
        list11:[
          {id:"100013",proName:'商品条码',proDesc:'线上办理商品条码',proAdvantage:['追溯源头、识别真伪、追踪去向','全面的O2O营销','防伪追溯、仓储物流'],proLabelType:1,proPrice:720},
          {id: "100014",proName:'食品农产品监管系统',proDesc:'可通过小程序快速查看商品批次信息',proAdvantage:['食品追溯','公共查询','可视化监管'],proLabelType:2,proPrice:'3000万'}
        ],
        list12:[
          {id:"100031",proName:'智慧新餐饮系统',proDesc:'为餐饮服务业商家提供软硬一体的SaaS系统',proAdvantage:['一桌一码、多人点餐','智能推荐菜品，提升客单价','分时段菜单自动呈现'],proLabelType:1,proPrice:200000},
          {id: "100032",proName:'智慧新生鲜系统',proDesc:'打造强大的进销存管理+线上商城+外卖聚合+会员管理等于一体的全方位服务',proAdvantage:['优惠券点餐更优惠','会员制享受更多功能','广告位营销展示'],proLabelType:2,proPrice:300000},
          {id: "100033",proName:'食品安全监管系统',proDesc:'与政府机构共同协作搭建食品农产品追溯系统',proAdvantage:['实时掌握配送情况','便于运费结算','直观管理和维护配送人员'],proLabelType:1,proPrice:'3000万'},
          {id: "100034",proName:'蛋鸡产业链系统',proDesc:'打造强大的进销存管理+线上商城+外卖聚合+会员管理等于一体的全方位服务',proAdvantage:['海量商品管理及用户数据','自定义优惠促销活动','完整的会员体系'],proLabelType:1,proPrice:400000}
        ],
        list3:[
          {id:"100031",proName:'智慧新餐饮系统',proDesc:'降本提效、提升体验、增加营收',proAdvantage:['一桌一码、多人点餐','手机多人点餐，菜谱多样化','全方位的VIP体系'],proLabelType:1,proPrice:200000},
          {id:"100032",proName:'智慧新生鲜系统',proDesc:'食客分流，减少排队，提高点餐效率',proAdvantage:['客户管理功能','大数据分析功能','降低生鲜成本'],proLabelType:2,proPrice:300000},
          {id:"100034",proName:'蛋鸡产业链系统',proDesc:'蛋鸡综合服务平台和生态圈',proAdvantage:['以提高鸡场效率','带动服务转型升级','节省交易费用'],proLabelType:1,proPrice:400000},
          {id:"100036",proName:'云物流系统',proDesc:'针对物流行业仓储、运输等场景',proAdvantage:['智能物联仓储管理','提升预分拣准确率','专属货运路线策略'],proLabelType:1,proPrice:1000000},
        ],
        list4:[
          {proName:'V2',proName2:'手持收银机',proDesc:'敢于创新，开启商用全面屏、双打印时代'},
          {id:'100024',proName:'L2S',proName2:'手持数据终端',proDesc:'全新一代PDA，专业助力每个场景数字化'},
          {id:'100025',proName:'T2s',proName2:'台式收银机',proDesc:'性能卓越，助力商户更好的经营成长'},
          {id:'100025',proName:'T2 LITE',proName2:'台式收银机',proDesc:'更灵活的组合，多场景适用'},
        ],
        caseType:1,
        nav1:0,
        nav2:0,
        nav3:0
      }
    },
    mounted(){
      this.http.getAccount().then(res=>{
          if(res.code == "1"){
            this.show = true
            this.userInfo = res.content
          }
        })
      this.http.cateTree().then(res=>{
        if(res.code == "1"){
          this.navList = res.data
        }
      })
    },
    methods:{
      // 登录
      GoLogin(){
        //  Go Login
        this.$router.push({
          path:"/login"
        })
      },
      servicebtn(){
        this.serviceShow = true
      },
      backService(){
        this.serviceShow = false
      },
      toList(item){
        this.$router.push({path:'/shop/list',query:{pathName:item.cateName,proType:item.id}})
      },
      toDetail(item){
        this.$router.push({path:'/shop/detail',query:{id:item.id}})
      }
    }
  }
</script>

<style scoped>
  .shop-page1-bg{width:100%;height:470px;background-image:url(../../assets/img/bg-shop-page1.png);background-size: 100% 100%;overflow: hidden;}
  .shop-page1-bg>div{display: flex;justify-content: space-between;}
  .page1-left{width:260px;background:#fff;float:left;height:449px;position:relative;z-index: 8;}
  .left-menu{width:695px;height:449px;top:0;position:absolute;left:260px;z-index: 10;background:#fff;box-shadow:  0 5px 10px rgba(0,0,0,.1);display: flex;justify-content: space-between;overflow-y: auto;}
  .menu-list-title{margin-top:30px;font-size: 16px;color:#52565B;}
  .menu-list-child{display: flex;flex-wrap:wrap;}
  .menu-list-child>div{margin-top:14px;color:#858A90;margin-right:24px;cursor: pointer;}
  .menu-fast{width:226px;margin-right:30px;margin-top:29px;}
  .menu-fast>div{width:100%;height:144px;background:#F9FCFF;position:relative;margin-bottom:24px;}
  .menu-fast>div>img{position:absolute;left:20px;top:50%;margin-top:-24px;}
  .menu-fast>div>div{position:absolute;left:80px;color:#52565B;top:31px;font-size: 16px;text-align: center;width:112px;}
  .menu-fast>div>div:last-child{font-size: 14px;top:64px;text-align: left;}
  .menu-list{margin-left:40px;width:358px;}
  .page1-left-item{position:relative;height:80px;width:260px;}
  .page1-left-item.cur{box-shadow:  0 10px 10px -10px rgba(0,0,0,.1);z-index: 11;overflow: hidden;background:#ffffff;}
  .nav-label{color:#121A25;font-size: 16px;position:absolute;left:24px;top:14px;}
  .nav-fast{position:absolute;left:24px;right:32px;bottom:16px;color:#858A90;font-size: 14px;display: flex;}
  .nav-fast>div{margin-right:18px;cursor: pointer;}
  .nav-fast>div:last-child{margin-right:0;}
  .arrow-right{font-size: 16px;position:absolute;top:24px;right:15px;color:#858A90;font-weight: lighter;}
  .page1-center{width:678px;height:449px;z-index: 1;}
  .slide-item{width:100%;height:282px;background:#0175EC;position:relative;}
  .slide-item>h1{color:#ffffff;font-size: 34px;font-weight: 600;width:204px;text-align: center;position:absolute;top:65px;left:80px;}
  .slide-item>img{position:absolute;top:36px;right:73px;}
  .slide-item>div{display: flex;justify-content: space-between;top:186px;left:59px;height:24px;width:246px;position:absolute;}
  .slide-item>div>div{width:76px;height:24px;background:#ffffff;color:#0175EC;text-align: center;line-height: 24px;}
  .page1-right{width:230px;height:440px;background:#fff;margin-top:19px;text-align: center;}
  .page1-right-title{color:#858A90;font-size: 14px;padding-top:32px;padding-bottom:30px;}
  .page1-swiper{width:100%;height:282px;margin-top:19px;}
  .page1-center-bottom{display: flex;justify-content: space-between;width:100%;margin-top:14px;height:118px;}
  .page1-center-bottom>div{background:url(../../assets/img/shop-banner1-1.png);color:#ffffff;width:334px;height:118px;position:relative;}
  .page1-center-bottom>div:last-child{background:url(../../assets/img/shop-banner1-2.png);}
  .page1-center-bottom>div>div{position:absolute;}
  .page1-center-bottom .title{left:41px;top:24px;font-size: 20px;}
  .page1-center-bottom .info{left:41px;top:65px;font-size: 14px;}
  .page1-center-bottom img{right:34px;top:35px;position:absolute;}
  .page1-news{height:176px;border-top:1px solid #E8E9EB;width:100%;}
  .page1-news .title{font-size: 14px;margin-top:13px;margin-left:19px;color:#1890FF;margin-bottom: 13px;text-align: left;}
  .page1-news .news-item{color:#858A90;font-size: 12px;margin:0 19px 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: left;}

  .shop-page2{height:587px;background:#F6F8FF;}

  .shop-nav-common{display: flex;justify-content: space-between;padding-top:97px;}
  .shop-nav-common .left{display: flex;}
  .shop-nav-common .right{color:#858A90;}
  .shop-nav-common .left>div{cursor:pointer;height:35px;padding:0 9px;color:#2A2A2A;font-size: 18px;margin-right:45px;border-bottom:1px solid transparent;box-sizing: border-box;}
  .shop-nav-common .left>div.cur{color:#2690FD;border-bottom:1px solid #2690FD;}
  .shop-nav-common .left>div:first-child{font-size: 24px;margin-right:64px;padding:0;cursor: default;}

  .shop-pro-list{margin-top:42px;display: flex;}
  .shop-pro-item{width:290px;height:320px;border-radius: 2px;background:#fff;margin-right:10px;}
  .shop-pro-item.type2{height:388px;text-align: center;}
  .shop-pro-item>img{margin-top:25px;}
  .pro-name{font-size: 22px;color:#121A25;margin-top:28px;margin-left:24px;}
  .pro-desc{font-size: 14px;color:#858A90;margin-top:11px;margin-left:24px;margin-bottom:18px;}
  .type2 .pro-name{margin-left:0;margin-top:8px;}
  .pro-name2{font-size: 18px;color:#121A25;margin-top:11px;}
  .type2 .pro-desc{margin-left:0;margin-top:13px;}
  .pro-advantage{margin-left:24px;color:#52565B;font-size: 14px;}
  .pro-advantage>div{margin-bottom:10px;}
  .pro-label{width:72px;height:26px;border:1px solid #FF9900;text-align: center;line-height: 26px;color:#FF9900;border-radius: 2px;margin:16px 0 24px 35px;}
  .pro-label.green{color:#1CCB73;border-color:#1CCB73;}
  .pro-price{color:#52565B;font-size: 14px;margin-left:24px;}
  .pro-price>span{color:#FE5E54;}
  .pro-price>span:last-child{font-size: 20px;}

  .shop-page3{height:816px;background:#EFF4FC;}
  .shop-page3-content{height:390px;display: flex;margin-top:42px;}
  .shop-page3-content>div:first-child{height:390px;width:336px;background:url(../../assets/img/page3-bg.png);color:#fff;}
  .shop-page3-content>div:first-child>.price{color:#fff;margin-left:24px;margin-top:45px;}
  .shop-page3-content>div:first-child>div:first-child{font-size: 22px;padding-top:25px;padding-left:24px;}
  .shop-page3-content>div:first-child>div:nth-child(2){padding-left:24px;padding-top:29px;width:280px;}
  .shop-page3-content>div:first-child>a{width:114px;height:36px;text-align: center;display: block;margin-top:76px;margin-left:24px;border-radius: 2px;border:1px solid #ffffff;color:#ffffff; }
  .shop-page3-content>div:last-child{height:390px;width:864px;}
  .shop-page3-content>div:last-child>a{float:left;width:430px;height:195px;box-sizing: content-box;border-right:1px solid #E8E9EB;border-bottom:1px solid #E8E9EB;position:relative;background:#fff;}
  .shop-page3-content>div:last-child>a>img{width:48px;height:48px;position:absolute;top:24px;left:24px;}
  .shop-page3-content>div:last-child>a>.name{color:#121A25;position:absolute;top:31px;left:87px;font-size: 20px;}
  .shop-page3-content>div:last-child>a>.desc{color:#858A90;position:absolute;top:74px;left:87px;width:283px;}
  .shop-page3-content.type2>div:last-child>a{width:285px;height:390px;border-top:0;border-bottom:0;border-right:1px solid #E8E9EB!important;}
  .shop-page3-content.type2>div:last-child>a:last-child{border-right:1px solid #ffffff!important;}
  .shop-page3-content.type2>div:last-child>a>.desc{width:238px;left:32px;top:109px;}


  .shop-page4{height:618px;background:#F6F8FF;}

  .shop-page5{height:610px;background:#EFF4FC;}

  .shop-page6{height:1006px;background:#fff;}
  .customer-case{height:461px;display: flex;margin-top:53px;}
  .case-left{width:247px;}
  .case-left>div{height:116px;border:1px solid #E8E9EB;margin-top:-1px;font-size: 16px;color:#121A25;text-align: center;line-height: 115px;}
  .case-left>div:first-child{margin-top:0;}
  .case-right.type1,.case-right.type3{width:953px;background:url(../../assets/img/bg-case-img1.png);}
  .case-right.type2{width:953px;background:url(../../assets/solution/zfzs-case.png);}
  .case-right.type4{width:953px;background:url(../../assets/img/xyncp-foot.png);}
  .case-right-mask{background:url(../../assets/img/bg-case-mask.png);width:724px;height:461px;float:right;color:#fff;position:relative;}
  .case-title{font-size: 34px;position:absolute;top:84px;left:179px;}
  .case-desc{font-size: 20px;position:absolute;top:183px;left:179px;width:480px;}
  .case-btn{width:123px;height:39px;border:1px solid #ffffff;text-align: center;line-height: 38px;font-size: 20px;position:absolute;left:179px;bottom:94px;color:#ffffff;cursor: pointer;}
</style>
<style>
  .shop-page1 .carousel-indicators{justify-content: normal!important;margin-left:32px!important;}
  .shop-page1 .carousel-indicators li{border-bottom:22px solid transparent!important;}
</style>
