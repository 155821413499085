<template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">门店在线商城小程序</div>
          <div class="d-b-info">配送APP主要针对配送员配送单的管理过程和配送历史的查看，4、单配送任务完成，等待用户进行收货验收，完成验收之后配送单费用自动结算。</div>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">小程序介绍</div>
      <div class="ya315-container">
        <div class="d-i-img"><img src="../../assets/img/img-zxsc.png" width="433" height="319" alt=""></div>
        <div class="d-i-description">
          <div class="d-i-name">门店在线小程序</div>
          <div class="d-i-info">门店在线小程序是汇集了品牌下所有门店的统一在线商城管理系统，用户首次进入的时候会指定品牌，完成品指定之后进入小程序品牌在线商城，会根据当前所属位置查看到品牌下所有门店的信息列表，点击相应的门店后进入具体门店小程序商城进行相关订单交易。</div>
        </div>
      </div>
    </div>
    <div class="advantage-page">
      <div class="d-i-title">小程序优势</div>
      <div class="advantage-box">
        <div>
          <img src="../../assets/img/icon-spncp-bjh.png" width="72" height="72" alt="">
          <p>便捷化</p>
        </div>
        <div>
          <img src="../../assets/img/icon-sjh.png" width="72" height="72" alt="">
          <p>数据化</p>
        </div>
        <div>
          <img src="../../assets/img/icon-gxx.png" width="72" height="72" alt="">
          <p>高效性</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{

    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-zxsc.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:120px;margin-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;margin-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.d-i-img{width:433px;height:319px;margin-left:54px;display: inline-block;}
.d-i-description{float:right;width:600px;}
.d-i-name{font-size: 30px;color:#333333;margin-top:12px;}
.d-i-info{color:#999999;font-size: 20px;margin-top:40px;}
.d-i-button{width:232px;height:54px;background:#2690FD;font-size: 22px;color:#FFFFFF;margin-top:32px;border-radius: 12px;display: block;line-height: 54px;text-align: center;cursor:pointer;}
.advantage-box{width:1125px;margin:0 auto;display: flex;justify-content: center;}
.advantage-box>div{width:33%;text-align: center;}
.advantage-box>div>p{margin-top:20px;color:#6B6B6B;font-size: 24px;margin-bottom:105px;}
.sys-page{height:1100px;background:#0A0A0A;}
.d-s-info{font-size: 20px;color:rgba(255,255,255,08);margin-top:-32px;text-align: center;}
.d-s-img{margin:64px auto 0;text-align: center;}
.dj-bottom{background:url(../../assets/img/bg-pro-foot-dj.png) no-repeat;background-size:100% 100%;height:324px;}
.d-btm-info{font-size: 20px;color:#999999;margin-top:-40px;text-align: center;}
</style>
