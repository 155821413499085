<template>
  <b-container>
    <header>用户解决方案</header>
    <section>
      <img src="../../assets/solution/navtree.jpg" alt="西安星云宇博数据科技有限公司">
    </section>
  </b-container>
</template>

<script>
export default {

}
</script>

<style scoped>
  .container{
    padding:3rem 15% 2rem;
  }
  .container>header{
    font-size: 24px;
    color: #333333;
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
  }
  .container>section{
    width: 100%;
  }
  .container>section>img{
    width:100%;
  }
</style>