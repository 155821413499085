<template>
  <div>
    <div class="swiper-box">
      <div class="banner1">
        <div style="margin-left: 300px;padding-top: 100px;">
          <div style="font-size: 30px;">物流配送APP</div>
          <div style="width: 600px;margin-top: 30px;line-height: 30px;">
            为完成门店自营人员配送的管理，门店针对线上配送订单可直接进行任务发起和管理
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div style="width: 100%">
        <p style="text-align: center;font-size: 24px;margin-top: 80px;">APP介绍</p>
        <div style="height: 60px;"></div>
        <div style="width: 100%;display: flex;">
          <div style="width: 50%;height: 300px;">
            <img width="433px" height="320px" src="../../assets/img/wlps.png" alt="">
          </div>
          <div style="width: 50%;height: 300px;">
            <p style="margin-top: 40px;font-size: 26px;">物流配送APP</p>
            <div style="width: 80%;font-size: 15px;color: #999;">
              为完成门店自营人员配送的管理，门店针对线上配送订单可直接进行任务发起和管理。届时，该APP同样发布至应用宝在线应用市场，
              用户通过搜索“溯源配送”进行APP的查找，直接下载安装即可。
            </div>
            <el-popover trigger="hover" placement="top">
              <p>111</p>
                <div slot="reference" style="width: 140px;height: 40px;text-align: center;line-height: 40px;
                color: white;background: #2690FD;border-radius: 4px;margin-top: 80px;">下载应用</div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;margin-top: 50px;">
      <div style="width: 100%;height: 400px;background: #F6F8FF;">
        <p style="text-align: center;font-size: 24px;padding-top: 80px;">APP优势</p>
        <div style="width: 1200px;height: 300px;margin: 0 auto;display: flex;align-items: center;justify-content: space-between;">
          <div style="text-align: center;width: 33%;">
            <img src="../../assets/img/xth.png" alt="">
            <span style="display: block;">系统化</span>
          </div>
          <div style="text-align: center;width: 33%;">
            <img src="../../assets/img/sjh.png" alt="">
            <span style="display: block;">数据化</span>
          </div>
          <div style="text-align: center;width: 33%;">
            <img src="../../assets/img/gxh.png" alt="">
            <span style="display: block;">高效化</span>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "three"
  }
</script>

<style scoped>
  .swiper-box{width: 1903px;color: white;}
  .banner1{
    width: 100%;height: 400px;background-image: url("../../assets/img/wlpsapp.png");
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
  }
</style>
