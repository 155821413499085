<template>
  <div class="login">
    <div class="box">
      <div style="height: 70px;"><img width="206" height="36" src="./assets/img/logo-header.png" alt="食安链家" /></div>
      <p>注册</p>
      <input v-model.trim="tell" type="text" placeholder="手机号" />
      <input v-model.trim="password" type="password" placeholder="密码" />
      <input v-model.trim="confirmPassword" type="password" placeholder="确认密码" />
      <div class="inp">
        <input v-model.trim="code" type="text" placeholder="输入验证码" />
        <button @click="getCode" style="width:90px;height: 40px;margin-top: 20px" type="button" :disabled="show?false:true" class="btn btn-secondary btn-sm">{{ show?'获取验证码':count + 's' }}</button>
      </div>
      <div style="margin-top: 20px;display: flex;justify-content: space-between" class="check">
        <div style="display: flex;">
          <button style="height: 40px;width: 240px;
          font-size: 16px;" type="button" @click="logReg" class="btn btn-primary btn-lg btn-block">注册</button>
          <!--          <input type="checkbox" style="margin-top: 4px" />-->
          <!--          <span style="display: block;margin-left: 5px">自动登录</span>-->
        </div>
        <span style="color: rgb(24,144,255);cursor: pointer;" @click="goLogin">使用已有账户登录</span>
      </div>
      <div style="height: 50px;display: flex;align-items: center;">
        <input v-model="bool" type="checkbox"><span style="font-size: 12px;margin-left: 5px;">
        <span>我已阅读并同意</span>
        <a style="color:#3496ed;" href="#">《食安链家用户服务协议》</a>
        <a style="color: #3496ed;">《食安链家隐私声明》</a>
        </span>
      </div>
      <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: rgb(85,85,87);margin-top: 50px;cursor: pointer;">
        <span>帮助</span>丨<span>隐私</span>丨<span>条款</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { JSEncrypt } from 'jsencrypt'
  export default {
    name: "login",
    data(){
      return{
        tell:"",//手机号
        password:"",//密码
        confirmPassword:"",//确认密码
        code:"",//验证码
        show:true,
        timer:null,
        count:"",
        bool:false,
      }
    },
    watch:{

    },
    methods:{
      //验证码倒计时
      setTim(){
        this.show = false
        const TIME_COUNT = 60
        if(!this.timer){
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(()=>{
            if(this.count>0&&this.count<=TIME_COUNT){
              this.count--
            }else{
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          },1000)
        }
      },
      //获取验证码
      getCode(){
        if(this.tell == ""){
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }else{
          let patt = new RegExp("^1[34578][0-9]{9}$", 'i');
          if(patt.test(this.tell)){
            this.http.phoneCode('?phone='+this.tell).then(res=>{
              if(res.code == "1"){
                this.$message({message: res.msg, type: 'success'});
                this.setTim()
              }else{
                this.$message({message: res.info, type: 'warning'});
              }
            })
          }else{
            this.$message({message: '请输入正确的手机号', type: 'warning'});
          }
        }
      },
      //使用已有账户登录
      goLogin(){
        this.user = ""//手机号
        this.password = ""//密码
        this.confirmPassword = ""//确认密码
        this.code = ""//验证码
        this.show = true
        clearInterval(this.timer)
        this.timer = null
        this.count = ""
        this.$emit("reg",false)
      },
      //注册
      logReg(){
        if(this.tell == ""){
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }else{
          let patt = new RegExp("^1[34578][0-9]{9}$", 'i');
          if(patt.test(this.tell)){
            if(this.password == ""){this.$message({message: '请输入密码', type: 'warning'});}
            else{if(this.confirmPassword == ""){this.$message({message: '请输入确认密码', type: 'warning'});}
            else{if(this.password != this.confirmPassword){this.$message({message: '两个密码不一致，请重新输入', type: 'warning'});}
            else{if(this.code == ""){this.$message({message: '请输入验证码', type: 'warning'});}
            else{if(this.bool == false){this.$message({message: '请确认协议以及声明', type: 'warning'});}
            else{
              //调用注册接口
              let data = {
                mobile: this.tell,//手机号
                pwd: this.password,//密码
                verificationCode: this.code//验证码
              }
              //将密码进行加密
              const encrypt = new JSEncrypt()
              encrypt.setPublicKey(
                'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
              )
              data.pwd = encrypt.encrypt(data.pwd)
              this.http.register(data).then(res=>{
                if(res.code == "1"){
                  this.goLogin()
                }else{
                  this.$message({message: res.info, type: 'warning'});
                }
              })
            }
            }
            }
            }
            }
          }else{
            this.$message({message: '请输入正确的手机号', type: 'warning'});
          }
        }
      }
    }
  }
</script>

<style scoped>
  .login{width:100%;height:100%;position:fixed;background-size:100% 100%;background-image: url("./assets/img/loginImg.jpg")}
  .box{width: 400px;height: 600px;margin: 200px auto auto auto;position: relative;}
  .box>div:nth-of-type(1)>img{margin: 0 auto;display: block;}
  .box>input{font-size: 14px;color:#333333;width: 400px;height: 40px;border-radius: 10px;margin-top: 20px;outline: none;border: 1px solid #bbb;padding-left: 10px;}
  .inp{height: 50px;display: flex;justify-content: space-between;}
  .inp>input{font-size: 14px;color:#333333;width: 260px;height: 40px;border-radius: 10px;margin-top: 20px;outline: none;border: 1px solid #bbb;padding-left: 10px;}
  .check{width: 400px;height: 50px;display: flex;justify-content: space-between;align-items: center;font-size: 13px;}
</style>





















