  <template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">禽蛋小程序交易平台</div>
          <div class="d-b-info">鸡蛋经销商通过平台在线入驻申请，平台审核通过后，与养鸡场在线建立采购关系，并进行鸡蛋的预定管理。 开通下游二级分销商城，分享自己的主页，让下游的终端顾客在线进行鸡蛋的采购，并在线配送。</div>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">平台介绍</div>
      <div class="ya315-container">
        <div class="d-i-img"><img src="../../assets/img/img-pro-qdxcx.png" width="433" height="319" alt=""></div>
        <div class="d-i-description">
          <div class="d-i-name">禽蛋小程序交易平台</div>
          <div class="d-i-info">鸡蛋经销商通过平台在线入驻申请，平台审核通过后，与养鸡场在线建立采购关系，并进行鸡蛋的预定管理。
            <br>开通下游二级分销商城，分享自己的主页，让下游的终端顾客在线进行鸡蛋的采购，并在线配送。</div>
          <a href="https://tf.yunan315.com/brand" target="_blank" class="d-i-button">体验小程序</a>
        </div>
      </div>
    </div>
    <div>
      <div class="d-i-title">交易管理核心流程</div>
      <div style="color:#52565B;font-size: 20px;text-align: center;">蛋鸡综合服务平台和生态圈，以提高鸡场效率为目标，提高鸡场盈利能力，带动服务转型升级，打造供应链平台</div>
      <div class="ya315-container">
        <img src="../../assets/img/img-qdxcx1.png" style="margin-top:32px;margin-bottom:60px;" alt="">
      </div>
    </div>
    <div class="advantage-page" style="padding-top:1px;background:#F6F8FF;">
      <div class="d-i-title">平台优势</div>
      <div class="advantage-box">
        <div>
          <img src="../../assets/img/icon-0cb.png" width="72" height="72" alt="">
          <p>0成本开店</p>
        </div>
        <div>
          <img src="../../assets/img/icon-dj-zyh.png" width="72" height="73" alt="">
          <p>优质货源</p>
        </div>
        <div>
          <img src="../../assets/img/icon-dbjy.png" width="72" height="72" alt="">
          <p>担保交易</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{

    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-qdxcx.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:90px;padding-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;padding-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.d-i-img{width:433px;height:319px;margin-left:54px;display: inline-block;}
.d-i-description{float:right;width:600px;}
.d-i-name{font-size: 30px;color:#333333;margin-top:12px;}
.d-i-info{color:#999999;font-size: 20px;margin-top:40px;}
.d-i-button{width:232px;height:54px;background:#2690FD;font-size: 22px;color:#FFFFFF;margin-top:32px;border-radius: 12px;display: block;line-height: 54px;text-align: center;cursor:pointer;}
.advantage-box{width:1125px;margin:0 auto;display: flex;justify-content: center;}
.advantage-box>div{width:33%;text-align: center;}
.advantage-box>div>p{margin-top:20px;color:#6B6B6B;font-size: 24px;margin-bottom:105px;}
.sys-page{height:1100px;background:#0A0A0A;}
.d-s-info{font-size: 20px;color:rgba(255,255,255,08);margin-top:-32px;text-align: center;}
.d-s-img{margin:64px auto 0;text-align: center;}
.dj-bottom{background:url(../../assets/img/bg-pro-foot-dj.png) no-repeat;background-size:100% 100%;height:324px;}
.d-btm-info{font-size: 20px;color:#999999;margin-top:-40px;text-align: center;}
</style>
