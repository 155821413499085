<template>
  <div class="box">
    <div class="loginCenter-header">
      <div class="loginCenter-header-center">
        <div class="logo" @click="toBack">
          <img src="../../assets/img/logo1.png" alt="食安链家" />
          <div class="title">登录中心</div>
        </div>
        <div class="goBack" @click="toBack">返回官网</div>
      </div>
    </div>
    <div class="search-group">
      <div class="search-tab">
        <div
          class="items"
          :class="info.type == 1 ? 'cur' : ''"
          @click="selectType(1)"
        >
          商品条码/放心码
        </div>
        <div
          class="items"
          :class="info.type == 2 ? 'cur' : ''"
          @click="selectType(2)"
        >
          追溯码查询
        </div>
        <!-- <div
          class="items"
          :class="info.type == 3 ? 'cur' : ''"
          @click="selectType(3)"
        >
          防伪序列号查询
        </div> -->
      </div>
      <div class="input-box">
        <input
          :placeholder="placeholderMessage"
          type="text"
          v-model="info.str"
          class="search-input"
        />
        <input
          placeholder="生产批次（选填）"
          type="text"
          v-if="info.type == 1"
          v-model="info.lot"
          class="search-input"
        />
        <div class="verify">
          <verify
            ref="verify"
            style="width: 318px; height: 43.22px"
            @success="(v) => (verify = v.verify)"
            @complete="isLoginError = false"
          />
          <div class="s-btn" @click="toSearch">查询</div>
        </div>
      </div>
    </div>
    <div class="desc">
      <div class="logo">
        <div class="logo1"></div>
        以下信息来源 中国物品编码中心
      </div>
      <div class="find-num">
        该商品已被查询
        <span class="num">{{ codeDetail.findNum || 0 }}</span>
        次
      </div>
    </div>
    <div class="details" v-if="!noData">
      <div class="title">
        {{ codeDetail.produceName || '' }}
      </div>
      <div class="detail-info">查询条码：{{ codeDetail.produceNo || '-' }}</div>
      <div class="content">
        <div class="menu">
          <div
            class="menu-item"
            :class="selectMenu === 1 ? 'selectMenu' : ''"
            @click="selectMenu = 1"
          >
            商品信息
          </div>
          <div
            class="menu-item"
            :class="selectMenu === 2 ? 'selectMenu' : ''"
            @click="selectMenu = 2"
          >
            企业信息
          </div>
          <div
            class="menu-item"
            :class="selectMenu === 3 ? 'selectMenu' : ''"
            @click="selectMenu = 3"
          >
            资质信息
          </div>
          <div
            class="menu-item"
            :class="selectMenu === 4 ? 'selectMenu' : ''"
            @click="selectMenu = 4"
          >
            质检数据
          </div>
          <div
            class="menu-item"
            :class="selectMenu === 5 ? 'selectMenu' : ''"
            @click="selectMenu = 5"
          >
            溯源信息
          </div>
        </div>
        <div class="info">
          <div class="good-form" v-show="selectMenu === 1">
            <div>
              <div class="label" style="font-size: 18px">商品图片：</div>
              <div class="content">
                <img
                  width="150"
                  style="margin-right: 10px"
                  height="auto"
                  v-for="(itm, idx) in imgList"
                  :src="itm.filename"
                  :key="idx"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div class="label">商品条码：</div>
              <div class="content">
                <!-- <img
                  :src="
                    'https://chinatrace.org/barcode/gensvg?type=ean13&msg=' +
                    codeDetail.produceNo +
                    '&fmt=png&hrsize=5pt&hrfont=song&qz=0.2cm&wf=1&mw=0.17mm&height=1cm'
                  "
                  alt=""
                /> -->
                <!-- <img id="barcodeContainer" /> -->
                <!-- <div ref="barcodeContainer" id="barcodeContainer"></div> -->
                <img id="barcode" />
              </div>
            </div>
            <div>
              <div class="label">产品名称：</div>
              <div class="content">{{ codeDetail.produceName }}</div>
            </div>
            <div>
              <div class="label">产品英文名称：</div>
              <div class="content"></div>
            </div>
            <div>
              <div class="label">UNSPSC分类：</div>
              <div class="content">{{ codeDetailName }}</div>
            </div>
            <div>
              <div class="label">品牌名称：</div>
              <div class="content">{{ codeDetail.brandName }}</div>
            </div>
            <div>
              <div class="label">规格型号：</div>
              <div class="content">{{ codeDetail.produceStyle }}</div>
            </div>
            <div>
              <div class="label">包装类型：</div>
              <div class="content">{{ packName }}</div>
            </div>
            <div>
              <div class="label">宽度：</div>
              <div class="content">{{ kuan + ' ' + danwei }}</div>
            </div>
            <div>
              <div class="label">高度：</div>
              <div class="content">{{ gao + ' ' + danwei }}</div>
            </div>
            <div>
              <div class="label">深度：</div>
              <div class="content">{{ shen + ' ' + danwei }}</div>
            </div>
            <div>
              <div class="label">原产国：</div>
              <div class="content"></div>
            </div>
            <div>
              <div class="label">装配国：</div>
              <div class="content"></div>
            </div>
            <div>
              <div class="label">产品系列：</div>
              <div class="content"></div>
            </div>
            <div>
              <div class="label">生产许可证号：</div>
              <div class="content"></div>
            </div>
          </div>
          <div class="good-form" v-show="selectMenu === 2">
            <div>
              <div class="label">企业名称：</div>
              <div class="content">
                {{ codeDetail.manufacturer }}
              </div>
            </div>
            <div>
              <div class="label">企业注册地址：</div>
              <div class="content">
                {{ codeDetail.placeOrigin }}
              </div>
            </div>
            <div>
              <div class="label">联系人：</div>
              <div class="content">
                {{ '' }}
              </div>
            </div>
            <div>
              <div class="label">联系电话：</div>
              <div class="content">
                {{ '' }}
              </div>
            </div>
            <div>
              <div class="label">企业资质：</div>
              <div class="content">
                {{ '' }}
              </div>
            </div>
          </div>
          <div class="good-form" v-show="selectMenu === 3">
            <!-- <div style="width: 100%" class="tableBox">
              <table>
                <thead>
                  <th>证件编号</th>
                  <th>发证单位</th>
                  <th>发证时间</th>
                  <th>有效时间</th>
                  <th>许可范围</th>
                </thead>
                <tbody></tbody>
              </table>
            </div> -->
            <template
              v-if="
                codeDetail.enterpriseQualificationList &&
                codeDetail.enterpriseQualificationList.length > 0
              "
            >
              <div
                v-for="(item, index) in codeDetail.enterpriseQualificationList"
                :key="index"
                style="display: flex; align-items: center"
              >
                <div class="label">
                  {{ filterLicence(item.enterpriseQualificationType) }}：
                </div>
                <div class="content" style="line-height: 14px">
                  <a
                    :href="
                      'https://suyuan.limafangxin.com/service/service-assure/' +
                      item.qualificationCertificateName
                    "
                    style="font-size: 14px; height: 100%; display: inline-block"
                    target="_blank"
                    class="look"
                  >
                    查看 >
                    <!-- <img
                      style="max-height: 200px"
                      :src="
                        'https://suyuan.limafangxin.com/service/service-assure/' +
                        item.qualificationCertificateName
                      "
                      alt=""
                    /> -->
                  </a>
                </div>
              </div>
            </template>
          </div>
          <div class="good-form" v-show="selectMenu === 4">
            <div style="width: 100%" class="tableBox">
              <table>
                <thead>
                  <th>检验报告证书</th>
                  <th>检验报告编号</th>
                  <th>产品名称</th>
                  <th>检验结果</th>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
          <div class="good-form" v-show="selectMenu === 5">
            <div>
              <div class="label">生产企业：</div>
              <div class="content">{{ codeDetail.manufacturer || '-' }}</div>
            </div>
            <div>
              <div class="label">生产日期：</div>
              <div class="content">
                {{
                  codeDetail.produceBatch
                    ? codeDetail.produceBatch.startTime
                    : '-'
                }}
              </div>
            </div>
            <div>
              <div class="label">生产批次号：</div>
              <div class="content">
                {{
                  codeDetail && codeDetail.produceBatch
                    ? codeDetail.produceBatch.batchNo
                    : '-'
                }}
              </div>
            </div>
            <div>
              <div class="label">产地：</div>
              <div class="content">{{ codeDetail.placeOrigin || '-' }}</div>
            </div>
            <div>
              <div class="label">追溯凭证：</div>
              <div
                class="content"
                v-if="
                  codeDetail &&
                  codeDetail.produceBatch &&
                  codeDetail.produceBatch.batchNo
                "
              >
                <a
                  :href="
                    'https://suyuan.limafangxin.com/service/service-assure/voucherPath/' +
                    codeDetail.produceTraceCode.retrospectVoucherPic
                  "
                  target="_blank"
                >
                  <img
                    style="width: 400px"
                    :src="
                      'https://suyuan.limafangxin.com/service/service-assure/voucherPath/' +
                      codeDetail.produceTraceCode.retrospectVoucherPic
                    "
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="content-title">追溯流通信息</div>
            <div class="content-title">暂无物流信息</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="noData" style="margin:30px; 0">
      <div style="text-align: center">
        <img src="../../assets/img/nodata1.png" alt="" />
      </div>
      未查询到相关数据，请重新输入
    </div>
  </div>
</template>

<script>
import licence from '@/assets/json/licence.json'
import gpc from '@/assets/json/gpc.json'
import pack from '@/assets/json/pack.json'

import axios from 'axios'
import Api from '@/utils/api.js'
import verify from './verify/index.vue'
import JsBarcode from 'jsbarcode'
export default {
  name: 'searchDetail',
  components: { verify },
  data() {
    return {
      codeDetail: {},
      // imgUrl,
      codeState: null,
      codeDetailName: null,
      packName: null,
      noData: true,
      imgList: [],
      otherData: {},
      kuan: '',
      gao: '',
      shen: '',
      danwei: '',
      placeholderMessage: '请输入商品条码/放心码',
      info: {
        type: 1,
        str: '',
        lot: '',
      },
      barcodeUrl: '',
      selectMenu: 1,
      gpc,
      pack,
      verify: null,
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.info = val.query
        console.log('this.info', this.info)
        if (this.info.type == 1) {
          this.placeholderMessage = '请输入商品条码/放心码'
        } else if (this.info.type == 2) {
          this.placeholderMessage = '请输入追溯码'
        } else {
          this.placeholderMessage = '请输入防伪序列号'
        }
        this.getInfo()
      },
      immediate: true,
    },
  },
  mounted() {},
  updated() {
    // this.getInfo()
    // 获取容器元素
  },
  methods: {
    filterLicence(type) {
      if (type) {
        let index = licence.findIndex((item, index) => {
          return item.id == type
        })
        if (index >= 0) {
          return licence[index].name
        }
      }
      return ''
    },
    renderBarcode() {
      this.$nextTick(() => {
        JsBarcode('#barcode', this.codeDetail.produceNo, {
          format: 'EAN13',
          lineColor: '#000',
          width: 2,
          height: 80,
          margin: 5,
          fontSize: 36, // 字体大小
          displayValue: true,
        })
      })
    },
    // 跳转查询
    toSearch() {
      const num = /^[0-9]*$/
      let message = ''
      if (this.info.type == 1) {
        message = '请输入商品条码'
      } else if (this.info.type == 2) {
        message = '请输入追溯条码'
      } else if (this.info.type == 3) {
        message = '请输入防伪序列号'
      }
      if (this.info.str === '') {
        this.$message({
          message: message,
          offset: '400',
        })
      } else if (!num.test(this.info.str)) {
        this.$message({
          message: '请输入数字',
          offset: '400',
        })
      } else {
        if (!this.verify) {
          this.$message({
            message: '请先完成验证',
            offset: '400',
          })
        } else {
          this.getInfo()
        }
      }
    },
    getInfo() {
      this.codeDetail = {}
      if (this.info.type == 1) {
        this.getProInfo()
      } else if (this.info.type == 2) {
        this.selectByTraceCodeAll()
      } else if (this.info.type == 3) {
        this.getSecurityCode(false)
      }
    },
    getSecurityCode(val) {
      //防伪系列号
      Api.getSecurityCode({
        code: this.info.str,
        codeType: val ? 1 : 3,
      }).then((res) => {
        console.log(93, res.data)
        if (res.data.code == 10000) {
          if (res.data.data.length > 0) {
            this.noData = false
            this.codeDetail = res.data.data[0]
            if (this.codeDetail.produceImgList)
              this.imgList = this.dataFormat(this.codeDetail.produceImgList)
            if (this.codeDetail.producePropertyList)
              this.otherFormat(this.codeDetail.producePropertyList)
            if (res.data.data[0].isDel == '0') {
              this.codeState = '该厂商识别代码处于正常状态。'
            } else {
              this.codeState =
                "该厂商识别代码已于'+ res.data.data.delTime + '注销，请关注产品生产日期。"
            }
            var name = ''
            this.gpc.forEach(function (item, index) {
              if (item.Code === res.data.data[0].mem) {
                name = item.Description
              }
            })
            this.codeDetailName = name
            var packName = ''
            console.log(116, data)
            this.pack.forEach(function (item, index) {
              if (item.value === res.data.data[0].producePack) {
                packName = item.name
              }
            })
            this.packName = packName
          } else {
            this.noData = true
          }
        } else {
          this.noData = true
          if (null == res.data.description) {
            this.$message.error('暂无相关信息')
          } else if (res.data.description == 'map_bg.png') {
            this.$message.error('该商品条码已注销')
          } else if (res.data.description == '3') {
            this.$message.error('该商品条码违法使用')
          } else if (res.data.description == 'map_kf.png') {
            this.$message.error('该商品条码未按规定通报')
          } else if (res.data.description == '5') {
            this.$message.error('该商品条码被冒用')
          } else if (res.data.description == '6') {
            this.$message.error('暂无相关信息')
          }
        }
      })
    },
    getProInfo() {
      console.log('this.info.str===============', this.info.str)
      //商品查询码
      Api.selectOne({
        produceNo: this.info.str,
        batchNo: this.info.lot || undefined,
      }).then((res) => {
        if (res.code == 10000) {
          if (res.data.length > 0) {
            this.codeDetail = res.data[0]
            this.noData = false
            this.renderBarcode()
            if (this.codeDetail.produceImgList)
              this.imgList = this.dataFormat(this.codeDetail.produceImgList)
            if (this.codeDetail.producePropertyList)
              this.otherFormat(this.codeDetail.producePropertyList)
            if (res.data[0].isDel == '0') {
              this.codeState = '该厂商识别代码处于正常状态。'
            } else {
              this.codeState =
                "该厂商识别代码已于'+ res.data.data.delTime + '注销，请关注产品生产日期。"
            }
            var name = ''
            this.gpc.forEach(function (item, index) {
              if (item.Code === res.data[0].mem) {
                name = item.Description
              }
            })
            this.codeDetailName = name
            var packName = ''
            this.pack.forEach(function (item, index) {
              if (item.value === res.data[0].producePack) {
                packName = item.name
              }
            })
            this.packName = packName
          } else {
            this.noData = true
          }
        } else {
          this.noData = true
          if (null == res.description) {
            this.$message.error('暂无相关信息')
          } else if (res.description == 'map_bg.png') {
            this.$message.error('该商品条码已注销')
          } else if (res.description == '3') {
            this.$message.error('该商品条码违法使用')
          } else if (res.description == 'map_kf.png') {
            this.$message.error('该商品条码未按规定通报')
          } else if (res.description == '5') {
            this.$message.error('该商品条码被冒用')
          } else if (res.description == '6') {
            this.$message.error('暂无相关信息')
          }
        }
      })
    },
    selectByTraceCodeAll() {
      // 搜索追溯条码
      Api.selectByTraceCodeAll({
        traceCode: this.info.str,
      }).then((res) => {
        console.log(res.data)
        if (res.data.code == 10000) {
          if (res.data.data) {
            this.traceDetail = res.data.data
            this.renderBarcode()
            this.noData = false
            if (res.data.data.isDel == '0') {
              this.traceDetail.codeState = '该厂商识别代码处于正常状态。'
            } else {
              this.traceDetail.codeState =
                "该厂商识别代码已于'+ res.data.data.delTime + '注销，请关注产品生产日期。"
            }
            var name = ''
            // axios.get('/gpc.json').then((data) => {
            //   data = data.data
            this.gpc.forEach(function (item) {
              if (item.Code === res.data.data.produce.mem) {
                name = item.Description
              }
              // })
              this.traceDetail.produce.mem = name
            })
          } else {
            this.noData = true
          }
          this.traceDialogTableVisible = true
        } else {
          this.noData = true
        }
      })
    },
    selectType(type) {
      this.info.type = type
      this.info.str = ''
      this.info.lot = ''
      if (type == 1) {
        this.placeholderMessage = '请输入商品条码'
      } else if (type == 2) {
        this.placeholderMessage = '请输入追溯条码'
      } else {
        this.placeholderMessage = '请输入防伪序列号'
      }
    },
    // getInfo() {
    //   let sn = this.$route.query.sn
    //   //商品查询码
    //   this.Api.selectOne({
    //     produceNo: sn,
    //   }).then((res) => {
    //     console.log(93, res.data)
    //     if (res.data.code == 10000) {
    //       if (res.data.data.length > 0) {
    //         this.codeDetail = res.data.data[0]
    //         this.noData = false

    //         if (this.codeDetail.produceImgList)
    //           this.imgList = this.dataFormat(this.codeDetail.produceImgList)
    //         if (this.codeDetail.producePropertyList)
    //           this.otherFormat(this.codeDetail.producePropertyList)
    //         if (res.data.data[0].isDel == '0') {
    //           this.codeState = '该厂商识别代码处于正常状态。'
    //         } else {
    //           this.codeState =
    //             "该厂商识别代码已于'+ res.data.data.delTime + '注销，请关注产品生产日期。"
    //         }
    //         var name = ''
    //         axios.get('/gpc.json').then((data) => {
    //           var data = data.data
    //           data.forEach(function (item, index) {
    //             if (item.Code === res.data.data[0].mem) {
    //               name = item.Description
    //             }
    //           })
    //           this.codeDetailName = name
    //         })
    //         var packName = ''
    //         axios.get('/pack.json').then((data) => {
    //           var data = data.data
    //           console.log(116, data)
    //           data.forEach(function (item, index) {
    //             if (item.value === res.data.data[0].producePack) {
    //               packName = item.name
    //             }
    //           })
    //           this.packName = packName
    //         })
    //       } else {
    //         this.noData = true
    //       }
    //     } else {
    //       if (null == res.data.description) {
    //         this.$message.error('暂无相关信息')
    //       } else if (res.data.description == 'map_bg.png') {
    //         this.$message.error('该商品条码已注销')
    //       } else if (res.data.description == '3') {
    //         this.$message.error('该商品条码违法使用')
    //       } else if (res.data.description == 'map_kf.png') {
    //         this.$message.error('该商品条码未按规定通报')
    //       } else if (res.data.description == '5') {
    //         this.$message.error('该商品条码被冒用')
    //       } else if (res.data.description == '6') {
    //         this.$message.error('暂无相关信息')
    //       }
    //     }
    //   })
    // },
    dataFormat(list) {
      let fl = JSON.parse(JSON.stringify(list))
      for (let i = 0; i < fl.length; i++) {
        let url = fl[i].filename.replace('www.anccnet.com', 'www.gds.org.cn')
        fl[i].filename = url
      }
      return fl
    },
    toBack() {
      this.$router.push('/')
    },
    otherFormat(list) {
      let fl = JSON.parse(JSON.stringify(list))
      for (let i = 0; i < fl.length; i++) {
        switch (fl[i].proName) {
          case '宽':
            this.kuan = fl[i].proVal
            break
          case '高':
            this.gao = fl[i].proVal
            break
          case '深':
            this.shen = fl[i].proVal
            break
          case '单位':
            this.danwei = fl[i].proVal
            break
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.loginCenter-header {
  width: 100%;
  min-width: 1300px;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  height: 72px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  padding: 0 60px;
  z-index: 999;
  // display: flex;
  // justify-content: ;
  &-center {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      height: 72px;
      cursor: pointer;
      .title {
        display: inline-block;
        margin-left: 50px;
      }
    }
    .goBack {
      color: #fff;
      background-color: #0366f1;
      text-align: center;
      line-height: 42px;
      width: 127px;
      height: 42px;
      border-radius: 2px;
      cursor: pointer;
    }
    .goBack:hover {
      background-color: rgba(3, 102, 241, 0.7);
    }
  }
}
.box {
  width: 100%;
  padding-top: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search-inpu {
}
.search-group {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  align-items: center;
  flex-direction: column;
}
.search-group .search-tab {
  width: 556px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #dde2ee;
  margin-bottom: 18px;
}
.search-group .search-tab .items {
  flex: 1;
  height: 62px;
  line-height: 62px;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;
  color: #707070;
  cursor: pointer;
}
.search-group .search-tab .cur {
  color: #265bec;
  font-weight: 600;
  border-bottom-color: #265bec;
}
.input-box input {
  display: flex;
  flex-wrap: wrap;
  width: 556px;
  height: 44px;
  border: 0;
  outline: 2px solid #dde2ee;
  text-indent: 14px;
  line-height: 44px;
  font-size: 18px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
/deep/.search-input:focus-visible {
  outline: 2px solid#265bec !important;
}
.input-box .verify {
  width: 558px;
  margin-top: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* // margin-left: ; */
  .s-btn {
    font-family: 微软雅黑;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 130px;
    height: 44px;
    background-image: linear-gradient(90deg, #0d74ff, #4760ff);
    text-align: center;
    font-size: 18px;
    color: #fff;
    line-height: 44px;
  }
}
.desc {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  background: rgba(38, 91, 236, 0.04);
  justify-content: space-between;
  border: 1px solid #dde2ee;
  align-items: center;
  padding: 0 33px;
  height: 72px;
  margin-top: 30px;
  box-sizing: border-box;
  .logo {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 18px;
    color: #333;
  }
  .logo1 {
    float: left;
    width: 44px;
    height: 44px;
    background: url(../../assets/img/searchDetail/logo-zgsp.png);
    background-size: contain;
    margin-right: 20px;
  }
  .find-num {
    // position: absolute;
    // right: 0;
    font-size: 18px;
    color: #333;
    // top: 10px;
    .num {
      color: rgb(38, 91, 236);
      display: inline-block;
      margin: 0 5px;
    }
  }
}
.details > .content {
  width: 100%;
  display: flex;
  margin-top: 16px;
  .menu {
    width: 170px;
    display: flex;
    flex-direction: column;
    .menu-item {
      width: 100%;
      height: 44px;
      line-height: 44px;
      cursor: pointer;
      border-left: 4px solid #fff;
      text-align: center;
      color: #666;
      font-size: 18px;
    }
  }
  .selectMenu {
    border-left-color: #265bec !important;
    background: rgba(38, 91, 236, 0.05);
  }
  .info {
    border: 1px solid #eaedf5;
    flex: 1;
    padding-top: 31px;
  }
}

.logo:after {
  clear: both;
  content: '';
  display: table;
}

.details {
  width: 1200px;
  margin: 40px 55px;
}
.details .title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 36px;
  position: relative;
  color: #265bec;
}
.good-form {
  margin-bottom: 120px;
}
.good-form > div:after {
  clear: both;
  content: '';
  display: table;
}
.good-form > div {
  margin-bottom: 24px;
  display: flex;
}
.good-form > div > div {
  float: left;
}
.good-form .label {
  width: 160px;
  text-align: right;
  display: inline-block;
  margin-right: 30px;
  color: #000;
  line-height: 12px;
  font-size: 14px;
}
.good-form .content {
  display: inline-block;
  line-height: 14px;
}

.find-num > span {
  color: #333;
}
.content-title {
  margin-left: 80px;
  font-size: 18px;
}
.tableBox {
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      width: 100%;
      th {
        height: 52px;
        background: #f9f9f9;
        border: none;
      }
    }
    td {
      height: 80px;
      border: none;
    }
  }
}
.look {
  color: #000;
}
.look:hover {
  color: #0d74ff;
}
</style>
