<template>
  <div>
    <shopTop></shopTop>
    <div v-show="goDp" class="shop-list">
      <div class="ya315-container">
        <div class="list-nav-brand">
          <router-link :to="{path:'/shop/list'}">全部分类</router-link>
          <template v-if="pathName.length<=1">
             >
            <a>{{data.sonCateName}}</a>
          </template>
        </div>
        <div class="shop-list-content">
          <div class="pro-list">
            <div class="pro-basic">
              <div class="pro-img">
<!--                v-if="100024"-->
                <img width="300" height="300" :src="img" alt="">
                <div style="display: flex;margin-top: 20px;">
                  <div @click="currentTool(item,index)" style="width: 60px;height: 60px;border: 1px solid white;line-height: 53px;cursor: pointer;" :class="current==index?'current':''" v-for="(item,index) in arr" :key="index">
                    <img width="54" height="54" :src="item" alt="">
                  </div>
                </div>
              </div>
              <div class="pro-basic-detail">
                <div class="name">{{data.goodsTitle}}</div>
                <div class="desc">{{data.proDesc}}</div>
                <div class="common">
                  <div class="label" style="line-height:39px;">价格</div>
                  <div class="price" v-show="active==index" v-for="(item,index) in data.goodsSpecs" :key="index">￥{{ item.price/100 | price }}</div>
                </div>
                <div class="side-line"></div>
                <div class="common">
                  <div class="label">规格</div>
                  <div style="cursor: pointer" v-for="(item,index) in data.goodsSpecs" :key="index" class="sel-list">
                    <div @click="active=index" :class="active==index?'cur':''">{{ item.specName }}</div>
                  </div>
                </div>
                <div class="common">
                  <div class="label">日期单位</div>
                  <div v-for="(item,index) in data.goodsSpecs" :key="index" class="sel-list">
                    <div v-show="active==index" class="cur">
                      <span v-show="item.dateUnit == 1">天</span>
                      <span v-show="item.dateUnit == 2">月</span>
                      <span v-show="item.dateUnit == 3">年</span>
                      <span v-show="item.dateUnit == 4">长期</span>
                    </div>
                  </div>
                </div>
                <div class="common">
                  <div class="label">数量</div>
                  <div>
                    <el-input-number size="small" v-model="num"></el-input-number>
                  </div>
                </div>
                <div class="btn-group">
                  <div>立即购买</div>
                  <div @click="addcart">加入购物车</div>
                  <div>咨询</div>
                </div>
              </div>
            </div>
            <div class="pro-detail">
              <div class="pro-nav">
                <div @click="too=0" :class="too==0?'cur':''">产品详情</div>
                <div @click="too=1" :class="too==1?'cur':''">使用教程</div>
                <div @click="too=2" :class="too==2?'cur':''">售后服务</div>
              </div>
              <div class="content">
                <div class="detail" v-show="too==0" v-html="data.goodsDetail"></div>
                <div v-show="too==1">
                  <div>
                    <span>111</span>
                    <a :href="data.guide">下载</a>
                  </div>
                </div>
                <div v-show="too==2">售后服务</div>
              </div>
            </div>
          </div>
          <div class="pro-recommend">
            <div class="pro-recommend-title">{{ data.storeName }}</div>
            <div class="pro-recommend-p">资质信息： 查看</div>
            <div class="pro-recommend-p">客服电话： {{ data.phone }}</div>
            <div class="pro-recommend-p">客服邮箱： {{ data.mailbox }}</div>
            <div class="pro-recommend-p">在线时间： 工作日 {{ JSON.parse(data.serviceTime)[0] }}-{{ JSON.parse(data.serviceTime)[1] }}</div>
            <div class="pro-recommend-btn" style="cursor: pointer;" @click="godianpu">进入店铺</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!goDp" class="shop-list">
      <div class="ya315-container">
        <div style="width: 1200px;height: 300px;">
          <img style="margin-top: 20px;" width="1200" height="300" :src="storeData.storeBanner" alt="">
        </div>
        <div class="list-nav-brand">
          <div class="header">
            <div><img width="300" :src="storeData.storeLogo" alt=""></div>
            <div>
              <div style="font-size: 18px;height: 40px;">{{ storeData.storeName }}</div>
              <div style="display: flex;justify-content: space-between;width: 450px;align-items: center;">
                <div style="font-size: 14px;height: 40px;"><span>客服电话:</span><span>{{ storeData.phone }}</span></div>
                <div style="font-size: 14px;height: 40px;"><span>电子邮件:</span><span>{{ storeData.mailbox }}</span></div>
              </div>
              <div style="font-size: 14px;height: 40px;"><span>服务时间:</span><span>周一至周五  08:30~18:00</span></div>
            </div>
          </div>
          <div class="title">
            <el-tabs v-model="activeName" style="background: white;padding: 0 25px;">
              <el-tab-pane label="全部商品" name="0"/>
              <el-tab-pane label="企业介绍" name="1"/>
            </el-tabs>
          </div>
          <div class="allShop" v-show="activeName == 0">
            <div class="priceSort">
              <div :class="activeIndex == 0?'active':''">默认排序</div>
              <div>上架时间<i class="el-icon-d-caret"></i></div>
              <div>价格<i class="el-icon-d-caret"></i></div>
            </div>
            <div class="shopList">
              <div v-for="(item,index) in shopList" :key="index">
                <div style="display: flex;margin-top: 20px;">
                  <div style="width: 80px;height: 80px;margin-left: 20px;">
                    <img width="80px" height="80px" :src="JSON.parse(item.goodsImgs)?JSON.parse(item.goodsImgs)[0]:''" alt="">
                  </div>
                  <div style="margin-left: 20px;">
                    <p style="font-weight:bold;">{{ item.goodsTitle }}</p>
                  </div>
                </div>
                <div>
                    <p style="font-size: 14px;padding: 20px;">{{ item.introduce }}</p>
                    <p style="font-size: 16px;padding: 10px 20px;font-weight: bold;">{{ item.storeName }}</p>
                </div>
                <div style="display: flex;">
                  <div style="font-size: 24px;padding: 40px 20px;font-weight: bold;">￥{{ item.price/100 | price }}</div>
                  <div style="margin: 45px 0 0 80px;cursor: pointer;font-size: 15px;">
                    <el-button  @click="goCart(item)" size="small" type="primary">加入购物车</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;height: 320px;background: white;" v-show="activeName == 1">
            <div style="width: 90%;height: 170px;margin: auto;padding-top: 20px;line-height: 30px;color: #52565b;">
              平台整合供应商、物流商、品牌商和终端生鲜门店、餐饮门店和养殖场资源，以自有商品、B2B支付、
              终端交易和C端聚合支付为支撑、以品牌商和终端业务流通和面向下游销售管理为基础，通过资源整合，打造统一供应平台，
              对终端客户提供商品供应和营销服务，并为食品农产品的安全追溯打下坚实基础。
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <shopFoot></shopFoot>-->
  </div>
</template>

<script>
  import shopTop from '@/components/Shop/Top.vue'
  import shopFoot from '@/components/Shop/Foot.vue'
  // const PROLIST = require('../../../public/data/proList.json');
  export default {
    name: "list",
    data(){
      return {
        img:"",
        pathName:[],
        data:{},
        // proList:PROLIST,
        goDp:true,
        activeName:0,
        activeIndex:0,
        shopList:[],
        list:JSON.parse(localStorage.getItem("cartList"))?JSON.parse(localStorage.getItem("cartList")):[],
        active:0,
        current:0,
        arr:[],
        too:0,
        num:1,
        storeData:{},
        shopData:[],
      }
    },
    components:{ shopTop, shopFoot },
    mounted() {
      this.init()
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    methods:{
      before(){},
      init(){
        this.http.getGoodsOne('?id=' + this.$route.query.id).then(res=>{
            console.log(res.data)
          this.data = res.data
          let arr = JSON.parse(res.data.goodsImgs)
          this.img = arr[0]
          arr.forEach((item,index)=>{
            this.arr.push(item)
          })
          let str = this.imageUrl + JSON.parse(this.data.guide)[0].path
          this.data.guide = str
        })
      },
      goCart(item){
        this.arr = []
        this.data = {}
        this.data.guide = ""
        this.$router.push({
          path:'/shop/detail',
          query:{id:item.id,pathName:this.$route.query.pathName}
        })
        this.init()
        this.goDp = true
      },
      currentTool(item,index){
        this.img = item
        this.current = index
      },
      addcart(){
        let obj = {
          goodsId:this.data.id,
          specId:this.data.goodsSpecs[this.active].id,
          quantity:this.num
        }
        this.http.addCart(obj).then(res=>{
          if(res.code == "1"){
            this.$message({message:"加入购物车成功",type:"success"})
            this.num = 1
          }else{
            localStorage.setItem("fullPath",this.$route.fullPath)
            this.$router.push({
              path:"/login"
            })
          }
        })
      },
      godianpu(){
        this.http.currentStore(`?id=${this.data.onlineId}`).then(res=>{
          if(res.code == "1"){
            console.log(res.data)
            this.storeData = res.data
            this.storeData.storeBanner = res.data.storeBanner
            this.storeData.storeLogo = res.data.storeLogo
            this.http.getGoodsList('?onlineInfoId='+res.data.id).then(msg=>{
                if(msg.code == "1"){
                  console.log(msg.data.data)
                  this.shopList = msg.data
                  this.goDp = false
                  this.shopList.forEach((item,index)=>{
                    item.logo = this.imageUrl + item.logo
                  })
                }
            })
            // getGoodsList
          }
        })
        // console.log(this.data.onlineId)
      },
    }
  }
</script>

<style scoped>
  .shop-list{background:#F5F7FD;box-shadow:inset 0px 15px 10px -15px rgba(38, 144, 253,.1);}
  .list-nav-brand{padding-top:36px;margin-left:8px;font-size: 18px;margin-bottom:30px;color:#52565B;}
  .list-nav-brand>a{margin:0 16px;color:#52565B;}
  .shop-list-content{display: flex;justify-content: space-between;}
  .pro-list{width:944px;}
  .pro-basic{height:551px;background:#ffffff;display: flex;margin-bottom:36px;}
  .pro-img{width:400px;height:400px;margin-left:30px;margin-top:24px;text-align: center;vertical-align: middle;}
  .pro-basic-detail{margin-top:30px;margin-left:32px;}
  .pro-basic-detail .name{color:#121A25;font-size: 20px;}
  .pro-basic-detail .desc{color:#858A90;font-size: 16px;margin-top:12px;}
  .pro-basic-detail .common{font-size: 16px;color:#858A90;margin-top:20px;display: flex;}
  .common>.label{width:90px;}
  .common>.price{color:#FE5E54;font-size: 26px;}
  .side-line{width:800px;border-top:1px solid #E8E9EB;margin-top:30px;}
  .sel-list{display: flex;}
  .sel-list>div{margin-right:16px;width:68px;height:36px;border:1px solid #E8E9EB;color:#52565B;line-height: 36px;text-align: center;}
  .sel-list>div.cur{color:#1890FF;border-color:#1890FF;}
  .btn-group{margin-top:39px;}
  .btn-group>div{width:114px;height:40px;border-radius: 20px;text-align: center;border:1px solid #1890FF;background:#1890FF;color:#ffffff;margin-right:27px;line-height: 40px;box-sizing: content-box;}
  .btn-group>div:last-child{color:#1890FF;background:#ffffff;}

  .pro-detail{background:#ffffff;margin-bottom:150px;}
  .pro-nav{height:65px;display: flex;line-height: 65px;border-bottom:1px solid #E8E9EB;}
  .pro-nav>div{width:136px;text-align: center;color:#52565B;font-size: 16px;}
  .pro-nav>div.cur{color:#1890FF;}
  .pro-detail .content{padding:70px 60px;color:#52565B;font-size: 16px;text-align: center;}
  .content h3{text-align: center;}

  .pro-recommend{width:230px;background:#ffffff;height:301px;}
  .pro-recommend-title{height:70px;line-height: 70px;color:#858A90;font-size: 16px;border-bottom:1px solid #E8E9EB;width:200px;margin: 0 auto;}
  .pro-recommend-p{color:#858A90;font-size: 12px;margin:22px 10px 0 16px;text-align: left;}
  .pro-recommend-btn{margin:22px 16px 0;line-height: 50px;border-top:1px solid #E8E9EB;font-size: 12px;color:#4983FF;text-align: center;}
  .header{
    width: 100%;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
  }
  .title{
    width: 100%;
    margin-top: 20px;
  }
  .allShop{
    width: 100%;
    padding: 20px;
    background: white;
    margin-top: 20px;
  }
  .priceSort{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .priceSort>div{
    width: 120px;
    height: 50px;
    text-align: center;
    font-size: 15px;
    line-height: 50px;
    cursor: pointer;
  }
  .active{
    color: #2690FD;
  }
  .shopList{
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .shopList>div{
    width: 350px;
    border: 1px solid #e5e5e5;
    margin-top: 40px;
    margin-left: 20px;
  }
  .current{
    border: 1px solid #00BAFF !important;
  }
  .detail img{
    width: 800px !important;
    height: 800px !important;
  }
</style>
