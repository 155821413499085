<template>
  <div id="scrollPage">
    <shopTop></shopTop>
    <div class="banner" :class="type">
      <div class="mask">
        <div class="ya315-container">
          <div class="h1">成为云市场合作伙伴</div>
          <div class="h2">打造共生共赢的数字生态体系，助力企业数字化转型升级！</div>
        </div>
      </div>
    </div>
    <div :class="isFixed?'fd':''" class="nav">
      <div class="ya315-container">
        <div @click="anchor(0)" :class="anchorNum==0?'cur':''">合作优势</div>
        <div @click="anchor(1)" :class="anchorNum==1?'cur':''">合作体系</div>
        <div @click="anchor(2)" :class="anchorNum==2?'cur':''">重点计划</div>
        <div @click="anchor(3)" :class="anchorNum==2?'cur':''">伙伴展示</div>
      </div>
    </div>
    <div>
      <div id="page1" class="common-title">合作优势</div>
      <div class="height42"></div>
      <div class="ya315-container flex-box">
        <div class="cooperate-item">
          <img src="../../assets/shop/img-cooperate1.png" width="48" height="48" alt="">
          <div class="h2">领先的技术能力</div>
          <div class="p">依托于华为云分布式架构方式，将食安链家各平台分别管理，对外输出，赋能管理服务云平台。</div>
        </div>
        <div class="cooperate-item">
          <img src="../../assets/shop/img-cooperate2.png" width="48" height="48" alt="">
          <div class="h2">开放生态</div>
          <div class="p">提供集成与被集成的开放生态合作模式，与伙伴优势互补、联合创新，共享商机、共生共赢。</div>
        </div>
        <div class="cooperate-item">
          <img src="../../assets/shop/img-cooperate3.png" width="48" height="48" alt="">
          <div class="h2">丰富市场资源</div>
          <div class="p">联合政府食品安全监管部门，对本地企业进行食品安全管理，并助力合作企业拓展市场</div>
        </div>
        <div class="cooperate-item">
          <img src="../../assets/shop/img-cooperate4.png" width="48" height="48" alt="">
          <div class="h2">销售赋能</div>
          <div class="p">提供专属技术咨询及售前、方案、交付等支持，为合作伙伴重要项目保驾护航。</div>
        </div>
      </div>
      <div style="background:#F6F8FF;">
        <div class="height32"></div>
        <div id="page2" class="common-title">合作体系</div>
        <div class="height32"></div>
        <div class="nav2">
          <div :class="nav2Current==0?'cur':''" @click="nav2Current = 0">供应商</div>
          <div :class="nav2Current==1?'cur':''" @click="nav2Current = 1">采购商</div>
          <div :class="nav2Current==2?'cur':''" @click="nav2Current = 2">服务商</div>
          <div :class="nav2Current==3?'cur':''" @click="nav2Current = 3">技术合作商</div>
        </div>
        <div class="height32"></div>
        <div class="cooperate-sys ya315-container flex-box">
          <div>
            <div class="height56"></div>
            <img src="../../assets/shop/img-cooperate-1.png" width="60" height="60" alt="">
            <div class="h2">农产品供应商</div>
            <div class="p">初级农产品和加工农产品，包含种植业、畜牧业、渔业产品，如谷物、油脂、农业原料、畜禽等。二次加工如消毒奶、分割肉、冷冻肉、食用油、饲料等。</div>
            <div class="btn flex-box">
              <a>立即加入</a>
              <a>查看详情</a>
            </div>
          </div>
          <div>
            <div class="height56"></div>
            <img src="../../assets/shop/img-cooperate-2.png" width="60" height="60" alt="">
            <div class="h2">食品供应商</div>
            <div class="p">初级农产品和加工农产品，包含种植业、畜牧业、渔业产品，如谷物、油脂、农业原料、畜禽等。二次加工如消毒奶、分割肉、冷冻肉、食用油、饲料等。</div>
            <div class="btn flex-box">
              <a>立即加入</a>
              <a>查看详情</a>
            </div>
          </div>
          <div>
            <div class="height56"></div>
            <img src="../../assets/shop/img-cooperate-3.png" width="60" height="60" alt="">
            <div class="h2">硬件供应商</div>
            <div class="p">SAAS系统场景中所涉及的收银机、扫码枪、扫码器、钱箱及打印机等</div>
            <div class="btn flex-box">
              <a>立即加入</a>
              <a>查看详情</a>
            </div>
          </div>
        </div>
        <div class="height82"></div>
      </div>

      <div id="page3" class="common-title">重点计划</div>
      <div class="height82"></div>
      <div class="important ya315-container">
        <div class="h2">县域市场品牌（超市、餐饮）招商服务</div>
        <div class="p">为超市、餐饮品牌企业更好拓展县域蓝海市场，并响应商务部会议精神以县域商业体升级为基础，结合超市、餐饮品牌企业的业务需求</div>
        <img src="../../assets/shop/img-important.png" width="428" height="280" alt="">
      </div>
      <div class="height56"></div>
      <div class="ya315-container flex-box important2">
        <div>
          <div class="height56"></div>
          <div class="h2">商务类服务</div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>河南省食品安全溯源平台服务</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>食安政府授牌认证</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>溯源平台优质供应商及产品名录、价格对接</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>物流、分拣服务</div>
          </div>
          <div class="btn">查看详情</div>
        </div>
        <div>
          <div class="height56"></div>
          <div class="h2">资源类服务</div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>河南省食品安全溯源平台服务</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>食安政府授牌认证</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>溯源平台优质供应商及产品名录、价格对接</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>物流、分拣服务</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>冷藏、常温仓储服务</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>产品品控及品质问题追责</div>
          </div>
          <div class="btn">查看详情</div>
        </div>
        <div>
          <div class="height56"></div>
          <div class="h2">软、硬件类服务</div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>区域县域市场情况调研报告</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>针对性市场推广计划书</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>超市、餐饮品牌企业专题推介会</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>终端加盟店面收银分账系统</div>
          </div>
          <div class="item">
            <img src="../../assets/shop/icon-true.png" alt="">
            <div>国内一线品牌收银设备及管理工具</div>
          </div>
          <div class="btn">查看详情</div>
        </div>
      </div>

      <div id="page4" class="common-title">伙伴展示</div>
      <div class="height82"></div>
      <div class="ya315-container flex-box last">
        <img src="../../assets/shop/img-biaoyuan.png" alt="">
        <img src="../../assets/shop/img-tofa.png" alt="">
        <img src="../../assets/shop/img-shitou.png" alt="">
        <img src="../../assets/shop/img-zhongji.png" alt="">
        <img src="../../assets/shop/img-icbc.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
  import shopTop from '@/components/Shop/Top.vue'
  export default {
    name: "server",
    components:{ shopTop},
    data(){
      return {
        anchorNum:0,
        nav2Current:0,
      }
    },
    methods:{
      anchor(n){
        this.anchorNum = n;
        let top = 0
        if(n==0){
          top = $('#page1').offset().top-72-188
        }else if(n==1){
          top = $('#page2').offset().top-72-188
        }else if(n==2){
          top = $('#page3').offset().top-72-188
        }else{
          top = $('#page4').offset().top-72-188
        }
        $('html').scrollTop(top)
      }
    }
  }
</script>

<style scoped>
  .height42{height:42px;}
  .height32{height:32px;}
  .height82{height:82px;}
  .height56{height:56px;}
  .banner{width:100%;height:300px;top:60px;background-image:url(../../assets/shop/banner-server.png);background-size: 100% 100%;}
  .mask{width:100%;height:100%;background:rgba(3, 36, 70, 0.48);}
  .mask>div{position:relative;height:300px;}
  .banner .h1{color:#ffffff;font-size: 40px;text-align: center;width:100%;position:absolute;top:82px;}
  .banner .h2{color:#E3E3E3;font-size: 20px;text-align: center;position:absolute;top:175px;left:50%;margin-left:-410px;width:820px;}
  .nav{height:54px;border-bottom:1px solid #E8E9EB;width:100%;background:#fff;overflow: hidden;}
  .nav.fd{position:fixed;top:60px;z-index: 10;}
  .nav>div{display: flex;justify-content: center;}
  .nav>div>div{width:64px;margin:0 102px;height:54px;border-bottom:2px solid transparent;line-height: 54px;font-size: 16px;color:#52565B;text-align: center;cursor: pointer;}
  .nav>div>div.cur{color:#1890FF;border-bottom-color: #1890FF;}
  .common-title{color:#2690FD;font-size: 30px;margin-top:72px;text-align: center;font-weight: 700;}
  .flex-box{flex-wrap:wrap;}
  .cooperate-item{height:188px;width:600px;position:relative;}
  .cooperate-item>div,.cooperate-item>img{position:absolute;}
  .cooperate-item>img{top:30px;left:32px;}
  .cooperate-item .h2{font-size: 20px;color:#121A25;font-weight: 600;top:40px;left:112px;}
  .cooperate-item .p{width:460px;top:89px;left:112px;color:#707070;}
  .nav2{width:620px;display: flex;justify-content: space-between;margin:0 auto;}
  .nav2>div{cursor:pointer;padding:7px 12px;border-bottom:1px solid transparent;color:#121A25;font-size: 20px;text-align:center;}
  .nav2>div.cur{border-bottom-color:#2C2C2C;}
  .cooperate-sys{justify-content: space-between;}
  .cooperate-sys>div{width:380px;height:460px;background:#fff;text-align: center;}
  .cooperate-sys>div>.h2{font-size: 18px;color:#2C2C2C;margin-top:20px;}
  .cooperate-sys>div>.p{line-height:28px;font-size: 14px;color:#666666;width:330px;height:166px;margin:27px auto 17px;text-align: left;}
  .cooperate-sys>div>.btn{margin:0 42px;justify-content: space-between;}
  .cooperate-sys>div>.btn>a{cursor:pointer;display: inline-block;width:130px;height:36px;border-radius: 8px;border:1px solid #2690FD;color:#fff;background:#2690FD;text-align: center;line-height: 35px;}
  .cooperate-sys>div>.btn>a:last-child{border-color:#707070;color:#666666;background:#fff;}
  .important{height:323px;background:url(../../assets/shop/bg-important.png);position:relative;color:#fff;}
  .important .h2{font-size: 22px;position:absolute;top:63px;left:87px;}
  .important .p{color:#efefef;width:522px;position:absolute;left:87px;top:128px;}
  .important img{position: absolute;right:85px;top:-50px;}
  .important2{justify-content: space-between;}
  .important2>div{width:360px;height:557px;box-shadow: 0 0 10px rgba(0,0,0,.1);position:relative;}
  .important2>div:nth-child(2){width:390px;height:589px;margin-top:-16px;}
  .important2>div .h2{color:#2C2C2C;font-size: 22px;text-align: center;margin-bottom:36px;}
  .important2>div .item{margin:0 45px;display: flex;}
  .important2>div .item>img{margin-right:18px;width:24px;height:24px;}
  .important2>div .item>div{font-size: 16px;color:#52565B;margin-bottom:29px;}
  .important2>div .btn{width:154px;height:44px;background:#2690FD;font-size: 18px;text-align: center;position:absolute;bottom:54px;left:50%;margin-left:-77px;border-radius: 4px;color:#ffffff;}
  .last{justify-content: space-between;margin-bottom:200px;}
  .last>img{width:188px;height:79px;}
  .last>img:first-child{margin-left:70px;}
  .last>img:last-child{margin-right:70px;}
</style>