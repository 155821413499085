  <template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">客户案例</div>
          <div class="d-b-info">深耕农业领域，打造集上游农业种养殖基地到农批市场的“农互通”、城市内从农批到终端的“商互通”和终端商户经营方的农贸市场商户的“云管家”于一体的农产品产业链管理平台。</div>
        </div>
      </div>
    </div>
    <div style="height:64px;border-bottom:1px solid #DDE0E7;line-height: 64px;margin-top:40px;" class="ya315-container">
      <router-link :to="{path:'/case'}" href="">客户案例</router-link>/<a href="" style="color:#2690FD;">民权县市场监督管理局</a>
    </div>
    <div class="ya315-container" style="display: flex;justify-content: space-between;margin-top:48px;">
      <div style="width:296px;text-align: center;">
        <img src="../../assets/img/img-case-mq.png" alt="">
        <div style="text-align: center;color:#121A25;margin-top:29px;font-size: 20px;">民权县市场监督管理局</div>
      </div>
      <div style="width:calc(100% - 310px);margin-top:80px;padding-bottom:232px;border-bottom:1px soild #DDE0E7;padding-left: 48px;">
        <div style="color:#121A25;font-size: 20px;">客户简介</div>
        <div style="color:#707070;font-size: 16px;margin-top:16px;">民权县位于河南省东部，商丘、开封和山东菏泽两省三市结合部，1928年经时任河南省国民政府主席冯玉祥批准，取孙中山先生"三民主义"之"民权"而置县。县域面积1222平方公里，下辖17个乡镇、2个街道办事处，529个行政村，总人口91万人。民权交通区位优越。310国道、陇海铁路、连霍高速、郑徐高铁及直通郑州航空港区的郑民高速横穿全境，中国制冷产业基地</div>
        <div style="color:#121A25;font-size: 20px;margin-top:60px;">客户需求</div>
        <div style="color:#707070;font-size: 16px;margin-top:16px;">
          1.实现食品农产品的安全追溯问题，解决食品安全；<br/>
          2.一物一码全程追溯产品信息，让百姓吃的安心；<br/>
          3.从源头溯源来保证食品质量，让老百姓家庭安康；<br/>
        </div>
        <div style="color:#121A25;font-size: 20px;margin-top:60px;">解决方案</div>
        <div style="color:#2C2C2C;font-size: 18px;margin-top:38px;">协助建立产品追溯标准体系</div>
        <div style="color:#707070;font-size: 16px;margin-top:16px;">协助建立以技术标准集、实施标准集、评价标准集为核心的产品追溯标准体系，指导产品追溯平台建设、支持产品追溯信息互联互通和共享，为后期快速复制和推广奠定坚实基础。</div>
        <div style="color:#2C2C2C;font-size: 18px;margin-top:38px;">共建产品追溯示范区</div>
        <div style="color:#707070;font-size: 16px;margin-top:16px;">
          1、一件一码，实现全程精准追溯和召回平台为每个最小包装的产品赋予唯一身份证，一件一码，环环采集、精准定位每一最小包装产品，一旦出现产品问题，支持在第一时间内追溯和召回，将问题产品所带来的不良社会影响降至最低，维护广大消费者的健康权益。 <br/><br/>
          2、通过云计算和物联网技术，高校搭建追溯平台借助云计算、物联网技术，帮助组织提高工作效率，降低IT成本和灵活拓展应用，低成本、安全、可靠构建高效运行的产品追溯管理平台。 <br/><br/>
          3、提供移动稽查APP，让监管监察更有效平台提供移动稽查APP工具，通过单码流向查询、批次流向查询、异常事件预警功能，帮助政府监管部门有效开展业务监督检查，强化监管力度。<br/>
        </div>
        <div style="color:#2C2C2C;font-size: 18px;margin-top:38px;">农产品的应用</div>
        <div style="color:#707070;font-size: 16px;margin-top:16px;">在产品最小包装单位赋码、实现全部流向监控的基础上，系统和各大农业企业、农批农贸、下游餐饮机构、生鲜连锁单位对接，建立追溯码和订单信息关联记录，通过追溯码实现每一个最小包装单位的流向可查询，帮助政府实现对食品的流向监管，确保食品在生产、运输、采购中的安全性。</div>
        <br><br><br><br><br><br><br><br><br><br><br>
        <div style="margin-left:-48px;padding-top:1px;font-size: 18px;color:#2C2C2C;font-weight: bold;border-top:1px solid #DDE0E7;">
          <a style="display: block;margin-top:48px;">上一案例：河南省标准化研究院</a>
          <a style="display:block;margin-top:16px;" href="">下一案例：河南省标准化研究院</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{
      caseNav:1
    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-case.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:170px;margin-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;margin-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.case-nav{display: flex;height:60px;line-height: 60px;border-bottom:2px solid #DDE0E7;}
.case-nav>span{display:inline-block;cursor: pointer;padding:0 15px;border-bottom:1px solid transparent;margin:0 88px -1px;}
.case-nav>span.cur{border-bottom-color:#1890FF;}
.case-item{width:280px;height:240px;border:1px solid #DDE0E7;margin-right:27px;position:relative;margin-top:41px;}
.case-item>img{position:absolute;top:24px;left:24px;}
.case-item>div.case-item-title{position:absolute;top:92px;left:24px;font-size: 18px;color:#121A25;}
.case-item>div.case-item-info{position:absolute;top:140px;left:24px;right:24px;color:#858A90;font-size: 14px;}
.case-foot{height:260px;background:#1890FF;margin-top:278px;}
</style>
