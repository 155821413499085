<template>
  <div id="app" key="Key">
    <Header v-show="isShow"></Header>
    <router-view></router-view>
    <Footer v-show="isShow"></Footer>
    <!--    <Bottom></Bottom>-->
  </div>
</template>
<script>
import Header from "./components/Header"
import Footer from "./components/Footer"
import Bottom from "./components/Bottom"
export default {
  name: "app",
  components: {
    Header,
    Footer,
    Bottom
  },
  data() {
    return {
      Key: "", // 监听URL的变化(解决路由不变,参数变,但页面不刷新的问题)
      isShow: true
    }
  },
  watch: {
    $route: {
      handler(newUrl) {
        if (newUrl.name == "login") {
          this.isShow = false
        } else if (newUrl.name == "userCenter") {
          this.isShow = false
        } else if (newUrl.name == "infoOne") {
          this.isShow = false
        } else if (newUrl.name == "infoTwo") {
          this.isShow = false
        } else if (newUrl.name == "infoThree") {
          this.isShow = false
        } else if (newUrl.name == "infoFour") {
          this.isShow = false
        } else if (newUrl.name == "infoFive") {
          this.isShow = false
        } else if (newUrl.name == "loginCenter") {
          this.isShow = false
        } else if (newUrl.name == "服务商入驻") {
          this.isShow = false
        } else if (newUrl.name == "购物车") {
          this.isShow = false
        } else if (newUrl.name == "确认订单") {
          this.isShow = false
        } else if (newUrl.name == "订单支付") {
          this.isShow = false
        } else if (newUrl.path == "/loginCenter") {
          this.isShow = false
        } else if (newUrl.path == "/loginCenter/searchDetail") {
          this.isShow = false
        } else if (newUrl.path == "/loginCenter/info") {
          this.isShow = false
        } else {
          this.isShow = true
        }
        // 路由(包括参数)发生变化时,对Key进行赋值
        // 如果Key发生了变化,则页面执行刷新
        this.Key = new Date().getTime()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style>
#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}
</style>
