<template>
  <b-container style="paddingTop:80px"  >
    <!-- 面包屑导航 -->
    <b-breadcrumb class="bg-transparent p-0 m-0" :items="items"></b-breadcrumb>
    <header>2020年11项网络安全预测</header>
    <aside>
      <span>2020年3月25日</span>
      <!-- <span>浏览次数：180</span> -->
    </aside>
    <section>
      <div>我们向Gurucul高管查询了2020年的预测，并确定了以下2020年的11项网络安全预测。我们将在明年的这个时候回头检查我们的准确性。同时，留意幽默学家里奥·罗斯滕（Leo Rosten）的话是明智的：“有些事情太出乎意料了，没人为他们做准备。” 这是合理的建议，尤其是对于不断发展的网络安全行业。</div>
      <footer>
        <img src="../../assets/news/six_one.jpg" alt="西安星云宇博数据科技有限公司">
      </footer>
      
      <div>预计2020年将进行大规模5G网络部署。该技术将为许多行业创造机遇，但也将带来来自网络黑暗面的更多威胁。根据ResearchAndMarkets.com的预测，预计EU5 5G市场将在2019年至2025年的预测期内呈现三位数的增长率。使用5G的企业面临的安全问题是，网络配置不同，供应商的解决方案和方法也大相径庭。我们可以预期在2020年看到的一些最大的5G问题涉及供应链和部署。庞大的5G供应链容易受到诸如恶意软件或硬件以及不良设计之类的漏洞的影响。此外，许多为5G网络提供硬件和软件的公司都有自己的安全漏洞。因此，我们应该期望网络资产妥协的增加和对数据机密性的负面影响。至于5G网络的部署，安全问题将包括增加的攻击面。这是由于与上一代无线网络相比，使用了更多的信息和通信技术（ICT）组件。</div>
      <div>网络犯罪分子寻找实现目标的最简单途径，有时该途径直接贯穿第三方供应商。通过供应链的攻击已经很普遍。2013年臭名昭著的Target数据泄露事件和2010年发现的破坏性Stuxnet攻击都是通过易受攻击的第三方提供商发起的。</div>
      <div>
        自动化攻击工具导致网络安全团队必须处理的数据量大量增加。人类不可能跟上如此大量的数据。组织进行反击的唯一有效方法是使用自己的自动化安全技术。自动化可以为IT部门带来很多繁重的工作。这样一来，网络安全团队就可以将更少的时间花费在尝试通过人工手段抵抗攻击上，徒劳无功。我们应该期望机器学习技术的有效性（和需求）有所提高，以便在异常行为和行动表明正在进行网络攻击时自动识别并进行干预。
      </div>
      <div>组织将大大增加网络安全方面的支出。最大的挑战是确保支出集中在正确的领域。在大多数情况下，情况不会如此，尽管全球范围内用于网络防御的支出达到创纪录水平，但我们可以预期2020年数据泄露事件会有所增加。</div>
      <div>根据我们的研究，有40％的组织无法检测到内部威胁，或者只能在数据离开组织之后才能检测到内部威胁。现在，越来越多的组织认识到内部和外部的威胁。因此，2020年应该是主动内部威胁安全计划成为主流的一年。</div>
      <div>欺诈医疗保险公司，医疗保健提供者和个人消费者所赚的钱是天文数字。犯罪分子从事虚假的保险索赔，重复索赔，虚假索赔，虚假的医疗保健提供者网站，保险诈骗等等。医疗欺诈的清单很长，并且在越来越复杂的相互关联的医疗模糊网络中不断增长。</div>
      <div>随着组织越来越多地将其数据和工作负载迁移到云中，我们可以预期会有更多针对云服务提供商的攻击从云提供商所服务的公司窃取数据。因此，公司将寻求更多方式来在其本地和云环境中获得可见性并对其数据进行控制。处理敏感数据的组织将开始向其云服务提供商施加压力，要求他们采用与内部应用相同级别的数据安全措施。</div>
      <div>到2020年，使用人工智能（AI）/机器学习的网络攻击将会增加。攻击者将利用AI来发现和利用弱点，并利用从成功的黑客中收集的信息来开发更强大的攻击。</div>
      <div>在2019年，我们发现协调良好的勒索软件攻击有所增加，例如攻击了德克萨斯州22个社区的勒索软件攻击。根据梭子鱼网络公司的数据，2019年美国将近三分之二的勒索软件攻击针对州或地方政府。根据Coveware的研究，到2019年第二季度，各国政府平均支付的勒索软件钱几乎是私营部门的勒索软件钱的10倍。因此，勒索软件攻击的总体速度可能会下降。但是，随着犯罪分子去钱的地方，对市政当局的勒索软件攻击只会在2020年增加。</div>
      <div>对医疗设备进行勒索软件攻击的趋势正在出现，在医疗保健安全方面造成了一些严重的漏洞。尽管这些攻击大多已受到关注，并且迄今很少，但我们可以预见到，这些高度针对性的攻击将在2020年有所增加。</div>
      <div>坏演员已经使用BEC了相当长的时间。根据我们在2019年所看到的情况，这在复杂性和获利能力方面已经提高了。根据Forrester的估计，2016年至2019年之间由于企业电子邮件泄露而造成的估计损失总计260亿美元。我们应该期望BEC会比勒索软件变得更加有利可图。</div>
    </section>
    <article>
      <span>下一篇:</span>
      <router-link :to="{name:'news_five'}">
        <span class="pl-2">2020年一季度十大网络安全热点大盘点</span>
      </router-link>
    </article>
  </b-container>
</template>

<script>
export default {
  name:'NewsFour',
  data(){
    return{
      items:[
        {
          text:'您现在的位置:',
          active:false
        },
        {
          text:'首页',
          to:{name:'index'},
          active:false
        },
        {
          text:'新闻动态',
          to:{name:"news"},
          active:false
        },
        {
          text:'2020年11项网络安全预测',
          active:false
        }
      ]
    }
  }
}
</script>

<style scoped>
  .container{
    max-width: 1920px;
    padding:1rem 15% 3rem;
  }
  /* 给面包屑导航更改样式 */
  .container>.breadcrumb>.breadcrumb-item:nth-child(3){
    padding:0;
  }
  .container>.breadcrumb>.breadcrumb-item:nth-child(4){
    padding: 0;
  }
  .container>.breadcrumb>.breadcrumb-item:nth-child(2)::before{
    display: inline-block;
    padding:0;
    content: "";
  }
  .container>.breadcrumb>.breadcrumb-item>a{
    color: #666666;
    text-decoration: none;
  }
  .container>.breadcrumb>.breadcrumb-item:nth-child(3)::before{
    display: inline-block;
    padding: 0 .5rem;
    color: #666666;
    content: ">";
  }
  .container>.breadcrumb>.breadcrumb-item:nth-child(4)::before{
    display: inline-block;
    padding: 0 .5rem;
    color: #666666;
    content: ">";
  }
  /* 对新闻内容部分进行样式设置 */
  .container>header{
    width:100%;
    text-align: center;
    font-size: 2rem;
    padding-bottom: 1rem; 
  }
  .container>aside{
    width: 100%;
    font-size: .75rem;
    text-align: center;
    padding-bottom: 3rem;
    color: #666666;
  }
  /* .container>aside>span:first-child{
    display: inline-block;
    padding-right: 2rem;
  } */
  .container>section>div{
    padding-bottom:1rem;
    text-indent: 2em;
    color: #666666;
  }
  .container>section>footer{
    width: 100%;
    padding:1rem 10% 2rem;
  }
  .container>section>footer>img{
    width: 100%;
  }
  .container>article{
      padding-top:2rem;
  }
</style>