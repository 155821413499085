<template>
  <div style="width: 100%">
    <div style="width: 1200px;margin: 0 auto;padding: 20px;">
      <div style="display: flex;align-items: center;height: 50px;width: 200px;height: 50px;line-height: 50px;text-align: center;font-size: 20px;border-bottom: 1px solid #00BAFF;justify-content: space-between;">
        <p style="">
          服务商入驻</p><el-button type="text" @click="backService('ruleForm')">返回</el-button>
      </div>
      <div style="padding-top: 20px;display: flex;justify-content: space-around;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" inline label-width="150px">
          <el-form-item label="公司名称" prop="name">
            <el-input style="width: 300px;" v-model="ruleForm.name" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="公司网址" prop="url">
            <el-input style="width: 300px;" v-model="ruleForm.url" placeholder="请输入公司网址"></el-input>
          </el-form-item>
          <el-form-item label="注册资金" prop="price">
            <el-input style="width: 300px;" v-model="ruleForm.price" placeholder="请输入注册资金"></el-input>
          </el-form-item>
          <el-form-item label="雇员规模" prop="empsize">
            <el-input style="width: 300px;" v-model="ruleForm.empsize" placeholder="请输入雇员规模"></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="addr">
            <el-input style="width: 300px;" v-model="ruleForm.addr" placeholder="请输入通讯地址"></el-input>
          </el-form-item>
          <el-form-item label="商务联系人" prop="contacts">
            <el-input style="width: 300px;" v-model="ruleForm.contacts" placeholder="请输入商务联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="mobile">
            <el-input style="width: 300px;" v-model="ruleForm.mobile" placeholder="请输入联系人手机号"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="email">
            <el-input style="width: 300px;" v-model="ruleForm.email" placeholder="请输入联系人邮箱"></el-input>
          </el-form-item>
          <el-form-item label="投放产品或服务" prop="service">
            <el-input style="width: 300px;" v-model="ruleForm.service" type="textarea" placeholder="请输入要投放的产品或服务"></el-input>
          </el-form-item>
          <el-form-item label="是否有业务接洽人" prop="contacter">
            <div style="margin-left: 20px;">
              <el-radio v-model="ruleForm.contacter" @change="change" :label="0">是</el-radio>
              <el-radio v-model="ruleForm.contacter" @change="change" :label="1">否</el-radio>
            </div>
          </el-form-item>
          <el-form-item v-show="ruleForm.contacter == 0" label="接洽人姓名" prop="contacterName">
            <el-input style="width: 300px;" v-model="ruleForm.contacterName" placeholder="请输入接洽人姓名"></el-input>
          </el-form-item>
          <el-form-item v-show="ruleForm.contacter == 0" label="接洽人手机号码" prop="contacterMobile">
            <el-input style="width: 300px;" v-model="ruleForm.contacterMobile" placeholder="请输入联系人手机号码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-form>
        <el-form-item style="text-align: center">
          <el-checkbox v-model="disbaleShow">阅读并接受 <el-button type="text">《食安链家云市场服务商入驻协议》</el-button></el-checkbox>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" :disabled="disbaleShow?false:true" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceProvider",
    data(){
      return {
        disbaleShow:false,
        ruleForm:{
          name:"", url:"",price:"",empsize:"",addr:"",contacts:"",mobile:"",email:"",service:"",
          contacter:0,contacterName:"",contacterMobile:"",
        },
        rules: {
          name: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          url:[
            { required: true, message: '请输入公司网址', trigger: 'blur' }
          ],
          price:[
            { required: true, message: '请输入注册资金', trigger: 'blur' }
          ],
          empsize:[
            { required: true, message: '请输入雇员规模', trigger: 'blur' }
          ],
          addr:[
            { required: true, message: '请输入通讯地址', trigger: 'blur' }
          ],
          contacts:[
            { required: true, message: '请输入上午联系人', trigger: 'blur' }
          ],
          mobile:[
            { required: true, message: '请输入联系人手机号', trigger: 'blur' }
          ],
          email:[
            { required: true, message: '请输入联系人邮箱', trigger: 'blur' }
          ],
          service:[
            { required: true, message: '请输入要投放的产品或服务', trigger: 'blur' }
          ],
          contacter:[
            { required: true, message: '请选择是否有业务接洽人', trigger: 'blur' }
          ],
          contacterName:[
            { required: true, message: '请输入接洽人姓名', trigger: 'blur' }
          ],
          contacterMobile:[
            { required: true, message: '请输入接洽人手机号', trigger: 'blur' }
          ]
        }
      }
    },
    mounted() {
    },
    methods:{
      change(val){
        this.ruleForm.contacterName = ""
        this.ruleForm.contacterMobile = ""
        if(val == 0){
          this.rules.contacterName[0].required = true
          this.rules.contacterMobile[0].required = true
        }else if(val == 1){
          this.rules.contacterName[0].required = false
          this.rules.contacterMobile[0].required = false
        }
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log("可以提交")
          } else {
            console.log('不能提交');
            return false;
          }
        });
      },
      backService(formName){
        this.$refs[formName].resetFields();
        this.$emit("backservice")
      },
    },
  }
</script>

<style scoped>

</style>
