<template>
  <div id="foot" class="ya315-foot">
    <div class="ya315-container">
      <div>
        <div class="foot-title">关于我们</div>
        <div class="foot-link-item">关于食安链家</div>
        <div class="foot-link-item">云市场介绍</div>
        <div class="foot-link-item">联系我们</div>
        <!-- <div class="foot-link-item" style="cursor: pointer" @click="toLink">
          登录中心
        </div> -->
      </div>
      <div>
        <div class="foot-title">热门产品</div>
        <router-link
          :to="{ path: '/product/one', query: { type: 'dj' } }"
          class="foot-link-item"
          >蛋鸡产业链平台</router-link
        >
        <div class="foot-link-item">智慧新餐饮管理云系统</div>
        <div class="foot-link-item">智慧新生鲜管理云系统</div>
        <router-link
          :to="{ path: '/product/one', query: { type: 'ccgl' } }"
          class="foot-link-item"
          >云仓储SAAS管理云平台</router-link
        >
        <router-link
          :to="{ path: '/product/one', query: { type: 'wls' } }"
          class="foot-link-item"
          >云物流SAAS管理云平台</router-link
        >
      </div>
      <div>
        <div class="foot-title">解决方案</div>
        <router-link
          :to="{ path: '/solutionNew', query: { type: 'qyzs' } }"
          class="foot-link-item"
          >防伪追溯</router-link
        >
        <div class="foot-link-item">农产品流通</div>
        <router-link
          :to="{ path: '/solutionNew', query: { type: 'zcls' } }"
          class="foot-link-item"
          >餐饮连锁</router-link
        >
        <router-link
          :to="{ path: '/solutionNew', query: { type: 'sxmd' } }"
          class="foot-link-item"
          >生鲜超市</router-link
        >
        <div class="foot-link-item">供应链金融</div>
      </div>
      <div>
        <div class="foot-title">合作伙伴</div>
        <div class="foot-link-item">产品服务合作</div>
        <div class="foot-link-item">战略合作伙伴</div>
        <div class="foot-link-item">政府合作伙伴</div>
      </div>
      <div>
        <div class="foot-title">关注联系我们</div>
        <div class="foot-link-item">扫描微信公众号</div>
        <img
          style="display: block; margin-bottom: 20px"
          src="../assets/img/mini-code.png"
          width="106"
          height="106  "
          alt=""
        />
        <div class="foot-link-item">咨询热线：17337135577</div>
        <div class="foot-link-item">邮箱：yunansuyuan@163.com</div>
      </div>
    </div>
    <div class="friend ya315-container">
      <div>友情链接</div>
      <!-- <a href="http://yunansuyuan.com/">云安溯源</a> -->
      <a href="https://limafangxin.com/#/home" target="_blank">立码放心</a>
      <a href="https://henantrace.org.cn" target="_blank"
        >河南省食品农产品安全追溯平台</a
      >
      <a
        href="http://www.gs1cn.org/Org/BranchMsg?branchCode=4101"
        target="_blank"
        >中国物品编码中心河南中心</a
      >
    </div>
    <div class="ya315-container foot-bottom">
      Copyright 2020-2021 云安溯源 版权所有
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >豫ICP备20017364号-5</a
      >
      <img src="../assets/img/foot_icon.png" width="16" height="18" alt="" />
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010302002710"
        target="_blank"
        >豫公网安备41010302002710号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.ya315-foot {
  height: 563px;
  text-align: center;
  background: #212330;
  color: #9b9b9b;
}
.ya315-foot > div {
  display: flex;
}
.ya315-foot > div > div {
  margin-right: 88px;
}
.ya315-foot > div > div:last-child {
  margin-left: 48px;
  margin-right: 0;
}
.foot-title {
  color: #ffffff;
  font-size: 20px;
  padding: 60px 0 39px;
  text-align: left;
}
.foot-link-item {
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
  display: block;
}
.friend {
  height: 75px;
  border-top: 1px solid #3b3f58;
  border-bottom: 1px solid #3b3f58;
  line-height: 75px;
  display: flex;
  margin-top: 24px;
}
.friend a {
  color: #9b9b9b;
  display: inline-block;
  font-size: 18px;
  margin-right: 60px;
}
.friend > div:first-child {
  margin-left: 32px;
  margin-right: 75px;
  color: #ffffff;
  font-size: 20px;
}
.foot-bottom {
  margin-top: 20px;
  text-align: center;
  display: block !important;
}
</style>
