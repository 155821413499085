<template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">养殖场管理平台</div>
          <div class="d-b-info">实现养鸡场生产管理线上化、生产数据实时化、营销采购公开透明化、资金快速流转、物流过程监管，对降本增效、加强管理、产品流通发挥较强作用。</div>
          <a style="margin-left: 360px;" href="https://raise.yunan315.com/store/login" target="_blank" class="d-b-button">登录平台</a>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">平台介绍</div>
      <div class="ya315-container">
        <div class="d-i-img"><img src="../../assets/img/img-pro-yzcgl.png" width="433" height="319" alt=""></div>
        <div class="d-i-description">
          <div class="d-i-name">养殖场管理平台</div>
          <div class="d-i-info">实现养鸡场生产管理线上化、生产数据实时化、营销采购公开透明化、资金快速流转、物流过程监管，对降本增效、加强管理、产品流通发挥较强作用。</div>
          <a href="https://raise.yunan315.com/store/login" target="_blank" class="d-i-button">登录平台</a>
        </div>
      </div>
    </div>
    <div>
      <div class="d-i-title">平台架构</div>
      <div class="ya315-container">
        <img src="../../assets/img/img-yzcgl1.png" alt="">
        <img style="margin-top:95px;margin-bottom:80px;" src="../../assets/img/img-yzcgl2.png" alt="">
      </div>
    </div>
    <div class="advantage-page" style="background:#F6F8FF;padding-top:1px;">
      <div class="d-i-title">平台优势</div>
      <div class="advantage-box">
        <div>
          <img src="../../assets/img/icon-pps-yyx.png" width="72" height="72" alt="">
          <p>易用性</p>
        </div>
        <div>
          <img src="../../assets/img/icon-pps-dch.png" width="72" height="72" alt="">
          <p>多层化</p>
        </div>
        <div>
          <img src="../../assets/img/icon-pps-tyh.png" width="72" height="72" alt="">
          <p>统一化</p>
        </div>
      </div>
    </div>
    <div class="sys-page">
      <div class="d-i-title" style="color:#ffffff;margin-top:0;padding-top:70px;">蛋鸡溯源供应链系统</div>
      <div class="d-s-info">蛋鸡综合服务平台和生态圈，以提高鸡场效率为目标，提高鸡场盈利能力，带动服务转型升级，打造供应链平台</div>
      <div class="d-s-img">
        <img src="../../assets/img/img-dj.png" width="1080" height="680" alt="">
      </div>
    </div>
    <div class="dj-bottom">
      <div class="d-i-title" style="margin-top:0;padding-top:110px;">打造蛋鸡绿色供应链运作模式</div>
      <div class="d-btm-info">加强各利益节点联系，节省交易费用、促进利益公平分配、保障蛋鸡产品质量安全。</div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.banner{height:500px;background:#3A3D41 url(../../assets/img/banner-yzcgl.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);position: absolute;left: 0;right: 0;top: 0;bottom: 0}
.d-b-title{font-size: 48px;color:#fff;padding-top:125px;padding-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;padding-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.d-i-img{width:433px;height:319px;margin-left:54px;display: inline-block;}
.d-i-description{float:right;width:600px;}
.d-i-name{font-size: 30px;color:#333333;margin-top:12px;}
.d-i-info{color:#999999;font-size: 20px;margin-top:40px;}
.d-i-button{width:232px;height:54px;background:#2690FD;font-size: 22px;color:#FFFFFF;margin-top:32px;border-radius: 12px;display: block;line-height: 54px;text-align: center;cursor:pointer;}
.advantage-box{width:1125px;margin:0 auto;display: flex;justify-content: center;}
.advantage-box>div{width:33%;text-align: center;}
.advantage-box>div>p{margin-top:20px;color:#6B6B6B;font-size: 24px;margin-bottom:105px;}
.sys-page{height:1100px;background:#0A0A0A;}
.d-s-info{font-size: 20px;color:rgba(255,255,255,08);margin-top:-32px;text-align: center;}
.d-s-img{margin:64px auto 0;text-align: center;}
.dj-bottom{background:url(../../assets/img/bg-pro-foot-dj.png) no-repeat;background-size:100% 100%;height:324px;}
.d-btm-info{font-size: 20px;color:#999999;margin-top:-40px;text-align: center;}
</style>
