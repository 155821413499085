<template>
  <div>
    <header>定制开发服务</header>
    <section>平台依托其专业的开发团队、成熟的网管平台以及丰富的网管开发经验，为用户提供完全可靠的网管软件定制开发服务。</section>
  </div>
</template>

<script>
export default {
  name:'FooterImage'
}
</script>

<style scoped>
  div{
    width:100%;
    height:735px;
    background-image: url("../../assets/technology/pc_dzkffw.png");
    background-size: 100%;
    color: #FFFFFF;
  }
  div>header{
    width:100%;
    text-align: center;
    font-size: 24px;
    padding: 2rem 0 1rem;
  }
  div>section{
    width: 100%;
    text-align: center;
  }
</style>