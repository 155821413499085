  <template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">客户案例</div>
          <div class="d-b-info">深耕农业领域，打造集上游农业种养殖基地到农批市场的“农互通”、城市内从农批到终端的“商互通”和终端商户经营方的农贸市场商户的“云管家”于一体的农产品产业链管理平台。</div>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">全部案例</div>
      <div class="ya315-container case-nav">
        <span :class="caseNav==1?'cur':''" @click="caseNav = 1">政务</span>
        <span :class="caseNav==2?'cur':''" @click="caseNav = 2">金融</span>
        <span :class="caseNav==3?'cur':''" @click="caseNav = 3">餐饮</span>
        <span :class="caseNav==4?'cur':''" @click="caseNav = 4">零售</span>
        <span :class="caseNav==5?'cur':''" @click="caseNav = 5">农业</span>
      </div>
      <div class="ya315-container" style="display: flex">
        <div class="case-item">
          <img src="../../assets/img/img-case1.png" alt="">
          <div class="case-item-title">中国物品编码中心</div>
          <div class="case-item-info">中国物品编码中心是统一组织、协调、管理我国商品条码、物品编码与自动识别技术的专门机构</div>
        </div>
        <div class="case-item">
          <img src="../../assets/img/img-case2.png" alt="">
          <div class="case-item-title">河南省标准化研究院</div>
          <div class="case-item-info">河南省市场监督管理局直属公益一类全供事业单位，是河南省唯一专业从事标准化研究工作的技术机构</div>
        </div>
        <router-link :to="{path:'/caseDetail',query:{type:'mqsc'}}" class="case-item">
          <img src="../../assets/img/img-case3.png" alt="">
          <div class="case-item-title">民权县市场监督管理局</div>
          <div class="case-item-info">负责全县市场综合监督管理。拟订并组织实施有关规划，规范和维护市场秩序</div>
        </router-link>
      </div>
    </div>
    <div class="case-foot">
      <div style="font-size: 30px;color:#ffffff;text-align: center;padding-top:69px;">定制化的解决方案，满足您的实际业务需求</div>
      <a href="" style="width:178px;background:#ffffff;text-align: center;line-height: 50px;height:50px;color:#1890FF;border-radius: 4px;display: block;margin:29px auto;">联系我们</a>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{
      caseNav:1
    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-case.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:170px;margin-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;margin-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.case-nav{display: flex;height:60px;line-height: 60px;border-bottom:2px solid #DDE0E7;}
.case-nav>span{display:inline-block;cursor: pointer;padding:0 15px;border-bottom:1px solid transparent;margin:0 88px -1px;}
.case-nav>span.cur{border-bottom-color:#1890FF;}
.case-item{width:280px;height:240px;border:1px solid #DDE0E7;margin-right:27px;position:relative;margin-top:41px;}
.case-item>img{position:absolute;top:24px;left:24px;}
.case-item>div.case-item-title{position:absolute;top:92px;left:24px;font-size: 18px;color:#121A25;}
.case-item>div.case-item-info{position:absolute;top:140px;left:24px;right:24px;color:#858A90;font-size: 14px;}
.case-foot{height:260px;background:#1890FF;margin-top:278px;}
</style>
