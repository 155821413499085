<template>
  <div>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">供应商集采集供数据<br/>管理平台</div>
          <div class="d-b-info">帮助采购商降低采购成本、提升采购品质、降低采购风险</div>
          <a style="margin-left: 360px;" href="https://salj.yunan315.com/supplier" target="_blank" class="d-b-button">登录平台</a>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">平台介绍</div>
      <div class="ya315-container">
        <div class="d-i-img"><img src="../../assets/img/gysjcjg-info.png" width="433" height="319" alt=""></div>
        <div class="d-i-description">
          <div class="d-i-name">供应商集采集供数据管理平台</div>
          <div class="d-i-info">平台包含供应商商品管理，供应关系的管理，供货订单查询及处理，配送单管理，费用计算和供单情况的统计分析</div>
          <a href="https://salj.yunan315.com/supplier" target="_blank" class="d-i-button">登录平台</a>
        </div>
      </div>
    </div>
    <div class="advantage-page">
      <div class="d-i-title">平台优势</div>
      <div class="advantage-box">
        <div>
          <img src="../../assets/img/icon-sjh.png" width="72" height="72" alt="">
          <p>数据化</p>
        </div>
        <div>
          <img src="../../assets/img/icon-spncp-aqh.png" width="72" height="72" alt="">
          <p>安全化</p>
        </div>
        <div>
          <img src="../../assets/img/icon-cysy-ksh.png" width="72" height="72" alt="">
          <p>快速化</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{

    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/gysjcjg-banner.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:120px;margin-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;margin-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.d-i-img{width:433px;height:319px;margin-left:54px;display: inline-block;}
.d-i-description{float:right;width:600px;}
.d-i-name{font-size: 30px;color:#333333;margin-top:12px;}
.d-i-info{color:#999999;font-size: 20px;margin-top:40px;}
.d-i-button{width:232px;height:54px;background:#2690FD;font-size: 22px;color:#FFFFFF;margin-top:32px;border-radius: 12px;display: block;line-height: 54px;text-align: center;cursor:pointer;}
.advantage-box{width:1125px;margin:0 auto;display: flex;justify-content: center;}
.advantage-box>div{width:33%;text-align: center;}
.advantage-box>div>p{margin-top:20px;color:#6B6B6B;font-size: 24px;margin-bottom:105px;}
.sys-page{height:1100px;background:#0A0A0A;}
.d-s-info{font-size: 20px;color:rgba(255,255,255,08);margin-top:-32px;text-align: center;}
.d-s-img{margin:64px auto 0;text-align: center;}
.dj-bottom{background:url(../../assets/img/bg-pro-foot-dj.png) no-repeat;background-size:100% 100%;height:324px;}
.d-btm-info{font-size: 20px;color:#999999;margin-top:-40px;text-align: center;}
</style>
