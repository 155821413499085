<template>
  <b-container>
    <div @click="jump6()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>25</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>2020年11项网络安全预测</div>
        <div>我们向Gurucul高管查询了2020年的预测，并确定了以下2020年的11项网络安全预测。我们将在明年的这个时候回头检查我们的准确性。同时，留意幽默学家里奥·罗斯滕（Leo Rosten）的话是明智的：“有些事情太出乎意料了，没人为他们做准备。” 这是合理的建议，尤其是对于不断发展的网络安全行业。</div>
      </section>
      <aside>
        <img src="../../assets/news/six_one.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>
    <div @click="jump5()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>23</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>2020年一季度十大网络安全热点大盘点</div>
        <div>随着网络威胁泛化的愈演愈烈，安全形势变得越来越复杂。物联网设备攻击,、无恶意软件攻击以及非电子邮件网络钓鱼攻击继续上升。防范这些攻击的代价从未像当下这样如此高昂。Ponemon公司最新发布数据泄露报告显示，数据泄露的平均成本现在已高达每起事件392万美元。随着威胁行为者不断改变方法，利用新技术并尝试新的攻击手段，无论是企业还是政府组织可能难以跟上这一变化的快速步伐。</div>
      </section>
      <aside>
        <img src="../../assets/news/five_one.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>
    <div @click="jump4()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>21</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>三国专家论道网络安全云峰会 互信应是数字世界共治的基石</div>
        <div>在“你中有我，我中有你”的全球化时代，疫情的蔓延让所有人陡然置身于“数字世界”的实战场景中。如何利用数字技术阻止疫情的蔓延，达成数字世界的治理共识，推动全球经济健康和可持续发展，已成全球社会当前普遍关心的问题。</div>
      </section>
      <aside>
        <img src="../../assets/news/four_one.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>
    <div @click="jump3()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>19</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>自动化运维之后，你还在人工巡检吗？</div>
        <div>初期阶段IT基础设施通常处在小规模状态。几台至几十台机器的规模，足以满足业务需求。很多公司都不一定配有专门的运维人员或者部门，业务开发人员完成自己业务工作的同时，也一并完成所负责管理相关业务的设备。随着云时代到来了，IT基础设施迅速发展成几百上千服务器。更多的业务系统上线，业务人员也无暇再顾及运维工作。此时，运维人员开始专业化，独立成部门。各类孤岛式的运维管理工具上线，提升运维效率。</div>
      </section>
      <aside>
        <img src="../../assets/news/three_one.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>
    <div @click="jump2()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>18</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>工信部部署开展2020年IPv6端到端贯通能力提升专项行动</div>
        <div>工业和信息化部近日发文部署开展2020年IPv6端到端贯通能力提升专项行动。行动包括优化提升IPv6网络接入能力、加快提升内容分发网络（CDN）IPv6应用加速能力、大幅提升云服务平台IPv6业务承载能力、全面扩大数据中心（IDC）IPv6覆盖范围、着力提升终端设备IPv6支持能力、稳步提升行业网站及互联网应用IPv6浓度、着力强化IPv6 网络安全保障能力等七项重点任务。</div>
      </section>
      <aside>
        <img src="../../assets/company_profile/articel.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>
    <div @click="jump1()" class="pb-3 d-flex flex-row flex-nowrap justify-content-between ">
      <article class="p-2 d-flex flex-column justify-content-center">
        <div>17</div>
        <div>2020年03月</div>
      </article>
      <section>
        <div>工信部实施《中小企业数字化赋能专项行动方案》</div>
        <div>《方案》提出，要坚持统筹推进新冠肺炎疫情防控和经济社会发展，以新一代信息技术与应用为支撑，以提升中小企业应对危机能力、夯实可持续发展基础为目标，集聚一批面向中小企业的数字化服务商，培育推广一批符合中小企业需求的数字化平台、系统解决方案、产品和服务，助推中小企业通过数字化网络化智能化赋能实现复工复产，增添发展后劲，提高发展质量。</div>
      </section>
      <aside>
        <img src="../../assets/company_profile/articel.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </div>

  </b-container>
</template>

<script>
export default {
  name:'SmallCarousel',
  methods:{
    jump1(){
      this.$router.push({path:'/news/one'})
    },
    jump2(){
     this.$router.push({name:'news_two'})
    },
    jump3(){
     this.$router.push({name:'news_three'})
    },
    jump4(){
      this.$router.push({name:'news_four'})
    },
    jump5(){
      this.$router.push({name:'news_five'})
    },
    jump6(){
      this.$router.push({name:'news_six'})
    },
  }
}
</script>

<style scoped>
  .container{
    padding: 0 15% 3rem;
    color:#666666;
  }
  .container>.d-flex{
    cursor: pointer;
  }
  .container>.d-flex>article{
    width: 12%;
    height: 120px;
    background: #E6E8ED;
  }
  .container>.d-flex>article>div{
    text-align: center;
  }
  .container>.d-flex>article>div:first-child{
    font-size:2rem;
    font-weight: bold;
  }

  .container>.d-flex>section{
    width: 61%;
  }
  .container>.d-flex>section>div:first-child{
    font-size: 1.3rem;
    font-weight: bold;
    padding-bottom:.5rem ;
  }
  .container>.d-flex>section>div:nth-child(2){
    font-size: .75rem;
  }
  .container>.d-flex>aside{
    width:23%;
  }
  .container>.d-flex>aside>img{
    width: 100%;
    height:125px;
  }
</style>