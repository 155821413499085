<template>
  <div v-loading="loading" class="box">
    <div style="height: 50px;"></div>
    <div class="content">
      <div class="addr">
        <p class="title">选择地址</p>
        <div style="display: flex;">
          <div>
            <div @click="too(item,item.id)" :class="activeIndex == item.id?'active':''" v-for="(item,index) in addr" :key="index" style="font-size: 15px;color: #aaa;cursor: pointer;">
              {{ item.provCode }} {{ item.cityCode }} {{ item.countyCode }}
              {{ item.addrDetail }} ({{ item.name }} 收)
              <span style="color: grey">{{ item.phone }}</span>
            </div>
          </div>
          <div>
            <div>
              <span v-show="editAddr" style="margin-left: 20px;cursor: pointer;color: #409eff;font-size: 15px;" @click="editAddress">确认修改</span>
              <span v-show="!editAddr" style="margin-left: 20px;cursor: pointer;color: #409eff;font-size: 15px;" @click="editAddress">修改地址</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      确认订单信息-->
    <div class="content" style="margin-top: 20px;">
      <div class="title">确认订单信息</div>
      <div class="info">
        <div>商品信息</div>
        <div>商品规格</div>
        <div>单价</div>
        <div>数量</div>
        <div>周期单位</div>
        <div>小计</div>
      </div>
      <div v-for="(item,index) in data" :key="index" class="shop">
        <div style="display: flex;">
          <img width="50" :src="JSON.parse(item.goodsImgs)[0]" alt="">
          <div style="font-weight: bold;margin-left: 20px;">{{ item.goodsTitle }}</div>
        </div>
        <div>{{ item.specName }}</div>
        <div style="text-align: center">{{ item.price/100 | price }}</div>
        <div style="text-align: center">{{ item.quantity }}</div>
        <div style="text-align: center">{{ item.dateUnit==1?'天':item.dateUnit==2?'月':item.dateUnit==3?'年':item.dateUnit==4?'长期':'暂无' }}</div>
        <div style="text-align: center;color: red;font-weight: bold;font-size: 17px;">{{ item.price*item.quantity/100 | price }}</div>
      </div>
      <div style="width: 100%;height: 50px;line-height: 80px;font-size: 15px;padding-left: 20px;">
        <span>商品种类：</span><span>{{ data.length }}种</span>
        <span style="margin-left: 20px;">数量总计：</span><span>{{ num }}件</span>
      </div>
    </div>
    <div class="youhuiquan">
      <p class="title" style="font-size: 15px;">优惠券/抵用</p>
    </div>
    <div class="settlement" style="margin-top: 20px;">
      <div></div>
      <div></div>
      <div>
        <p>
          <span style="font-weight: bold;font-size: 12px;">实付款：</span>
          <span style="color: #999;font-size: 20px;font-weight: bold;">￥</span>
          <span style="font-size: 20px;font-weight: bold;color: #ff5000;">{{ sum | price }}</span>
        </p>
        <div>
          <span style="font-weight: bold;font-size: 12px;">寄送至：</span>
          <span v-for="(item,index) in addr" :key="index" v-show="item.isDefault == 1" style="font-size: 12px;cursor: pointer;">
            {{ item.provCode }} {{ item.cityCode }} {{ item.countyCode }}
            {{ item.addrDetail }}
          </span>
          <div style="font-size: 12px;">
            <span style="font-weight: bold;">收货人：</span>
            <span v-for="(item,index) in addr" :key="index" v-show="item.isDefault == 1">{{ item.name }} {{ item.phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 80%;height: 50px;margin: 0 auto;display: flex;justify-content: space-between;">
      <div></div>
      <div></div>
      <div style="width: 20%;height: 50px;display: flex;justify-content: space-between;">
        <el-button @click="$router.push({path:'/cart'})" icon="el-icon-arrow-left" type="text" style="color: #36c;">返回购物车</el-button>
        <el-button @click="submit" style="background-color: rgb(255, 80, 0);color: white;width: 182px;font-weight: bold;font-size: 17px;">提交订单</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "confirmOrder",
    data(){
      return {
        addr:[],
        editAddr:false,
        activeIndex:0,
        loading:false,
        obj:{},
        arr:[],
        data:[],
      }
    },
    computed:{
      sum(){
        let sum = 0
        this.data.forEach((item,index)=>{
          sum = sum + Number(item.price/100 * item.quantity)
        })
        return sum
      },
      num(){
        let num = 0
        this.data.forEach((item,index)=>{
          num = num + item.quantity
        })
        return num
      },
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    mounted() {
      this.userList()
      if(JSON.parse(localStorage.getItem("order"))){
        this.data = JSON.parse(localStorage.getItem("order"))
      }
    },
    methods:{
      userList(){
        this.loading = true
        this.addr = []
        this.http.addrList().then(res=>{
          if(res.code == "1"){
            this.arr = res.data
            this.loading = false
            this.arr.forEach((item,index)=>{
              if(item.isDefault == 1){
                this.addr = [item]
                this.activeIndex = item.id
              }
            })
          }
        })
      },
      editAddress(bool){
        this.loading = true
        if(this.editAddr){
          if(JSON.stringify(this.obj)==="{}"){
            this.userList()
            this.editAddr = false
            this.loading = false
          }else{
            this.http.upDefault('?id=' + this.obj.id).then(res=>{
              if(res.code == "1"){
                this.userList()
                this.editAddr = false
                this.loading = false
              }
            })
          }
        }else{
          this.addr = this.arr
          this.editAddr = true
          this.loading = false
        }
      },
      too(item,index){
        this.activeIndex = index
        this.obj = item
      },
      submit(){
        this.$confirm('确定提交订单吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback: action => {
            console.log(this.data)
            let list = []
            this.data.forEach((item,index)=>{
              let obj = {
                goodsId:item.goodsId,
                specId:item.specId,
                goodsNum:item.quantity
              }
              list.push(obj)
            })
            //获取寄送快递的地址的id
            let str = ""
            this.addr.forEach((item,index)=>{
              if(item.isDefault == 1){
                str = item.id
              }
            })
            let sum = 0
            this.data.forEach((item,index)=>{
              sum = sum + item.price * item.quantity
            })
            let obj = {
              goodsData:list,
              addrData:str,
              price:sum
            }
            this.http.cartSubmit(obj).then(res=>{
                if(res.code == "1"){
                  document.cookie = 'orderId' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//存储数据之前删除cookie删除token
                  //将token存储cookie
                  let orderId = res.content
                  let date = new Date();
                  date.setDate(date.getDate() + 1);
                  document.cookie = 'orderId' + '=' + orderId + ';expires=' + date;
                  this.$message({message:"订单提交成功",type:"success"})
                  //要将确认订单信息页面的商品删除掉
                  localStorage.removeItem("order")
                  //提交之后要删除的购物车列表
                  let arr = []
                  this.data.forEach((item,index)=>{
                    arr.push(item.id)
                  })
                  this.http.delCart('?ids=' + arr.toString()).then(res=>{})
                  this.$router.push({
                    path: "/orderSubmit"
                  })
                }
            })
          }
        });
      },
    },
  }
</script>

<style scoped>
.box{
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 100px;
}
  .content{
    width: 80%;
    padding: 20px;
    margin: 0 auto;
    background: white;
  }
  .title{
    font-weight: bold;
  }
  .active{
    color: #00BAFF !important;
  }
  .info{
    width: 100%;
  }
  .info{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
.info>div{
  width: 19%;
  height: 100%;
  border-bottom: 3px solid #b2d1ff;
  text-align: center;
  font-size: 12px;
  line-height: 50px;
}
.shop{
  display: flex;
  justify-content: space-around;
  background-color: rgb(251, 252, 255);
  margin-top: 10px;
}
.shop>div{
  width: 19%;
  height: 70px;
  padding: 10px;
  font-size: 14px;
}
  .settlement{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .settlement>div{
    height: 100px;
  }
.settlement>div:nth-of-type(3){
  background: white;
  padding: 15px;
  text-align: right;
  border: 1px solid #ff0036;
}
  .youhuiquan{
    width: 80%;
    padding: 20px;
    background: white;
    margin: 20px auto;
  }
</style>
