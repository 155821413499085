<template>
  <div>
    <div class="banner">
      <div class="ya315-container" style="text-align: center;">
        <div class="d-b-title">数字农批产业园</div>
        <div class="d-b-info">基于大宗农批交易，通过科技与供应链创新相结合，为大宗农批交易业务体系升级，助力农批行业数字化转型</div>
      </div>
    </div>
    <div :class="isFixed?'fd':''" class="nav">
      <div>
        <div @click="anchor(0)" :class="anchorNum==0?'cur':''">产品简介</div>
        <div @click="anchor(1)" :class="anchorNum==1?'cur':''">相关系统</div>
        <div @click="anchor(2)" :class="anchorNum==2?'cur':''">产品优势</div>
      </div>
    </div>
    <div id="page1">
      <p>产品介绍</p>
      <p>
        利用移动互联网和大数据分析技术，以农批（贸）市场为中心，以农批（贸）市场数字化为基础，构建上下游信息流、资金流和物流。解决传统农产品流通领
        域信息不对称、交易效率低、交易数据采集困难、食品安全溯源困难等问题，实现农批（贸）市场全面转型。
      </p>
      <div>
        <img src="../../assets/img/行业数字农批/cpjj.png" alt="">
      </div>
    </div>
    <div id="page2">
      <p>相关系统</p>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>招商管理系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>品牌管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              实现对管理区域内各项日常招商管理工作的数字化管理，该系统可以帮助管理人员更
              好的匹配意向客户、服务现有商家，灵活地将商区发展和商家利益有机结合起来
            </div>
          </div>
          <div>
            <p>招商预算</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              实现对管理区域内各项日常招商管理工作的数字化管理，该系统可以帮助管理人员更好
              的匹配意向客户、服务现有商家，灵活地将商区发展和商家利益有机结合起来
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 40px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>物业管理系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;padding-bottom: 20px;">
          <div>
            <p>租控管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              入驻园区企业装修报备、租控监控，随时了解合同到期情况，预警提醒续租退租等合同变更事宜，并进行退租离场业务处理。
            </div>
          </div>
          <div>
            <p>费用管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              及时掌握园区入驻企业物业缴费情况，每月物业费用通过物业缴费帐单通知线上／线下实时自动推送，多渠道费用线上／线下便捷支付。
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>设备管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              根据巡检路线、巡检计划、巡检节点，明确责任矩阵，通过移动终端实现巡检记录的无纸化，报事报修线上工单提交，系统智能化自动派单，实时获知任务处理进度。
            </div>
          </div>
          <div>
            <p>人员管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              为园区管理者提供集中管理园区固定人员的功能，可以导入或添加员工的信息，并随时进行维护。
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>停车管理系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>车辆通行管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              先进的车牌识别技术、实现出入通 道车辆的快速识别、快速检测、快速开闸、快速通行、快速关闸、不取卡、不停车等快速通行系统，解决出入口拥堵。
            </div>
          </div>
          <div>
            <p>自助缴费管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              集中式收费管理，采用微信、支付 宝、银联支付等电子支付手段，自助缴费终端、手机APP等方式，让车主停车费直达物业账户，完美规避中间环节。
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>安防系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>管理应用简便</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              由于数字监控系统基于计算机和网络设备，绝大部分系统控制管理功能通过电脑实现，无须模拟系统
              中众多繁杂的设备，减轻了操作维护人员的管理工作强度
            </div>
          </div>
          <div>
            <p>监控查看简便</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              由于数字监控系统基于计算机和网络设备，绝大部分系统控制管理功能通过电脑实现，无须模拟系统
              中众多繁杂的设备，减轻了操作维护人员的管理工作强度
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>农产品溯源系统（立码放心）</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>全流程追溯监管</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              解决企业产品生产、库存、质量跟踪过程中标识产品、跟踪产品等问题，使数据标准化、程规范化。
            </div>
          </div>
          <div>
            <p>大数据智能分析</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              一物一码产品身份管理,流转环节全程数据管控,智能供应链全程信息记录,大数据只能分析,清晰化生产库存管理以及营销效果监控,全程立"码"解决
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>检测检验系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>样本检验机构</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              出入库管理线上化，实时更新，配备可视化管理，有效管理缺货、丢货、出入库时间过长等情况
            </div>
          </div>
          <div>
            <p>检测结果公示</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              将检测检验中心的商品检测结果以可视化大屏方式公示，严格控制食品安全检验
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>仓储系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;padding-bottom: 20px;">
          <div>
            <p>出入库管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              出入库管理线上化，实时更新，配备可视化管理，有效管理缺货、丢货、出入库时间过长等情况
            </div>
          </div>
          <div>
            <p>费用结算</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              独立财务核算，融入供应链金融支付及结算，支持大额交易，快速便捷化
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>任务管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              仓库补货任务、上架任务及拣货任务查询及管理
            </div>
          </div>
          <div>
            <p>盘点及报表分析</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              庞大盘点功能，盘点商品生成明细表单及汇总表；总结库存、结算、经营报表
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>运输管理系统</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;padding-bottom: 20px;">
          <div>
            <p>智能调度</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              基于预设规则与约束条件，结合路径优化引擎，自动匹配车源，计划运输路线，为企业节省物流运输费用。
            </div>
          </div>
          <div>
            <p>订单管理</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              利用移动与社交技术，通过提供移动APP或微信公众号全程连接货主、承运商、司机与客户，实现多方实时协同。
            </div>
          </div>
        </div>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>统计分析</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              根据数据统计并生成图表查看送货及时率、签收完好率、运输工具负荷、费用指标、客户满意度、承运商考核指标等
            </div>
          </div>
          <div>
            <p>自动化结算</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              提供强大的计费引擎，满足您复杂的计费需求能为货主、客户、承运商一键生成同一本账单，节省大量人力物力。
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <p class="title"><span style="display:block;width: 2px;height: 10px;background: black;margin-right: 8px;"></span>大数据可视化</p>
        <div style="margin-top: 30px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8E9EB;padding-bottom: 20px;">
          <div>
            <p>商品菜价公示</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              根据园区管理中商品管理，在大屏中展示各类型商品价格
            </div>
          </div>
          <div>
            <p>交易数据监管</p>
            <div style="width: 550px;margin-top: 10px;font-size: 14px;">
              展示园区各个板块交易金额及交易笔数，交易量（公斤），方便管理者查看交易详情
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 50px;" id="page3">
      <p>产品优势</p>
      <div style="display: flex;justify-content: space-between;margin-top: 50px;">
        <div style="width: 240px;height: 180px;text-align: center">
          <div style="height: 20px;"></div>
          <img src="../../assets/img/行业数字农批/ncpaqsy.png" alt="">
          <div style="height: 20px;"></div>
          <p style="font-weight: bold;">农产品安全溯源</p>
          <div style="height: 20px;"></div>
        </div>
        <div style="width: 240px;height: 180px;text-align: center">
          <div style="height: 20px;"></div>
          <img src="../../assets/img/行业数字农批/xssc.png" alt="">
          <div style="height: 20px;"></div>
          <p style="font-weight: bold;">线上商城</p>
          <div style="height: 20px;"></div>
        </div>
        <div style="width: 240px;height: 180px;text-align: center">
          <div style="height: 20px;"></div>
          <img src="../../assets/img/行业数字农批/gyljrzc.png" alt="">
          <div style="height: 20px;"></div>
          <p style="font-weight: bold;">供应链金融支持</p>
          <div style="height: 20px;"></div>
        </div>
        <div style="width: 240px;height: 180px;text-align: center">
          <div style="height: 20px;"></div>
          <img src="../../assets/img/行业数字农批/pbwlcc.png" alt="">
          <div style="height: 20px;"></div>
          <p style="font-weight: bold;">配备物流仓储</p>
          <div style="height: 20px;"></div>
        </div>
        <div style="width: 240px;height: 180px;text-align: center">
          <div style="height: 20px;"></div>
          <img src="../../assets/img/行业数字农批/dsjfx.png" alt="">
          <div style="height: 20px;"></div>
          <p style="font-weight: bold;">大数据分析</p>
          <div style="height: 20px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hyszhp",
    data(){
      return {
        isFixed:false,
        anchorNum:0,
        active:0,
        activeName:"上游供应采集"
      }
    },
    mounted() {
      $('#head,#foot').show();
      this.type = this.$route.query.type
      if (document.addEventListener) {//firefox
        document.addEventListener('DOMMouseScroll', this.scrollFuc, false);
      }
      //滚动滑轮触发scrollFunc方法 //ie 谷歌
      window.onmousewheel = document.onmousewheel = this.scrollFuc;
    },
    methods:{
      scrollFuc(e){
        e = e || window.event;
        if (e.wheelDelta) { //第一步：先判断浏览器IE，谷歌滑轮事件
          if (document.documentElement.scrollTop > 450) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        } else if (e.detail) { //Firefox滑轮事件
          if (document.body.scrollTop> 450) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        }
      },
      anchor(n){
        this.anchorNum = n;
        let top = 0
        if(n==0){
          top = $('#page1').offset().top-72-60
        }else if(n==1){
          top = $('#page2').offset().top-72-60
        }else{
          top = $('#page3').offset().top-72-60
        }
        $('html').scrollTop(top)
        this.isFixed = true
      }
    },
  }
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/行业数字农批/banner.png);background-size:cover;}
  .d-b-title{font-size: 48px;color:#fff;padding-top:90px;}
  .d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;}
  .nav{height:54px;border-bottom:1px solid #E8E9EB;width:100%;background:#fff;overflow: hidden;}
  .nav.fd{position:fixed;top:60px;z-index: 10;}
  .nav>div{display: flex;justify-content: center;margin: 0 auto;}
  .nav>div>div{width:90px;margin:0 146px;height:54px;border-bottom:2px solid transparent;line-height: 54px;font-size: 16px;color:#52565B;text-align: center;cursor: pointer;}
  .nav>div>div.cur{color:#1890FF;border-bottom-color: #1890FF;}
  #page1,#page2,#page3,#page4{
    width: 1200px;
    padding: 20px 0px;
    margin: 0 auto;
  }
  #page1>p:nth-of-type(1),#page2>p:nth-of-type(1),#page3>p:nth-of-type(1),#page4>p:nth-of-type(1){
    font-size: 30px;
    text-align: center;
    color: #1890FF;
  }
  #page1>p:nth-of-type(2){
    font-size: 14px;
    color: #52565B;
    line-height: 25px;
    letter-spacing: 2px;
    margin-top: 20px;
  }
  .text{
    width: 588px;
    height: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #52565B;
  }
  .active{
    border: 1px solid rgba(38, 144, 253, 0.2000);
    border-left: 4px solid #2690FD;
  }
  .title{
    font-weight: bold;
    display: flex;
    align-items: center;
  }
</style>
