<template>
  <div>
    <div class="login">
      <div v-show="userObj.register == 0" class="box">
        <div style="height: 70px;"><img width="206" height="36" src="./assets/img/logo-header (2).png" alt="食安链家" /></div>
        <el-tabs style="display: flex;" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="主账户登录" name="0"></el-tab-pane>
          <el-tab-pane label="子账户登录" name="1"></el-tab-pane>
        </el-tabs>
        <input v-show="activeName == 1" v-model.trim="zhuUserPhone" type="text" placeholder="主账户注册手机号" />
        <input v-model.trim="user" type="text" placeholder="账户" />
        <input v-model.trim="password" :type="userObj.passFlag?'text':'password'" placeholder="密码" />
        <span class="sp1"><img width="20" height="20" src="./assets/img/user.webp"/></span>
        <span v-show="activeName == 1" :class="activeName == 0?'sp1':'sp2'"><img width="20" height="20" src="./assets/img/zhuuser.png"/></span>
        <span :class="activeName == 0?'sp2':'sp3'"><img width="20" height="20" src="./assets/img/password.webp"/></span>
        <div v-show="!userObj.wordShow">
          <span :class="activeName == 0?'wd3':'sp4'" @click="isPass" v-show="!userObj.passFlag"><img width="20" height="20" src="./assets/img/eye-slash.png" /></span>
          <span :class="activeName == 0?'wd3':'sp4'" @click="isPass" v-show="userObj.passFlag"><img width="20" height="20" src="./assets/img/eye.png" /></span>
        </div>
        <div style="margin-top: 15px;">
          <Verify ref="verify" @success="codeOk"></Verify>
        </div>
        <div class="check">
          <div style="display: flex;">
            <span @click="forGetPsd" style="color: rgb(24,144,255);cursor: pointer;">忘记密码?</span>
            <!--          <input type="checkbox" style="margin-top: 4px" />-->
            <!--          <span style="display: block;margin-left: 5px">自动登录</span>-->
          </div>
          <span style="color: rgb(24,144,255);cursor: pointer;" @click="zhuce">注册账户</span>
        </div>
        <button type="button" @click="login" class="btn btn-primary btn-lg btn-block">登录</button>
        <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: rgb(85,85,87);cursor: pointer;">其他登录方式</div>
        <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: rgb(85,85,87);margin-top: 50px;cursor: pointer;">
          <span>帮助</span>丨<span>隐私</span>丨<span>条款</span>
        </div>
      </div>
      <Reg v-show="userObj.register == 1" @reg="reg"></Reg>
<!--      忘记密码-->
      <div v-show="userObj.register == 2" class="forGet">
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 20px 50px;">
          <el-button type="text" @click="fanhuiLogin">返回</el-button>
          <div>找回密码</div>
          <span></span>
        </div>
        <el-input v-show="forGetShow" style="width: 320px;margin-top: 20px;" v-model="userPhone" placeholder="主账号手机号" prefix-icon="el-icon-user"></el-input>
        <el-input style="width: 320px;margin-top: 20px;" v-model="phone" placeholder="手机号" prefix-icon="el-icon-phone"></el-input>
        <el-input style="width: 320px;margin-top: 20px;" v-model="oldPassword" placeholder="密码" type="password" show-password prefix-icon="el-icon-lock"></el-input>
        <el-input style="width: 320px;margin-top: 20px;" v-model="newPassword" placeholder="确认密码" type="password" show-password prefix-icon="el-icon-lock"></el-input>
        <br>
        <el-input style="width: 200px;margin-top: 20px;" v-model="verCode" placeholder="请输入验证码"></el-input>
        <button @click="getCode" style="width:120px;height: 40px;margin-left: 5px;" :disabled="show?false:true" type="button" class="btn btn-secondary btn-sm">{{ show?'获取验证码':count + 's' }}</button>
        <br>
        <el-button type="primary" style="width: 320px;margin-top: 20px;" @click="forGetEdit" black>确认修改</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import Reg from "./register"
  import Verify from "./verify"
  import { JSEncrypt } from 'jsencrypt'
  import Cookies from 'js-cookie'
  export default{
    name:"",
    data(){
      return{
        userObj:{
          passFlag:false,//密码框内容明文密文的显示
          wordShow:true,//密码框明文密文icon的显示隐藏
          register:0,//0登录  1注册  2忘记密码
        },
        user:"",//账户
        password:"",//密码
        zhuUserPhone:"",
        codeData:{},
        fullscreenLoading: true,
        loadingShow:true,
        activeName:0,
        forGetShow:false,
        userPhone:"",
        phone:"",
        oldPassword:"",
        newPassword:"",
        verCode:"",
        show:true,
        timer:null,
        count:"",
      }
    },
    components:{
      Reg,
      Verify
    },
    watch:{
      //监听密码框里面是否有内容   如果有就显示明文密文icon   如果没有就隐藏明文密文icon
      password(val){
        if(val == ""){
          this.userObj.wordShow = true
          this.userObj.passFlag = false
        }else{
          this.userObj.wordShow = false
        }
      }
    },
    mounted() {
      setTimeout(()=>{
        this.fullscreenLoading = false
        this.loadingShow = false
      },3000)
      this.user = ""
      this.password = ""
      this.codeData = {}
      this.$refs.verify.refresh()//重置图片验证码
    },
    methods:{
      //验证码倒计时
      setTim(){
        this.show = false
        const TIME_COUNT = 2
        if(!this.timer){
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(()=>{
            if(this.count>1&&this.count<=TIME_COUNT){
              this.count--
            }else{
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          },1000)
        }
      },
      getCode(){
        if(this.phone == ""){
          this.$message({message: '请输入手机号', type: 'warning'});
        }else{
          let patt = new RegExp("^1[34578][0-9]{9}$", 'i');
          if(patt.test(this.phone)){
            this.http.phoneCode('?phone='+this.phone).then(res=>{
              if(res.code == "1"){
                this.$message({message: res.msg, type: 'success'});
                this.setTim()
              }else{
                this.$message({message: res.info, type: 'warning'});
              }
            })
          }else{
            this.$message({message: '请输入正确的手机号', type: 'warning'});
          }
        }
      },
      //图片验证码校验成功
      codeOk(data){
        console.log(data)
        this.codeData = data
      },
      //登录模块切换至注册模块
      zhuce(){
        this.$refs.verify.refresh()
        this.userObj.register = 1
      },
      //注册模块切换至登录模块
      reg(bool){
        this.userObj.register = 0
        this.$refs.verify.refresh()
      },
      //明文密文切换
      isPass(){
        this.userObj.passFlag = !this.userObj.passFlag
      },
      handleClick(val){
        if(val == "0"){
          this.$refs.verify.refresh()
          this.user = ""
          this.password = ""
        }else{
          this.$refs.verify.refresh()
          this.zhuUserPhone = ""
          this.user = ""
          this.password = ""
        }
      },
      //登录
      login(){
        if(this.activeName == "0"){
          let data = {
            userName:this.user,//主账户账号
            mixPd:this.password,//主账户密码
            _c:this.codeData.verify,//图形验证码
          }
          //将密码进行加密
          const encrypt = new JSEncrypt()
          encrypt.setPublicKey(
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
          )
          data.mixPd = encrypt.encrypt(data.mixPd)
          this.http.login(data).then((res)=>{
              if(res.code == "1") {
                document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//存储数据之前删除cookie删除token
                //将token存储cookie
                let token = res.data
                let date = new Date();
                date.setDate(date.getDate() + 1);
                document.cookie = 'token' + '=' + token + ';expires=' + date;
                Cookies.set('token', token, { expires: 1, path: 'http://192.168.2.125:9999' });
                let fullePath = localStorage.getItem("fullPath")
                if(fullePath){
                  this.$router.push({
                    path:fullePath
                  })
                  localStorage.removeItem("fullPath")
                }else{
                  this.$router.push({
                    path:"/shop"
                  })
                }
                // location.reload()
              }else {
                this.$refs.verify.refresh()//重置图片验证码
              }
            })
        }else{
          let data = {
            masterUserName:this.zhuUserPhone,//主账户手机号
            userName:this.user,//子账户账号
            mixPd:this.password,//子账户密码
            _c:this.codeData.verify,//图形验证码
          }
          //将密码进行加密
          const encrypt = new JSEncrypt()
          encrypt.setPublicKey(
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
          )
          data.mixPd = encrypt.encrypt(data.mixPd)
          this.http.subLogin(data).then((res)=>{
              if(res.code == "1") {
                document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//存储数据之前删除cookie删除token
                //将token存储cookie
                let token = res.data
                let date = new Date();
                date.setDate(date.getDate() + 1);
                document.cookie = 'token' + '=' + token + ';expires=' + date;
                Cookies.set('token', token, { expires: 1, path: 'http://192.168.2.125:9999' });
                this.$router.push({
                  path:"/shop"
                })
                location.reload()
              }else {
                this.$refs.verify.refresh()//重置图片验证码
              }
            })
        }
      },
      forGetPsd(){
        if(this.activeName == 0){
          this.userObj.register = 2
          this.forGetShow = false
        }else{
          this.userObj.register = 2
          this.forGetShow = true
        }
      },
      fanhuiLogin(){
        this.userObj.register = 0
        this.userPhone = ""
        this.phone = ""
        this.oldPassword = ""
        this.newPassword = ""
        this.verCode = ""

      },
      forGetEdit(){
        if(this.phone == ""){
          this.$message({message:"请输入手机号",type:"error"})
        }else{
          if(this.oldPassword == ""){
            this.$message({message:"请输入密码",type:"error"})
          }else{
            if(this.newPassword == ""){
              this.$message({message:"请输入确认密码",type:"error"})
            }else{
              if(this.oldPassword != this.newPassword){
                this.$message({message:"两次密码必须一致",type:"error"})
              }else{
                if(this.verCode == ""){
                  this.$message({message:"请输入验证码",type:"error"})
                }else{
                  if(this.forGetShow){
                    //  子账户
                    if(this.userPhone == ""){
                      this.$message({message:"请输入主账户注册手机号",type:"error"})
                    }else{
                      let data = {
                        masterMobile:this.userPhone,
                        mobile:this.phone,
                        pwd:this.newPassword,
                        verificationCode:this.verCode
                      }
                      //将密码进行加密
                      const encrypt = new JSEncrypt()
                      encrypt.setPublicKey(
                        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
                      )
                      data.pwd = encrypt.encrypt(data.pwd)
                      this.http.subForGetEdit(data).then(res=>{
                        if(res.code == "1"){
                          this.fanhuiLogin()
                        }
                      })
                    }
                  }else{
                    //  主账户
                    let data = {
                      mobile:this.phone,
                      pwd:this.newPassword,
                      verificationCode:this.verCode
                    }
                    //将密码进行加密
                    const encrypt = new JSEncrypt()
                    encrypt.setPublicKey(
                      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
                    )
                    data.pwd = encrypt.encrypt(data.pwd)
                    this.http.userForGetEdit(data).then(res=>{
                      if(res.code == "1"){
                        this.fanhuiLogin()
                      }
                    })
                  }
                }
              }
            }
          }
        }
      },
    }
  }
</script>
<style scoped>
  .login{width:100%;height:100%;position:fixed;background-size:100% 100%;background-image: url("./assets/img/loginImg.jpg");}
  .box{width: 320px;height: 600px;margin: 200px auto auto auto;position: relative;border: none;}
  .box>div:nth-of-type(1)>img{margin: 0 auto;display: block;}
  .box>p:nth-of-type(1){width: 110px;height: 30px;text-align: center;border-bottom: 2px solid rgb(24,144,255);margin-left: 20px;}
  .box>input{font-size: 14px;color:#333333;width: 320px;height: 40px;border-radius: 10px;margin-top: 20px;outline: none;border: 1px solid #bbb;padding-left: 40px;}
  .sp1{position: absolute;top: 150px;left: 10px;}
  .sp2{position: absolute;top: 210px;left: 10px;}
  .sp3{position: absolute;top: 270px;left: 10px;}
  .sp4{position: absolute;top: 270px;right: 5px}
  .sp5{position: absolute;top: 270px;right: 5px}
  .wd3{position: absolute;top: 210px;right: 5px;}
  .check{width: 320px;height: 50px;display: flex;justify-content: space-between;align-items: center;font-size: 13px;}
  .forGet{
    width: 600px;height: 460px;margin: 200px auto auto auto;position: relative;
    background: white;
    text-align: center;
  }
</style>
