<template>
  <b-container >
    <header class="d-flex flex-row flex-nowrap justify-content-between">
      <section>
        <article>拓扑管理</article>
        <nav>网管软件动态可视化拓扑图，一览掌控全局:拓扑图自动发现,助力网络IT运维管理稳健运行</nav>
        <aside>1.网络管理软件实时直观展观IT运维管理环境中各种资源的当前分布与设备运行情况。</aside>
        <aside>
          2.支持物理拓扑图、示意拓扑图、业务拓扑图、机柜拓扑图:内置大量网络设备素材库,可自定义轻松构造绚丽的展现。
        </aside>
        <aside class="p-0">
          3.拓扑图将以全局管理视角出发,自动生成物理拓扑图。支持自定义灵活部署物理、示意、业务、及机柜拓扑图等,以可视化动态展现网络设备资源的结构分布、链路关系、性能指标和运行状态等，并能通过颜色策略、动态流量、告警提示变化来表示每个资源的异常等级,做到故障快速定位,帮忙IT运维管理人员快速掌握全局网络设备运行状态，强化网络运维管理水平。
        </aside>
      </section>
      <aside>
        <img src="../../assets/product_center/1.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </header>
    <header class="d-flex flex-row flex-nowrap justify-content-between">
       <aside>
        <img src="../../assets/product_center/2.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
      <section>
        <article>资产管理</article>
        <nav>综合网管系统设备资产管理-信息化管理企业固定资产</nav>
        <aside>CMDB资产管理系统存储着企业IT设备的所有配置信息，将众多IT设备信息整合，完整记录着从资产购买到报废整个生命周期的全称管理，存储资管相关信息并设置自动提醒业务。支持硬件资产、合同资产、维护供应商等功能，帮助管理者从不同角度了解企业IT资产情况，高效统一管理设备资源，最大化发挥企业设备使用价值。</aside>
        <aside>
          1.企业资产全周期管理
        </aside>
        <aside>
          2.移动扫码实时查看
        </aside>
        <aside>3.模板下载批量入库</aside>
        <aside>4.多用户协同管理</aside>
        <aside>5.设备保修服务提供</aside>
        <aside class="p-0">6.资产采购与合同</aside>
      </section>
    </header>
    <header class="d-flex flex-row flex-nowrap justify-content-between">
      <section>
        <article>故障告警</article>
        <nav>故障管理，根源分析：异常与故障管理</nav>
        <aside>1. 通过故障模块的异常一览可以根据资源类型、指标类型、等级、确认、恢复、地域、时间段及关键字等进行筛选与查询，从而针对性的查看相关资源的异常信息，网络管理软件可以对其进行详细信息查看、确认、删除及导出EXCEL格式</aside>
        <aside class="p-0">
         2. 支持客户端告警、短信告警、电话告警、微信告警、消息框、远程声音等多种告警方式，第一时间通知运维人员故障的原因、故障所在的位置，网络管理软件将管理人员从网管机面前解放出来，真正实现无人值守的网络运维管理。
        </aside>
      </section>
      <aside>
        <img src="../../assets/product_center/3.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </header>
    <header class="d-flex flex-row flex-nowrap justify-content-between">
       <aside>
        <img src="../../assets/product_center/4.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
      <section>
        <article>数据报表</article>
        <nav>报表分析，决策无忧：报表统计分析与自动发现</nav>
        <aside>1 .网管软件中的报表为管理和决策提供了重要的依据，通过数据的整合和智能分析，将重要的监控指标结果直观的展现给用户，为网络运维管理系统提供完善的统计和分析报表</aside>
        <aside>
          2.网络管理系统提供各种类型的报表模板，可直接启动，进行订阅。支持自定义报表模板，各种类型，时间和模版权限
        </aside>
        <aside>
         3.历史数据进行分析，提供自定义时间，可多个设备和多个指标进行对比分析
        </aside>
        <aside>4.管理软件中智能巡检基于日常工作流程管理系统,以用户实际巡检情况出发，实现无人值守的定期巡检</aside>
        <aside>5.网络管理系统可以支持按不同巡检内容和设备制定周期性的定点智能巡检,自定义添加检测点，构建巡检规则</aside>
        <aside>6.网络管理系统以模板规范标准值为依据，根据预设的要求进行数据采集，进行自主学习和分析判断,进行定期巡检</aside>
        <aside >7. 网络管理系统以报表的形式直观反映巡检结果，系统会定期生成并主动发送运维人员</aside>
        <aside class="p-0">8. 网络管理系统支持对系统监控巡查的整体进行评价和备注说明,导出多种格式向领导汇报</aside>
      </section>
    </header>
    <header class="d-flex flex-row flex-nowrap justify-content-between">
      <section>
        <article>大屏幕展示</article>
        <nav>大屏幕实时动态展示-可视化网络运维管理生态</nav>
        <aside>大屏幕系统设计整体美观、大方、合理，充分展现信息化时代的数据氛围与特点，将IT大数据用更生动、直观的形式，通过数据分析精简于形，将复杂的IT网络运维管理简洁化：</aside>
        <aside>
          1 .大屏幕数据实时采集，采用炫酷的动态图形展示，网络运维管理人员可以及时了解网络运维管理的资源使用情况、趋势和告警
        </aside>
        <aside >
          2.灵活部署和多屏展示，支持不同分辨率，可根据多屏或单屏自定义配置多个界面，灵活部署在各类拼接屏或单屏轮换逬行展示
        </aside>
        <aside>3. 内建数据展示模板智能匹配，根据不同的行业满足用户业务管理、网络运维管理资源、网络运维管理结构等各场景的展示需求</aside>
        <aside class="p-0">4. 多种类型组件数据支持、图形化编辑界面，通过简单拖拽即可配置灵活易用的自定义组件，轻松部署各视</aside>
      </section>
      <aside>
        <img src="../../assets/product_center/7.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
    </header>
    <header class="d-flex flex-row flex-nowrap justify-content-between">
       <aside>
        <img src="../../assets/product_center/5.jpg" alt="西安星云宇博数据科技有限公司">
      </aside>
      <section>
        <article>移动端-微信端</article>
        <nav>网管系统提供微信端，打造网络设备管理移动化运维</nav>
        <aside>1 .微信企业端，用户打开微信企业号或APP即可查看与网管软件同步的核心资源列表</aside>
        <aside>
          2.微信企业端可直接推送故障信息到IT人员的移动端
        </aside>
        <aside>
         3. IT运维管理人员在网管系统上配置订阅微信，移动端即可推送各订阅的各类报表及提醒
        </aside>
        <aside>4. 支持申告人创建故障工单管理，实时把握工单处理进度</aside>
        <aside>5.IT运维管理人员在手机就能接收到推送的ITSM的流程任务信息</aside>
        <aside>6.IT运维人员在手机扫描二维码可以直接获取网络设备当前的实时信息</aside>
        <aside class="p-0">7.随时随地的对网络监控系统的环境做到，实现了客户端与移动端一体化网络监控方案</aside>
      </section>
    </header>
  </b-container>
</template>

<script>
export default {
  name:'Details'
}
</script>

<style scoped>
  .container{
    padding:3rem 15%;
  }
  .container>header{
    width:100%;
    padding-bottom: 2rem;
  }
  .container>header>section{
    width:49.5%;
  }
  .container>header>section>article{
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    padding-bottom:1rem ;
  }
  .container>header>section>nav{
    width: 100%;
    font-weight: bold;
    padding-bottom:.7rem;
  }
  .container>header>section>aside{
    width: 100%;
    text-indent: 2em;
    font-size: .9rem;
    padding-bottom:.2rem;
  }
  .container>header>section>aside:first-child{
    padding-bottom: 1rem;
  }
  .container>header>aside{
    width:49.5%;
  }
  .container>header>aside>img{
    width: 100%;
  }
</style>