<template>
  <div id="head" @mouseleave="leave()">
    <div class="ya315-container">
      <b-row class="m-0">
        <b-col cols="4" class="p-0">
          <img
            src="../assets/img/logo-header.svg"
            width="340"
            height="36"
            alt="食安链家"
          />
        </b-col>
        <b-col cols="8" class="p-0">
          <b-nav>
            <b-nav-item
              id="home"
              class="cur"
              :to="{ path: '/' }"
              @mouseenter="enter()"
              >首页</b-nav-item
            >
            <b-nav-item
              id="produce"
              :to="{ path: '/' }"
              @mouseenter="enter('produce')"
              >产品</b-nav-item
            >
            <b-nav-item
              id="solution"
              :to="{ path: '/solutionNew', query: { type: 'scjg' } }"
              @mouseenter="enter('solution')"
              >解决方案</b-nav-item
            >
            <!-- <b-nav-item id="shop" :to="{ path: '/shop' }" @mouseenter="enter()" -->
            <!-- <b-nav-item id="shop" @mouseenter="enter()">客户案例</b-nav-item> -->
            <b-nav-item
              id="customerCase"
              @mouseenter="enter('customerCase')"
              :to="{ path: '/customerCase', query: { type: 'customerCase' } }"
              >客户案例</b-nav-item
            >
            <b-nav-item
              id="cooperation"
              :to="{ path: '/cooperation', query: { type: 'cooperation' } }"
              @mouseenter="enter('cooperation')"
              >合作与生态</b-nav-item
            >
            <b-nav-item
              id="about"
              :to="{ path: '/about' }"
              @mouseenter="enter('about')"
              >关于</b-nav-item
            >
            <b-nav-item :to="{ path: '/loginCenter' }" @mouseenter="enter()"
              >登录中心</b-nav-item
            >
          </b-nav>
        </b-col>
      </b-row>
    </div>
    <!-- 产品中心 -->
    <div id="produceMenu" class="produce-menu">
      <div class="left-nav">
        <div
          :style="
            'font-size: 12px;' +
            (leftNavIndex == 1 ||
            leftNavIndex == 2 ||
            leftNavIndex == 3 ||
            leftNavIndex == 4 ||
            leftNavIndex == 5
              ? 'color: #1980ff'
              : '')
          "
        >
          企业应用
        </div>
        <div
          @mousemove="scrollBox(1, 1)"
          :class="leftNavIndex == 1 ? 'cur' : ''"
        >
          食安-农互通
        </div>
        <div
          @mousemove="scrollBox(2, 1)"
          :class="leftNavIndex == 2 ? 'cur' : ''"
        >
          食安-商互通
        </div>
        <div
          @mousemove="scrollBox(3, 1)"
          :class="leftNavIndex == 3 ? 'cur' : ''"
        >
          食安-云管家
        </div>
        <div
          @mousemove="scrollBox(4, 1)"
          :class="leftNavIndex == 4 ? 'cur' : ''"
        >
          食安-云集仓
        </div>
        <div
          @mousemove="scrollBox(5, 1)"
          :class="leftNavIndex == 5 ? 'cur' : ''"
        >
          食安-云物流
        </div>
        <div
          :style="
            'font-size: 12px;' + (leftNavIndex == 6 ? 'color: #1980ff' : '')
          "
        >
          行业应用
        </div>
        <div
          @mousemove="scrollBox(6, 2)"
          :class="leftNavIndex == 6 ? 'cur' : ''"
        >
          数字农业
        </div>
      </div>
      <div
        v-show="rightContent == 1"
        class="right-menu"
        id="scrollBox"
        @scroll="getScrollTop"
      >
        <div class="menu-title">
          <div>食安-农互通</div>
          <div class="line"></div>
        </div>
        <div style="display: flex">
          <div>
            <router-link
              id="dj"
              :to="{ path: '/product/one', query: { type: 'dj' } }"
              class="menu-item"
            >
              <img
                style="margin-right: 18px"
                src="../assets/img/icon-header-jsl.png"
                width="25"
                height="31"
                alt=""
              />
              <span class="name" style="color: black !important">鸡司令</span>
            </router-link>
            <div
              style="
                background: #fbfbfb;
                width: 260px;
                height: 155px;
                padding-top: 18px;
              "
            >
              <router-link
                class="menu-item2"
                :to="{ path: '/product/one', query: { type: 'yzcgl' } }"
                >养殖场管理平台</router-link
              >
              <router-link
                class="menu-item2"
                :to="{ path: '/product/one', query: { type: 'qdxcx' } }"
                >禽蛋小程序交易平台</router-link
              >
              <router-link
                class="menu-item2"
                :to="{ path: '/product/one', query: { type: 'wlpsapp' } }"
                >物流配送APP</router-link
              >
            </div>
          </div>
          <!--          <div id="zsl" class="menu-item">-->
          <!--            <img style="margin-right:14px;" src="../assets/img/icon-header-zsl.png" width="32" height="19" alt="">-->
          <!--            <span class="name">猪司令（公测中）</span>-->
          <!--          </div>-->
        </div>
        <div class="menu-title">
          <div>商互通</div>
          <div class="line"></div>
        </div>
        <div class="menu-item-box">
          <div class="menu-item menu-item-title">数据管理系统</div>
          <div class="menu-item menu-item-title">品牌商管理系统</div>
          <div class="menu-item menu-item-title">分销商管理系统</div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="spncp"
            :to="{ path: '/product/one', query: { type: 'spncp' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-spncp.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">食品农产品大数据资源管理平台</span>
          </router-link>
          <router-link
            id="pps"
            :to="{ path: '/product/one', query: { type: 'pps' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-pps.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">品牌商SAAS管理平台</span>
          </router-link>
          <router-link
            id="fxs"
            :to="{ path: '/product/one', query: { type: 'fxs' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-fxs.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">分销商SAAS管理平台</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="gysjcjg"
            :to="{ path: '/product/one', query: { type: 'gysjcjg' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-gysjcjg.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">供应商集采集供数据管理平台</span>
          </router-link>
          <router-link
            id="ppsht"
            :to="{ path: '/product/one', query: { type: 'ppsht' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-ppsgyl.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">品牌商后台供应链管理平台</span>
          </router-link>
          <router-link
            id="fxsht"
            :to="{ path: '/product/one', query: { type: 'fxsht' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-fxsgyl.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">分销商后台供应链管理平台</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <div class="menu-item menu-item-title" style="margin-top: 20px">
            数字农批管理系统
          </div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="sznp"
            :to="{ path: '/product/one', query: { type: 'sznp' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-gysjcjg.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">数字农批在线商城</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="sznpsjgl"
            :to="{ path: '/product/one', query: { type: 'sznpsjgl' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-gysjcjg.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">数字农批数据管理</span>
          </router-link>
        </div>
        <div class="menu-title">
          <div>云管家</div>
          <div class="line"></div>
        </div>
        <div class="menu-item-box">
          <div class="menu-item menu-item-title">智慧新餐饮管理云系统</div>
          <div class="menu-item menu-item-title">智慧新生鲜管理云平台</div>
          <div class="menu-item menu-item-title">智慧新农贸管理云系统</div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="cyls"
            :to="{ path: '/product/one', query: { type: 'cyls' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-cyls.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">餐饮连锁SAAS管理平台</span>
          </router-link>
          <router-link
            id="sxls"
            :to="{ path: '/product/one', query: { type: 'sxls' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-sxls.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">生鲜连锁SAAS管理平台</span>
          </router-link>
          <router-link
            id="zhxnm"
            :to="{ path: '/product/one', query: { type: 'zhxnm' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-sxls.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">智慧新农贸管理平台</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="cysy"
            :to="{ path: '/product/one', query: { type: 'cysy' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-cymd.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">餐饮门店收银APP</span>
          </router-link>
          <router-link
            id="sxsy"
            :to="{ path: '/product/one', query: { type: 'sxsy' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-mdsy.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">门店收银APP</span>
          </router-link>
          <router-link
            id="zxscxcx"
            :to="{ path: '/product/one', query: { type: 'zxscxcx' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-mdsy.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">在线商城小程序</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="dcps"
            :to="{ path: '/product/one', query: { type: 'dcps' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-dcps.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">点餐配送APP</span>
          </router-link>
          <router-link
            id="zxsc"
            :to="{ path: '/product/one', query: { type: 'zxsc' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-zxsc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">门店在线商城小程序</span>
          </router-link>
          <router-link
            id="sycz"
            :to="{ path: '/product/one', query: { type: 'sycz' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-zxsc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">摊位收银称重APP</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="fwydc"
            :to="{ path: '/product/one', query: { type: 'fwydc' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-fwydc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">服务员点餐APP</span>
          </router-link>
          <router-link
            id="sxps"
            :to="{ path: '/product/one', query: { type: 'sxps' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-mdps.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">门店配送APP</span>
          </router-link>
          <router-link
            id="psapp"
            :to="{ path: '/product/one', query: { type: 'psapp' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-mdps.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">配送APP</span>
          </router-link>
        </div>
        <div class="menu-item-box">
          <router-link
            id="cxapp"
            :to="{ path: '/product/one', query: { type: 'cxapp' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-cxapp.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">厨显APP</span>
          </router-link>
          <div class="menu-item"></div>
          <div class="menu-item"></div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="smdc"
            :to="{ path: '/product/one', query: { type: 'smdc' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-smdc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">扫码点餐小程序</span>
          </router-link>
          <div class="menu-item"></div>
          <div class="menu-item"></div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="wmxcx"
            :to="{ path: '/product/one', query: { type: 'wmxcx' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-smdc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">外卖小程序</span>
          </router-link>
          <div class="menu-item"></div>
          <div class="menu-item"></div>
        </div>
        <div class="menu-title">
          <div>云集仓</div>
          <div class="line"></div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="ccgl"
            :to="{ path: '/product/one', query: { type: 'ccgl' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-cc.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">仓储SAAS管理平台</span>
          </router-link>
        </div>
        <div class="menu-title">
          <div>云集物</div>
          <div class="line"></div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="wls"
            :to="{ path: '/product/one', query: { type: 'wls' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-wls.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">物流商saas管理平台</span>
          </router-link>
          <router-link
            id="wlps"
            :to="{ path: '/product/one', query: { type: 'wlps' } }"
            class="menu-item"
          >
            <img
              src="../assets/img/icon-header-wlps.png"
              width="36"
              height="36"
              alt=""
            />
            <span class="name">物流配送APP</span>
          </router-link>
          <div class="menu-item"></div>
        </div>
      </div>
      <div class="right-menu" v-show="rightContent == 2">
        <div class="menu-item-box" style="margin-top: 20px">
          <div class="menu-item menu-item-title">数字农业</div>
          <div class="menu-item menu-item-title">数字产业园</div>
          <div class="menu-item menu-item-title"></div>
        </div>
        <div class="menu-item-box">
          <router-link
            id="djcyl"
            :to="{ path: '/product/one', query: { type: 'djcyl' } }"
            class="menu-item"
          >
            <img
              style="margin-right: 18px"
              src="../assets/img/icon-header-jsl.png"
              width="25"
              height="31"
              alt=""
            />
            <span class="name">蛋鸡产业链</span>
          </router-link>
          <router-link
            id="hysznp"
            :to="{ path: '/product/one', query: { type: 'hysznp' } }"
            class="menu-item"
          >
            <img
              style="margin-right: 18px"
              src="../assets/img/icon-header-jsl.png"
              width="25"
              height="31"
              alt=""
            />
            <span class="name">数字农批</span>
          </router-link>
          <div class="menu-item"></div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div id="solutionMenu" class="produce-menu">
      <div class="left-nav">
        <div class="cur">通用解决方案</div>
      </div>
      <div class="right-menu">
        <div style="margin-left: 49px">
          <div class="solution-group">
            <div class="solution-group-title">防伪追溯</div>
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'qyzs' } }"
              class="solution-item"
              >企业追溯解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'zfzs' } }"
              class="solution-item"
              >政府追溯解决方案</router-link
            >
            <div class="solution-group-title">供应链金融</div>
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'szzf' } }"
              class="solution-item"
              >数字支付解决方案</router-link
            >
            <div class="solution-item">企业融资解决方案(公测中)</div>
            <div class="solution-item">大数据征信解决方案(公测中)</div>
            <div class="solution-item">信用贷款解决方案(公测中)</div>
          </div>

          <div class="solution-group">
            <div class="solution-group-title">农产品流通</div>
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'scjg' } }"
              class="solution-item"
              >生产加工企业解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'zhnm' } }"
              class="solution-item"
              >智慧农贸解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'zhnp' } }"
              class="solution-item"
              >智慧农批解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'xyncp' } }"
              class="solution-item"
              >县域农产品经济体解决方案</router-link
            >
          </div>
          <div class="solution-group">
            <div class="solution-group-title">餐饮连锁</div>
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'kc' } }"
              class="solution-item"
              >快餐解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'hgyt' } }"
              class="solution-item"
              >火锅业态解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'zcls' } }"
              class="solution-item"
              >中餐连锁解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'cyxls' } }"
              class="solution-item"
              >餐饮新零售解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'msgc' } }"
              class="solution-item"
              >美食广场解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'dfxc' } }"
              class="solution-item"
              >地方小吃解决方案</router-link
            >
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'xxjg' } }"
              class="solution-item"
              >学校、机关食堂解决方案</router-link
            >
          </div>
          <div class="solution-group">
            <div class="solution-group-title">生鲜超市</div>
            <router-link
              :to="{ path: '/solutionNew', query: { type: 'sxmd' } }"
              class="solution-item"
              >生鲜门店解决方案</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 合作与生态 -->
    <div id="cooperationMenu" class="produce-menu">
      <div class="left-nav">
        <div class="cur">关于合作伙伴</div>
      </div>
      <div class="right-menu" style="padding-bottom: 0">
        <div
          style="
            margin-left: 49px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="solution-group">
            <div class="solution-group-title">关于合作伙伴</div>
            <router-link
              :to="{ path: '/cooperation', query: { type: 0 } }"
              class="solution-item"
              >我们的合作体系</router-link
            >
            <router-link
              :to="{ path: '/cooperation', query: { type: 1 } }"
              class="solution-item"
              >重点合作计划</router-link
            >
            <router-link
              :to="{ path: '/cooperation', query: { type: 2 } }"
              class="solution-item"
              >优秀伙伴展示</router-link
            >
            <div
              :to="{ path: '/cooperation', query: { type } }"
              class="solution-item"
            >
              精品案例展示
            </div>
          </div>
          <div class="solution-group">
            <div class="solution-group-title">成为合作伙伴</div>
            <router-link
              class="solution-item"
              :to="{ path: '/cooperation/index1', query: { type: 1 } }"
              >渠道供应商伙伴</router-link
            >
            <router-link
              :to="{ path: '/cooperation/index1', query: { type: 2 } }"
              class="solution-item"
              >交付服务伙伴</router-link
            >
            <router-link
              :to="{ path: '/cooperation/index1', query: { type: 3 } }"
              class="solution-item"
              >产品及方案合作</router-link
            >
            <div class="solution-item">云市场合作</div>
          </div>
          <div class="solution-group">
            <div class="solution-group-title">食安生态计划</div>
            <div class="solution-item">民权商贸城招商计划</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们-->
    <div id="aboutMenu" class="produce-menu">
      <div class="left-nav">
        <div class="cur">关于食安链家</div>
      </div>
      <div class="right-menu" style="padding-bottom: 0">
        <div
          style="
            margin-left: 49px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="solution-group">
            <div class="solution-group-title">关于食安链家</div>
            <router-link
              :to="{ path: '/about', query: { type: 0 } }"
              class="solution-item"
              >了解食安链家</router-link
            >
          </div>
          <div class="solution-group">
            <div class="solution-group-title">客户成功案例</div>
            <router-link class="solution-item" :to="{ path: '/case' }"
              >全部案例</router-link
            >
          </div>
          <div class="solution-group"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      leftNavIndex: 1,
      rightContent: 1,
    }
  },
  watch: {
    $route(to, form) {
      this.getCurrent()
      document.body.scrollTop = document.documentElement.scrollTop = 0
      $('#produceMenu,#solutionMenu,#cooperationMenu').removeClass('cur')
    },
  },
  mounted() {
    this.getCurrent()
  },
  methods: {
    getScrollTop(e) {
      let top = e.target.scrollTop
      if (top < 116) {
        this.leftNavIndex = 1
      } else if (top >= 116 && top < 330) {
        this.leftNavIndex = 2
      } else if (top >= 330 && top < 688) {
        this.leftNavIndex = 3
      } else if (top >= 688 && top < 800) {
        this.leftNavIndex = 4
      } else {
        this.leftNavIndex = 5
      }
    },
    scrollBox(n, r) {
      this.leftNavIndex = n
      this.rightContent = r
      let height = 0
      switch (n) {
        case 1:
          height = 0
          break
        case 2:
          height = 116
          break
        case 3:
          height = 330
          break
        case 4:
          height = 688
          break
        case 5:
          height = 800
          break
      }
      $('#scrollBox').scrollTop(height)
    },
    enter(tp) {
      $('#produceMenu,#solutionMenu,#cooperationMenu,#aboutMenu').removeClass(
        'cur'
      )
      if (tp == 'produce') $('#produceMenu').addClass('cur')
      if (tp == 'solution') $('#solutionMenu').addClass('cur')
      if (tp == 'cooperation') $('#cooperationMenu').addClass('cur')
      if (tp == 'about') $('#aboutMenu').addClass('cur')
    },
    leave() {
      $('#produceMenu,#solutionMenu,#cooperationMenu,#aboutMenu').removeClass(
        'cur'
      )
    },
    getCurrent() {
      let pageName = this.$route.name
      let param = this.$route.query
      console.log('pageName', pageName)
      switch (pageName) {
        case 'index':
          $('#home').addClass('cur').siblings().removeClass('cur')
          break
        case 'details_one':
          $('#produce').addClass('cur').siblings().removeClass('cur')
          break
        case 'solutionNew':
          $('#solution').addClass('cur').siblings().removeClass('cur')
          break
        case 'cooperation':
          $('#cooperation').addClass('cur').siblings().removeClass('cur')
          break
        case 'customerCase':
          $('#customerCase').addClass('cur').siblings().removeClass('cur')
          break
      }
      if (param.type) {
        $('.menu-item').removeClass('cur')
        switch (param.type) {
          case 'dj':
            $('#dj').addClass('cur')
            break
          case 'spncp':
            $('#spncp').addClass('cur')
            break
          case 'pps':
            $('#pps').addClass('cur')
            break
          case 'cyls':
            $('#cyls').addClass('cur')
            break
          case 'cysy':
            $('#cysy').addClass('cur')
            break
          case 'dcps':
            $('#dcps').addClass('cur')
            break
          case 'fwydc':
            $('#fwydc').addClass('cur')
            break
          case 'sxls':
            $('#sxls').addClass('cur')
            break
          case 'sxsy':
            $('#sxsy').addClass('cur')
            break
          case 'zxsc':
            $('#zxsc').addClass('cur')
            break
          case 'sxps':
            $('#sxps').addClass('cur')
            break
          case 'ccgl':
            $('#ccgl').addClass('cur')
            break
          case 'wls':
            $('#wls').addClass('cur')
            break
          case 'wlps':
            $('#wlps').addClass('cur')
            break
        }
      }
    },
  },
}
</script>

<style scoped>
#head {
  position: fixed;
  z-index: 11;
  width: 100%;
  box-shadow: 0 12px 24px 0 rgb(105 123 140 / 20%);
  background: #ffffff;
  height: 60px;
  line-height: 60px;
}
.ya315-container > .row > .col-4 > img {
  object-fit: contain;
}
.ya315-container > .row > .col-8 > .nav {
  justify-content: space-between;
}
.ya315-container > .row > .col-8 > .nav > .nav-item > .nav-link {
  padding: 0;
  color: #666666;
  border-bottom: 2px solid transparent;
  height: 60px;
  line-height: 60px;
}
.ya315-container > .row > .col-8 > .nav > .nav-item > .nav-link:hover,
.ya315-container > .row > .col-8 > .nav > .nav-item.cur > .nav-link {
  border-bottom: 2px solid #3895ff;
  color: #3895ff;
}
/* 鼠标悬停时出现折叠导航栏 */
.produce-menu {
  width: 1200px;
  position: fixed;
  top: 60px;
  z-index: 11;
  left: 50%;
  margin-left: -600px;
  height: 0;
  transition: height linear 0.3s;
  background: #ffffff;
  box-shadow: 0 12px 24px 0 rgb(105 123 140 / 10%);
  overflow: hidden;
}
.produce-menu.cur {
  height: 500px;
}
.left-nav {
  width: 166px;
  float: left;
  background: #f6f8ff;
  height: 500px;
}
.left-nav > div {
  width: 120px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 20px;
  cursor: pointer;
}
.left-name > div:first-child {
  margin-top: 30px;
}
.left-nav > div.cur {
  color: #2690fd;
  background: #ffffff;
}
.right-menu {
  width: 1000px;
  float: right;
  height: 500px;
  overflow-y: auto;
  padding-bottom: 400px;
}
.menu-title {
  height: 80px;
  display: flex;
  justify-content: space-between;
  margin-right: 60px;
  font-size: 14px;
  color: #858a90;
  line-height: 80px;
}
.menu-title .line {
  width: 830px;
  height: 1px;
  background: #e8e9eb;
  margin-top: 40px;
}
.menu-item-box {
  display: flex;
  justify-content: space-between;
}
.menu-item {
  display: block;
  width: 306px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.menu-item .name {
  margin-left: 3px;
  font-size: 16px;
}
.menu-item.cur .name {
  color: #2690fd;
}
.menu-item2 {
  display: block;
  margin-left: 55px;
  height: 40px;
  line-height: 40px;
  color: #52565b;
  font-size: 16px;
}
.solution-group {
  width: 220px;
  float: left;
  margin-bottom: 31px;
}
.solution-group-title {
  color: #121a25;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 22px;
  margin-top: 35px;
  line-height: auto;
}
.solution-item {
  color: #52565b;
  margin-bottom: 15px;
  line-height: 20px !important;
  display: block;
}
.menu-item-title {
  font-size: 16px;
  color: #121a25;
  text-indent: 16px;
  font-weight: 700;
}
</style>
