<template>
  <div>
    <div class="header">
      <div style="display: flex;" class="container">
        <img @click="$router.push({path:'/shop'})" style="cursor: pointer;" src="./img/logo.svg" width="300" alt=""/>
        <div style="width: 300px;text-align: center;font-weight: bold;">收银台</div>
      </div>
    </div>
    <div style="text-align: center;padding-top: 40px;font-size: 22px;color: #52565B;" v-if="lastTime == '' || lastTime<='00:00'">
      <div>
        <img width="300" src="./img/orderShixiao.png" alt="">
        <p>订单支付已失效</p>
        <div style="height: 30px;"></div>
        <el-button type="primary" @click="$router.push({path:'/shop'})">重新下单</el-button>
      </div>
    </div>
    <div id="order" v-else>
<!--      未付款-->
      <div class="container" id="toPay">
        <div id="detail1" class="box">
          <div class="fs24 mt30 ml40">订单({{ data.orderNo }})已提交成功，请付款 <div class="fr fs22 mr40">应付金额：<span class="red price">{{ data.amount/100 | price }}</span> 元</div></div>
          <div class="fs20 mt20 ml40">请您在 <span id="time" class="red">{{ lastTime<="00:00"?'该订单已失效':lastTime }}</span> 内完成支付，超时后将自动取消订单</div>
          <div class="fs20 mt20 ml40" style="font-size: 16px;"><span style="font-weight: bold;">寄送至：</span>
            <span style="font-size: 15px;">{{ data.provCode }} {{ data.cityCode }} {{ data.countyCode }} {{ data.addrDetail }} </span>
          </div>
          <div class="fs20 mt20 ml40" style="font-size: 16px;"><span style="font-weight: bold;">收货人：</span>
            <span style="font-size: 15px;">{{ data.name }} {{ data.phone }} </span>
          </div>
          <div class="line1 mt24"></div>
          <div id="orderDetail">
            <div class="info">
              <div>商品名称</div>
              <div>商品规格</div>
              <div>数量</div>
              <div>单价</div>
              <div>小计</div>
            </div>
            <div v-for="(item,index) in data.orderDetails" :key="index" class="shop">
              <div>{{ item.goodsTitle }}</div>
              <div>{{ item.specName }}</div>
              <div>{{ item.goodsNum }}</div>
              <div>{{ item.unitPrice/100 | price }}</div>
              <div>{{ item.totalPrice/100 | price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:1047px;margin:0 auto 20px;">
        <div id="paying" class="btn fr mt20 fs18">
          <el-button type="primary" @click="zhifuShow = true">支付</el-button>
        </div>
        <div class="clear"></div>
      </div>
<!--      付款方式-->
      <el-dialog title="请选择支付方式" :visible.sync="zhifuShow" width="50%" :show-close="false" :before-close="before">
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="在线支付" name="在线支付">
              <div style="margin-top: 20px;">
                <p style="font-weight: bold;">选择支付方式</p>
                <div>
                  <img id="weixin" @click="appZhifu('2')" data-id="2" class="mt32 ml36" src="./img/wx.png" width="159" height="54" alt="">
                  <img id="zhifubao" @click="appZhifu('1')" data-id="1" class="mt32 ml36" src="./img/zfb.png" width="181" height="54" alt="">
                  <img id="yl" @click="appZhifu('3')" data-id="2" class="mt32 ml36" src="./img/yl.png" width="159" height="54" alt="">
                </div>
                <div>
                  <div v-show="activeType==1">
                    <div class="mt42 ml90 fs16">请在 <span class="blue">{{ lasterweimaTime<="00:00"?"已过期":lasterweimaTime }}</span> 秒内付款，过期后请单击图片重新获取二维码</div>
                    <img style="display: block;margin:40px auto 20px;cursor: pointer;" @click="appZhifu('1')" :src="image" width="260" height="260" alt="">
                    <div style="text-align: center;font-size: 20px;">请使用 <span class="codeType">支付宝</span>扫一扫</div>
                  </div>
                </div>
                <div v-show="activeType==2">
                  <div>
                    <div class="mt42 ml90 fs16">请在 <span class="blue">{{ lasterweimaTime<="00:00"?"已过期":lasterweimaTime }}</span> 秒内付款，过期后请单击图片重新获取二维码</div>
                    <img style="display: block;margin:40px auto 20px;cursor: pointer;" @click="appZhifu('2')" :src="image" width="260" height="260" alt="">
                    <div style="text-align: center;font-size: 20px;">请使用 <span class="codeType">微信</span>扫一扫</div>
                  </div>
                </div>
                <div>
                  <div v-show="activeType==3">
                    <div style="width: 100%;margin-top: 10px;">
                      <el-steps :active="active" simple>
                        <el-step title="输入卡号"></el-step>
                        <el-step title="输入验证信息"></el-step>
                        <el-step title="完成支付"></el-step>
                      </el-steps>
                    </div>
                    <div v-show="active==0">
                      <div style="height: 20px;"></div>
                      <el-input style="width: 330px;" v-model="BankCardNumber" placeholder="请输入卡号"></el-input>
                      <el-button type="primary" style="margin-left: 20px;" size="medium" @click="cardNumberFun">下一步</el-button>
                    </div>
                    <div v-show="active==1">
                      <div style="height: 20px;"></div>
                      <el-input style="width: 330px;" v-model="info.phone" placeholder="请输入手机号"></el-input>
                      <div style="height: 10px;"></div>
                      <el-input style="width: 120px;" v-model="info.code" placeholder="请输入验证码"></el-input>
                      <el-button style="margin-left: 20px;" size="medium" @click="active=0,phone='',code=''">上一步</el-button>
                      <el-button type="primary" style="margin-left: 20px;" size="medium" @click="information">下一步</el-button>
                    </div>
                    <div v-show="active==3">
                      <el-result icon="success" title="支付成功" subTitle="您已完成支付"></el-result>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="对公汇款" name="对公汇款">对公汇款</el-tab-pane>
          </el-tabs>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="zhifuQx">取 消 支 付</el-button>
          <el-button type="primary" @click="backMaifang">已支付，并跳转 <i class="el-icon-arrow-right"></i></el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index.vue",
    data(){
      return {
        uid: "", //用户ID,用来请求后台数据的
        timer:[],//定时器ID数组
        showTime:"",
        lastTime:"",
        data:{},
        zhifuShow:false,
        activeName:"在线支付",
        active:0,
        BankCardNumber:"",//银联卡号
        info:{
          phone:"",
          code:"",

        },
        payType:"",
        activeType:"",
        showerweimaTime:"",
        lasterweimaTime:"",
        time:[],
        image:"",
      }
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    mounted() {
      let reg = RegExp('orderId' + '=([^;]+)');
      let arr = []
      if(document.cookie.match(reg)){
        arr = document.cookie.match(reg)
      }
      this.uid = arr[1]
      this.userList();
    },
    beforeDestroy(){
      //清除定时器
      this.timer.forEach(a =>{
        clearInterval(a);
      })
    },
    methods:{
      before(){},
      userList(){
        this.http.orderDetail(`?id=` + this.uid).then(res=>{
          if(res.code == "1"){
            this.data = res.content
            this.showTime = this.getTime(res.content.createTime);
            if(this.data.orderDetails.length>0){
              this.showTimeFun()//开始倒计时
            }
          }
        })
      },
      //获取倒数计时的时间差
      getTime(createTime){
        createTime = Date.parse(new Date(createTime));//parse方法只能精确到秒,也就是转成时间戳,
        //后面三位数是000,比如本来是124141,精确成了124000
        let timeDate = Date.parse(new Date());
        return createTime + 30*60*1000*48 - timeDate;
      },
      //开启定时器
      showTimeFun(){
        let flag = true;
        if(this.showTime >= 0){
          let time
          if(flag){
            time = this.showTime -= 1000;
            let timeOne = time / 1000;
            timeOne = timeOne - 1;
            let minute = this.checkFigure(parseInt(timeOne / 60));
            let second = this.checkFigure(parseInt(timeOne % 60));
            this.lastTime = `${minute}:${second}`;
          }
          //加倒数的定时器
          let timerId = setInterval(()=>{
            flag = false;
            time = this.showTime -= 1000;
            let timeOne = time / 1000;
            timeOne = timeOne - 1;
            let minute = this.checkFigure(parseInt(timeOne / 60));
            let second = this.checkFigure(parseInt(timeOne % 60));
            this.lastTime = `${minute}:${second}`;//用来显示倒计时的
            this.$forceUpdate();//!!!划重点,这个是迫使Vue实例重新（rander）渲染虚拟DOM，
            //注意并不是重新加载组件,但是也达到更新的目的了,要不然你会发现数据一直在变化,页面却没变
          }, 1000)
          this.timer.push(timerId);
        }else{
          //请求后台接口,取消订单的接口
          console.log("清除订单")
          console.log(this.lastTime)
        }
      },
      //把单一位数字通过在前面+“0”转化为两位；
      checkFigure(i) {
        if (i < 10) {
          i = '0' + i;
        }
        return i;
      },
      handleClick(val){},
    //  银联卡号
      cardNumberFun(){
        if(this.BankCardNumber == ""){
          this.$message({message:"银联卡号不能为空",type:"error"})
        }else{
          this.active = 1
        }
      },
    //  验证信息
      information(){
        if(this.phone == ""){
          this.$message({message:"手机号不能为空",type:"error"})
        }else{
          let patt = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/
          if(!patt.test(this.phone)){
            this.$message({message:"手机号格式不正确",type:"error"})
          }else{
            if(this.code == ""){
              this.$message({message:"验证码不能为空",type:"error"})
            }else{
              this.active = 3
            }
          }
        }
      },
      Date(ms){
        let date = new Date(ms);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + (M <10 ? '0' + M : M) + (D <10 ? '0' + D : D) + (h<10?'0' + h:h) + (m < 10 ? '0' + m : m) + (s < 10 ? '0' + s : s)
      },
      appZhifu(str){
        //清除定时器
        this.time.forEach(a =>{
          clearInterval(a);
        })
        this.payType = str
        if(str != 3){
          let obj = {
            orderId:this.data.id,
            amount:this.data.amount,
            payType:this.payType
          }
          this.http.pay(obj).then(res=>{
            if(res.code == "1"){
              this.image = res.content.qrCode
              this.activeType = str
              let date = new Date()
              this.showerweimaTime = this.geterweimaTime(this.Date(date));
              this.showerweimaTimeFun()//开始倒计时
            }
          })
        }else{
          this.activeType = str
        }
      },
      //获取倒数计时的时间差
      geterweimaTime(createTime){
        createTime = Date.parse(new Date(createTime));//parse方法只能精确到秒,也就是转成时间戳,
        //后面三位数是000,比如本来是124141,精确成了124000
        let timeDate = Date.parse(new Date());
        return createTime + 60*1005*5 - timeDate;
      },
      //开启定时器
      showerweimaTimeFun(){
        let flag = true;
        if(this.showerweimaTime >= 0){
          let time
          if(flag){
            time = this.showerweimaTime -= 1000;
            let timeOne = time / 1000;
            timeOne = timeOne - 1;
            let minute = this.checkFigure(parseInt(timeOne / 60));
            let second = this.checkFigure(parseInt(timeOne % 60));
            this.lasterweimaTime = `${minute}:${second}`;
          }
          //加倒数的定时器
          let timerId = setInterval(()=>{
            flag = false;
            time = this.showerweimaTime -= 1000;
            let timeOne = time / 1000;
            timeOne = timeOne - 1;
            let minute = this.checkFigure(parseInt(timeOne / 60));
            let second = this.checkFigure(parseInt(timeOne % 60));
            this.lasterweimaTime = `${minute}:${second}`;//用来显示倒计时的
            this.$forceUpdate();//!!!划重点,这个是迫使Vue实例重新（rander）渲染虚拟DOM，
            //注意并不是重新加载组件,但是也达到更新的目的了,要不然你会发现数据一直在变化,页面却没变
          }, 1000)
          this.time.push(timerId);
        }else{
          //请求后台接口,取消订单的接口
          // let date = new Date()
          // this.showerweimaTime = this.geterweimaTime(this.Date(date));
          // this.showerweimaTimeFun()//开始倒计时
        }
      },
      zhifuQx(){
        this.$confirm('您确定要取消支付吗？, 是否继续?', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.zhifuShow = false
          this.activeType = ""
          this.payType = ""
          this.time.forEach((a)=>{
            clearInterval(a)
          })
        }).catch(() => {});
      },
      backMaifang(){
        //调取接口，跳转到买方中心的订单页面
      },
    },
  }
</script>

<style scoped>
  @import './css/index.css';
  .info{
    width: 100%;
  }
  .info{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .info>div{
    width: 12%;
    height: 100%;
    border-bottom: 3px solid #b2d1ff;
    text-align: center;
    font-size: 15px;
    line-height: 50px;
  }
  .shop{
    display: flex;
    justify-content: space-around;
    background-color: rgb(251, 252, 255);
    margin-top: 10px;
  }
  .shop>div{
    width: 12%;
    height: 70px;
    padding: 10px;
    font-size: 14px;
    text-align: center;
  }
</style>
